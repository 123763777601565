import React, { useCallback, useRef, useState } from 'react';
import { FormHandles, Scope } from '@unform/core';
import * as Yup from 'yup';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { AxiosError } from 'axios';
import { Input, InputMask, DatePicker } from '../../../../components/Form';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { IParticipant } from '../../../../types';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import {
  cnpjMaskDefinition as cnpjMask,
  cpfMaskDefinition as cpfMask,
} from '../../../../utils/format';
import { Container, FormStyled as Form } from './styles';

interface NewParticipantData {
  type: 'fisical' | 'juridical';
  fisical?: {
    cpf: string;
    name: string;
    birthday: Date;
  };
  juridical?: {
    cnpj: string;
    social_name: string;
    fantasy_name: string;
  };
}

interface CheckDisponibilityData {
  identificator: string;
}

interface OwnProps {
  onAddParticipant(participant: IParticipant): void;
  company_id: string;
  onCancel(): void;
}

const NewParticipant: React.FC<OwnProps> = ({
  onAddParticipant,
  company_id,
  onCancel,
}) => {
  const { addToast } = useToast();
  const formRefDisponible = useRef<FormHandles>(null);
  const formRef = useRef<FormHandles>(null);
  const formConfirmation = useRef<FormHandles>(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [error, setError] = useState('');
  const [participant, setParticipant] = useState<IParticipant | undefined>();
  const [loading, setLoading] = useState({
    isLoading: false,
    message: '',
  });
  const [maskIdentificator, setMaskIdentificator] = useState({
    isFully: false,
    mask: cpfMask,
  });
  const [identificator, setIdentificator] = useState<string | undefined>();

  const handleOnSubmit = useCallback(
    async (data: NewParticipantData) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          type: Yup.string().oneOf(['fisical', 'juridical']).required(),
          fisical: Yup.object().when('type', {
            is: 'fisical',
            then: Yup.object()
              .shape({
                name: Yup.string().required('O campo nome é obrigatório'),
                cpf: Yup.string().length(11).required(),
                birthday: Yup.date().required(
                  'A data de nascimento é obrigatória',
                ),
              })
              .required(),
          }),
          juridical: Yup.object().when('type', {
            is: 'juridical',
            then: Yup.object()
              .shape({
                social_name: Yup.string().required(
                  'O campo razão social é obrigatório',
                ),
                cnpj: Yup.string().length(14).required(),
                fantasy_name: Yup.string().required(
                  'O nome fantasia é obrigatório',
                ),
              })
              .required(),
          }),
        });

        const result = await schema.validate(
          {
            type: identificator?.length === 11 ? 'fisical' : 'juridical',
            fisical: {
              ...data.fisical,
              cpf: identificator,
            },
            juridical: {
              ...data.juridical,
              cnpj: identificator,
            },
          },
          {
            abortEarly: false,
          },
        );

        const formData = Object.assign(result, { company_id });

        setLoading({
          isLoading: true,
          message: 'Adicionando participante a empresa',
        });

        const response = await api.post<IParticipant>('participants', formData);

        onAddParticipant(response.data);
        onCancel();
        addToast({
          title: 'Participante adicionado com sucesso',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        }
      }
    },
    [identificator, company_id, onAddParticipant, onCancel, addToast],
  );

  const handleNextStep = useCallback(() => {
    setActiveStep(current => current + 1);
  }, []);

  const handlePrevius = useCallback(() => {
    setActiveStep(current => current - 1);
  }, []);

  const handleOnCheckDisponibility = useCallback(
    async (data: CheckDisponibilityData) => {
      formRefDisponible.current?.setErrors({});

      const identificatorLenght = maskIdentificator.mask === cpfMask ? 11 : 14;

      try {
        const schema = Yup.object().shape({
          identificator: Yup.string()
            .length(identificatorLenght, 'O campo deve conter 14 dígitos.')
            .required('Este campo é obrigatório'),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        setLoading({
          isLoading: true,
          message: 'Verificando se participante existe',
        });
        setError('');

        await api
          .get<IParticipant>(`participants/${result?.identificator}`)
          .then(async response => {
            setLoading({
              isLoading: true,
              message: 'Verificando se participante já pertence a empresa',
            });

            try {
              const membersReponse = await api.get<IParticipant[]>(
                `companies/${company_id}/members`,
              );

              const hasParticipant = membersReponse.data.findIndex(
                member => member.id === response.data.id,
              );

              if (hasParticipant >= 0) {
                setError('Participante já pertence a esta empresa');
                return;
              }

              setParticipant(response.data);
              setActiveStep(2);
            } catch (err) {
              addToast({
                title: 'Inconsistência',
                description:
                  'Não foi possível verificar se o participante já pertence a esta empresa. Tente novamente',
                type: 'error',
              });
            } finally {
              setLoading({
                isLoading: false,
                message: '',
              });
            }
          })
          .catch((err: AxiosError) => {
            if (err.isAxiosError) {
              if (
                err.response?.data.message === 'Participante não cadastrado.'
              ) {
                handleNextStep();
              }
            }
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRefDisponible.current?.setErrors(getValidationErrors(err));
        }
      } finally {
        setLoading({
          isLoading: false,
          message: '',
        });
      }
    },
    [maskIdentificator.mask, company_id, addToast, handleNextStep],
  );

  const handleOnChangeIdentificator = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const cleanValue = event.target.value.replace(/\D/g, '');
      setIdentificator(cleanValue);
      if (cleanValue.length === 11 && !maskIdentificator.isFully) {
        setMaskIdentificator(current => ({
          ...current,
          isFully: true,
        }));
      } else if (cleanValue.length === 11 && maskIdentificator.isFully) {
        setMaskIdentificator({
          isFully: false,
          mask: cnpjMask,
        });
      } else if (
        cleanValue.length === 11 &&
        maskIdentificator.mask === cnpjMask
      ) {
        setMaskIdentificator({
          isFully: true,
          mask: cpfMask,
        });
      } else if (
        cleanValue.length < 11 &&
        maskIdentificator.mask === cnpjMask
      ) {
        setMaskIdentificator({
          isFully: false,
          mask: cpfMask,
        });
      }
    },
    [maskIdentificator.isFully, maskIdentificator.mask],
  );

  const handleOnAddToCompany = useCallback(async () => {
    if (!participant) {
      setError('Participante não pode ser recuperado para adição');
      return;
    }

    try {
      setLoading({
        isLoading: true,
        message: 'Adicionando participante a empresa',
      });

      await api.post(`companies/${company_id}/members`, {
        participant_id: participant.id,
      });

      onAddParticipant(participant);
      onCancel();
    } catch (err) {
      addToast({
        title: 'Inconsistência',
        description:
          'Não foi possível adicionar o participante a empresa. Tente novamente',
        type: 'error',
      });
    } finally {
      setLoading({
        isLoading: false,
        message: '',
      });
    }
  }, [addToast, company_id, onAddParticipant, onCancel, participant]);

  return (
    <Container>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Verificar disponibilidade do identificador</StepLabel>
          <StepContent>
            {loading.isLoading && (
              <div>
                <LinearProgress />
                <Typography variant="subtitle1" color="primary">
                  {loading.message}
                </Typography>
              </div>
            )}

            {error && (
              <Typography variant="subtitle1" color="error">
                {error}
              </Typography>
            )}
            <Form ref={formRefDisponible} onSubmit={handleOnCheckDisponibility}>
              <InputMask
                name="identificator"
                label="CPF/CNPJ"
                onChange={handleOnChangeIdentificator}
                mask={maskIdentificator.mask}
                disabled={loading.isLoading}
              />

              <section>
                <div>
                  <Button
                    color="secondary"
                    onClick={onCancel}
                    disabled={loading.isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading.isLoading}
                  >
                    Verificar
                  </Button>
                </div>
              </section>
            </Form>
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Informar dados do novo participante</StepLabel>
          <StepContent>
            {loading.isLoading && (
              <div>
                <LinearProgress />
                <Typography variant="subtitle1" color="primary">
                  {loading.message}
                </Typography>
              </div>
            )}
            {error && (
              <Typography variant="subtitle1" color="error">
                {error}
              </Typography>
            )}
            <Form ref={formRef} onSubmit={handleOnSubmit}>
              {identificator?.length === 11 && (
                <Scope path="fisical">
                  <Input
                    name="name"
                    label="Nome"
                    placeholder="Insira o nome do participante"
                  />

                  <DatePicker name="birthday" label="Data de nascimento" />
                </Scope>
              )}
              {identificator?.length === 14 && (
                <Scope path="juridical">
                  <Input
                    name="social_name"
                    label="Razão social"
                    placeholder="Insira a razão social da empresa"
                  />
                  <Input
                    name="fantasy_name"
                    label="Nome fantasia"
                    placeholder="Insira o nome fantasia da empresa"
                  />
                </Scope>
              )}
              <div>
                <div>
                  <Button onClick={handlePrevius}>Voltar</Button>
                  <Button type="submit" variant="contained" color="primary">
                    Adicionar
                  </Button>
                </div>
              </div>
            </Form>
          </StepContent>
        </Step>

        <Step>
          <StepLabel>Confirmar dados do participante</StepLabel>
          <StepContent>
            <Typography>
              Confirme os dados para adicioná-lo a esta empresa
            </Typography>
            {loading.isLoading && (
              <div>
                <LinearProgress />
                <Typography variant="subtitle1" color="primary">
                  {loading.message}
                </Typography>
              </div>
            )}
            {error && (
              <Typography variant="subtitle1" color="error">
                {error}
              </Typography>
            )}
            <Form
              ref={formConfirmation}
              onSubmit={handleOnAddToCompany}
              initialData={participant || {}}
            >
              {participant?.type === 'fisical' && (
                <Scope path="fisical">
                  <Input name="name" label="Nome" disabled />
                  <InputMask name="cpf" label="CPF" mask={cpfMask} disabled />
                  <DatePicker
                    name="birthday"
                    label="Data de nascimento"
                    disabled
                  />
                </Scope>
              )}
              {participant?.type === 'juridical' && (
                <Scope path="juridical">
                  <Input name="social_name" label="Razão social" disabled />
                  <Input name="fantasy_name" label="Nome fantasia" disabled />
                  <InputMask
                    name="cnpj"
                    label="CNPJ"
                    mask={cnpjMask}
                    disabled
                  />
                </Scope>
              )}

              <div>
                <div>
                  <Button type="submit" variant="contained" color="primary">
                    Adicionar
                  </Button>
                </div>
              </div>
            </Form>
          </StepContent>
        </Step>
      </Stepper>
    </Container>
  );
};

export default NewParticipant;
