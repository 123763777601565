import React from 'react';

import { CircularProgress } from '@material-ui/core';
import { Container } from './styles';

interface LoadingProps {
  message?: string;
}

const Loading: React.FC<LoadingProps> = ({ message, children, ...rest }) => {
  return (
    <Container {...rest}>
      <CircularProgress size={40} />
      <h1>{message || 'Carregando...'}</h1>
      {children}
    </Container>
  );
};

export default Loading;
