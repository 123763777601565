import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

export const StockContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #7764e4;
  border-radius: 10px;
  padding: 15px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
