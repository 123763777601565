import React, { useEffect, useRef, useCallback, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextFieldProps, IconButton } from '@material-ui/core';
import { useField } from '@unform/core';
import { BiSearch } from 'react-icons/bi';
import { Container, TInput as TextInput } from './styles';
import DialogScroll from '../../DialogScroll';
import BaseTableDataManager from '../../BaseTableDataManager';
import { IProduct } from '../../../types';

interface DefaultValue {
  label: string;
  value: string;
}

interface Props {
  name: string;
  label?: string;
  products: IProduct[];
  onSelectProduct?(product: IProduct): void;
}

type InputProps = TextFieldProps & JSX.IntrinsicElements['input'] & Props;

export const ProductPicker: React.FC<InputProps> = ({
  name,
  label,
  products,
  variant = 'outlined',
  required = true,
  onSelectProduct,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [open, setOpen] = useState(false);
  const [showedValue, setShowedValue] = useState<string | undefined>(
    defaultValue ? (defaultValue as DefaultValue).label : undefined,
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  const handleOnSelectItem = useCallback(
    (item: IProduct) => {
      setShowedValue(
        `${item.name}${item.internal_code ? ` (${item.internal_code})` : ''}`,
      );
      if (onSelectProduct) {
        onSelectProduct(item);
      }
      if (inputRef.current) {
        inputRef.current.value = item.id;
      }
      handleOnToogleDialog();
    },
    [handleOnToogleDialog, onSelectProduct],
  );

  return (
    <Container>
      <TextInput
        inputRef={inputRef}
        defaultValue={defaultValue && (defaultValue as DefaultValue).value}
        style={{ display: 'none' }}
        {...rest}
      />

      <TextInput
        required={required}
        label={label}
        error={!!error}
        helperText={error}
        value={showedValue || ''}
        fullWidth
        variant={variant}
        style={{ marginBottom: 10 }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <>
              <InputAdornment position="end">
                <IconButton onClick={handleOnToogleDialog}>
                  <BiSearch />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />

      <DialogScroll
        open={open}
        dialogTitle="Seleção de Produtos"
        onClose={handleOnToogleDialog}
        onClickActionCancelButton={handleOnToogleDialog}
        dialogActions={<div />}
      >
        <BaseTableDataManager<IProduct>
          options={{
            pageSizeOptions: [5, 20, 50, products.length],
            selection: false,
            filtering: true,
            exportButton: false,
            grouping: false,
          }}
          title=""
          columns={[
            {
              title: 'Nome',
              field: 'name',
            },
            {
              title: 'Descrição',
              field: 'name',
            },
            {
              title: 'Categoria',
              field: 'category',
              render: (column: IProduct) => {
                const categories = {
                  fish: 'Peixe',
                  machines: 'Máquina',
                  implement: 'Implemento',
                  ration: 'Ração',
                };

                return categories[column.category];
              },
            },
            {
              title: 'Espécie',
              field: 'specie.name',
            },
            {
              title: 'Ativo',
              field: 'active',
              type: 'boolean',
            },
            {
              title: 'Cód. Interno',
              field: 'internal_code',
            },
            { title: 'Cód.', field: 'id', grouping: false },
          ]}
          actions={[
            {
              icon: 'check',
              tooltip: 'Selecionar',
              isFreeAction: false,
              onClick: (_, data) => handleOnSelectItem(data as IProduct),
            },
          ]}
          data={products}
        />
      </DialogScroll>
    </Container>
  );
};
