import { IconButton, Tooltip } from '@material-ui/core';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import DialogScroll from '../../../components/DialogScroll';
import { useUserCompanies } from '../../../hooks/userCompanies';
import api from '../../../services/api';
import { IPopulates } from '../../../types';
import { errorHandler } from '../../../utils/errorHandler';
import EditShoppingPopulate from './EditShoppingPopulate';
import { Container } from './styles';

const FixPopulateWithoutShoppingItem: React.FC = () => {
  const [populates, setPopulates] = useState<IPopulates[]>([]);
  const [selectedPopulate, setSelectedPopulate] = useState<
    IPopulates | undefined
  >();
  const { selectedCompany } = useUserCompanies();

  async function checkHasPopulatesToSolve(company_id: string) {
    try {
      const response = await api.get<IPopulates[]>(`populate/fit/bycompany`, {
        params: {
          company_id,
        },
      });

      setPopulates(response.data);
    } catch (error) {
      if ((error as AxiosError).isAxiosError) {
        errorHandler(error);
      }
    }
  }

  useEffect(() => {
    if (selectedCompany?.id) {
      checkHasPopulatesToSolve(selectedCompany.id);
    }
  }, [selectedCompany]);

  async function handleOnSelectPopulate(populate: IPopulates) {
    setSelectedPopulate(populate);
  }

  function handleOnClose() {
    setSelectedPopulate(undefined);
  }

  function handleOnSubmitPopulate(id: string) {
    setPopulates(current => current.filter(item => item.id !== id));
  }

  return (
    <Container>
      <header>
        <h1>
          Olá, está é uma sessão para ajustar alguns povoamentos que foram
          lançados no modelo antigo.
        </h1>
        <span>
          Aqui você pode selecionar o item da compra ou trocar a compra lançada
          nesses povoamentos
        </span>
      </header>

      <table>
        <thead>
          <tr>
            <th>Fazenda</th>
            <th>Viveiro</th>
            <th>Espécie</th>
            <th>Quantidade</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {populates.map(populate => (
            <tr key={populate.id}>
              <td>{populate.pond.farm?.name}</td>
              <td>{populate.pond.name}</td>
              <td>{populate.specie?.name}</td>
              <td style={{ textAlign: 'right' }}>{populate.quantity}</td>
              <td style={{ textAlign: 'right' }}>
                <Tooltip title="Alterar povoamento">
                  <IconButton onClick={() => handleOnSelectPopulate(populate)}>
                    <MdEdit />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedPopulate && (
        <DialogScroll
          open={!!selectedPopulate}
          fullWidth
          maxWidth="md"
          dialogTitle="Alterando povoamento"
          onClose={handleOnClose}
          onClickActionCancelButton={handleOnClose}
          dialogActions={<div />}
        >
          <EditShoppingPopulate
            populate={selectedPopulate}
            farm_id={selectedPopulate.pond.farm_id}
            onUpdate={handleOnSubmitPopulate}
            onCancel={handleOnClose}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default FixPopulateWithoutShoppingItem;
