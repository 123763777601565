import React, { useState, useEffect, useCallback } from 'react';
import BaseTableDataManager from '../../../components/BaseTableDataManager';
import { IUser } from '../../../types';
import Error from '../../../components/Helpers/Information/Error';
import Loading from '../../../components/Helpers/Information/Loading';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { useUserCompanies } from '../../../hooks/userCompanies';
import { getNameFromParticipant } from '../../../utils/format';
import { Container } from './styles';
import DialogScroll from '../../../components/DialogScroll';
import NewUser from './NewUser';
import { useAuth } from '../../../hooks/auth';

const Users: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { selectedCompany } = useUserCompanies();
  const [users, setUsers] = useState<IUser[]>([]);
  const [open, setOpen] = useState(false);

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  useEffect(() => {
    async function loadUsers(company_id: string) {
      try {
        setError(false);
        const { data } = await api.get<IUser[]>(
          `companies/${company_id}/users`,
        );

        setUsers(
          data.map(loadedUser => ({
            ...loadedUser,
            participantName: getNameFromParticipant(loadedUser.participant),
          })),
        );
      } catch (err) {
        addToast({
          title: 'Inconsistência no loading',
          description:
            'Não foi possível carregar as espécies, tente mais tarde.',
          type: 'error',
        });
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    if (selectedCompany?.id) {
      loadUsers(selectedCompany.id);
    }
  }, [addToast, selectedCompany]);

  const handleOnAddNewUser = useCallback((newUser: IUser) => {
    setUsers(current => [...current, newUser]);
  }, []);

  if (error) {
    return <Error />;
  }

  if (loading || !users) {
    return <Loading />;
  }

  if (!selectedCompany) {
    return (
      <Error message="É necessário selecionar a empresa no escopo geral." />
    );
  }

  return (
    <Container>
      <BaseTableDataManager<IUser>
        isLoading={loading}
        options={{
          grouping: true,
          pageSizeOptions: [5, 20, 50, users.length],
          selection: false,
        }}
        title="Usuários"
        columns={[
          {
            title: 'Nome',
            field: 'name',
          },
          {
            title: 'Email',
            field: 'email',
          },
          {
            title: 'Participante',
            field: 'participantName',
          },
          {
            title: 'Cód. Participante',
            field: 'participant_id',
          },
          { title: 'Cód.', field: 'id', grouping: false },
        ]}
        onAdd={user.is_admin ? () => setOpen(true) : undefined}
        data={users}
      />

      {open && (
        <DialogScroll
          open={open}
          dialogTitle="Novo Usuário"
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <NewUser
            company_id={selectedCompany.id}
            onAddUser={handleOnAddNewUser}
            onCancel={handleOnToogleDialog}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default Users;
