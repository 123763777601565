import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { Select, DatePicker } from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import {
  IShopping,
  IParticipant,
  IFarm,
  Options,
  IShoppingItens,
} from '../../../../../types';
import api from '../../../../../services/api';
import Loading from '../../../../../components/Helpers/Information/Loading';
import { useToast } from '../../../../../hooks/toast';
import Error from '../../../../../components/Helpers/Information/Error';
import {
  getAndFormatIdentificator,
  getNameFromParticipant,
} from '../../../../../utils/format';
import { ParticipantPicker } from '../../../../../components/Form/ParticipantPicker';
import BaseTableDataManager from '../../../../../components/BaseTableDataManager';
import DialogScroll from '../../../../../components/DialogScroll';
import NewShoppingProduct from './NewShoppingProduct';
import { errorHandler } from '../../../../../utils/errorHandler';
import { Container } from './styles';

interface NewShopping {
  name: string;
  description: string;
  internal_code: string;
  specie_id: string;
  category: string;
}

interface OwnProps {
  onAddShopping(shopping: IShopping): void;
  company_id: string;
  onCancel(): void;
}

const NewShoppingBuy: React.FC<OwnProps> = ({
  onAddShopping,
  company_id,
  onCancel,
}) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [farms, setFarms] = useState<IFarm[]>([]);
  const [farmsFormatted, setFarmsFormatted] = useState<Options[]>([]);
  const [selectedFarm, setSelectedFarm] = useState<IFarm>();
  const [itens, setItems] = useState<IShoppingItens[]>([]);
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function loadFarms() {
      try {
        setLoading(true);
        const { data } = await api.get<IFarm[]>('company-farms', {
          params: api.defaults.params,
        });

        setFarms(data);

        setFarmsFormatted(
          data.map(farm => ({
            value: farm.id,
            label: farm.name,
          })),
        );
      } catch (err) {
        setError(true);
        addToast({
          title: 'Inconsistência no carregamento',
          description:
            'Houve uma inconsistência ao carregar as fazendas. Tente novamente',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    async function loadParticipants(id: string) {
      try {
        setLoading(true);
        const { data } = await api.get<IParticipant[]>(
          `companies/${id}/members`,
        );

        setParticipants(
          data.map(participant => {
            const identificator = getAndFormatIdentificator(participant);
            const nameFormatted = getNameFromParticipant(participant);

            return {
              ...participant,
              nameFormatted,
              identificator,
            };
          }),
        );
      } catch (err) {
        setError(true);
        addToast({
          title: 'Inconsistência no carregamento',
          description:
            'Houve uma inconsistência ao carregar os participantes. Tente novamente',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    setError(false);
    if (company_id) {
      loadFarms();
      loadParticipants(company_id);
    }
  }, [addToast, company_id]);

  const handleOnSelectFarm = useCallback(
    (farm_id: string) => {
      const farmFounded = farms.find(farm => farm.id === farm_id);

      if (farmFounded) {
        setSelectedFarm(farmFounded);
      }

      formRef.current?.setFieldValue('farm_id', farm_id);
    },
    [farms],
  );

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  const handleOnSubmit = useCallback(
    async (data: NewShopping) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          date: Yup.date().required('O campo é obrigatório'),
          participant_id: Yup.string()
            .uuid()
            .required('O campo fornecedor é obrigatório'),
          farm_id: Yup.string().required('O campo fazenda é obrigatório'),
          payment_type: Yup.string()
            .oneOf(['cash', 'debit', 'credit', 'pix', 'transfer'])
            .required('É obrigatório selecionar um tipo de pagamento'),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        const formData = Object.assign(result, {
          itens: itens.map(item => {
            const {
              quantity,
              unit_price,
              discount,
              product_id,
              pond_id,
              unit_measure,
            } = item;

            return {
              quantity,
              unit_price,
              discount,
              product_id,
              pond_id,
              unit_measure,
            };
          }),
          type: 'payment',
        });

        const response = await api.post<IShopping>('shopping', formData, {
          params: api.defaults.params,
        });

        onAddShopping(response.data);
        onCancel();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        } else if ((err as AxiosError).isAxiosError) {
          const { title, type, description } = errorHandler(err);

          addToast({
            title,
            description,
            type,
          });
        }
      }
    },
    [addToast, itens, onAddShopping, onCancel],
  );

  const handleCanOpen = useCallback(() => {
    if (!selectedFarm) {
      addToast({
        title: 'Ops, selecione a fazenda antes de adicionar os produtos',
        type: 'info',
      });
      return;
    }

    setOpen(true);
  }, [addToast, selectedFarm]);

  const handleOnAddItem = useCallback((item: IShoppingItens) => {
    setItems(current => [...current, item]);
  }, []);

  const handleOnRemoveOneItem = useCallback((item: IShoppingItens) => {
    setItems(current =>
      current.filter(productItem => productItem.id !== item.id),
    );
  }, []);

  if (error) {
    return (
      <Error message="Um dado necessário não pode ser carregado. Tente novamente" />
    );
  }

  if (loading || !farms || !participants) {
    return <Loading message="Carregando dados necessários" />;
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <ParticipantPicker
          name="participant_id"
          label="Fornecedor"
          participants={participants}
        />

        <Select
          name="farm_id"
          label="Fazenda"
          options={farmsFormatted}
          onValueChange={value => handleOnSelectFarm(value as string)}
          required
        />
        <DatePicker name="date" label="Data da compra" />
        <Select
          name="payment_type"
          label="Tipo de pagamento"
          options={[
            { value: 'cash', label: 'Dinheiro' },
            { value: 'debit', label: 'Cartão de débito' },
            { value: 'credit', label: 'Cartão de Crédito' },
            { value: 'pix', label: 'PIX' },
            { value: 'transfer', label: 'Transferência' },
          ]}
          required
        />

        <BaseTableDataManager<IShoppingItens>
          options={{
            selection: false,
            exportButton: false,
            pageSizeOptions: [itens.length],
            filtering: true,
            grouping: true,
          }}
          title="Itens"
          columns={[
            { title: 'Produto', field: 'product.name' },
            { title: 'Prod. Cod.', field: 'product.internal_code' },
            { title: 'Qtd.', field: 'quantity', type: 'numeric' },
            { title: 'Vlr. Unitário', field: 'unit_price', type: 'currency' },
            { title: 'Desconto', field: 'discount', type: 'currency' },
            { title: 'Total', field: 'amount', type: 'currency' },
            { title: 'Viveiro', field: 'pond.name' },
            { title: 'Cod. Viveiro', field: 'pond.id' },
            { title: 'Cod. Produto', field: 'product.id' },
          ]}
          actions={[
            {
              icon: 'delete',
              tooltip: 'Remover',
              isFreeAction: false,
              onClick: (_, data) =>
                handleOnRemoveOneItem(data as IShoppingItens),
            },
          ]}
          data={itens}
          onAdd={handleCanOpen}
        />

        <div className="farm-add-buttons-actions">
          <Button type="submit" disabled={itens.length === 0}>
            Adicionar
          </Button>
          <Button colorType="cancel" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </Form>

      {open && (
        <DialogScroll
          open={open}
          fullWidth
          maxWidth="lg"
          dialogTitle="Novo item"
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <NewShoppingProduct
            onAddItem={handleOnAddItem}
            onCancel={handleOnToogleDialog}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default NewShoppingBuy;
