import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  max-width: 100%;
  padding: 1px;

  header {
    display: flex;
    color: ${props => props.theme.main};

    a {
      text-decoration: none;
      color: inherit;
    }

    h1 {
      margin-right: 10px;
    }
  }
`;
