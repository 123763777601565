import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { uuid } from 'uuidv4';
import { InputNumber, Select } from '../../../../../../components/Form';
import Button from '../../../../../../components/Button';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { IProduct, IShoppingItens } from '../../../../../../types';
import api from '../../../../../../services/api';
import Loading from '../../../../../../components/Helpers/Information/Loading';
import { useToast } from '../../../../../../hooks/toast';
import Error from '../../../../../../components/Helpers/Information/Error';
import { Container } from './styles';
import { ProductPicker } from '../../../../../../components/Form/ProductPicker';

interface NewShoppingProductData {
  quantity: number;
  unit_price: number;
  discount?: number;
  product_id: string;
  pond_id: string;
}

interface OwnProps {
  onAddItem(item: IShoppingItens): void;
  onCancel(): void;
}

const NewShoppingProduct: React.FC<OwnProps> = ({ onAddItem, onCancel }) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [products, setProducts] = useState<IProduct[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function loadProducts() {
      try {
        setLoading(true);
        const { data } = await api.get<IProduct[]>(`product`, {
          params: api.defaults.params,
        });

        setProducts(data);
      } catch (err) {
        setError(true);
        addToast({
          title: 'Inconsistência no carregamento',
          description:
            'Houve uma inconsistência ao carregar os produtos. Tente novamente',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
    setError(false);
    loadProducts();
  }, [addToast]);

  const handleOnSubmit = useCallback(
    async (data: NewShoppingProductData) => {
      formRef.current?.setErrors({});

      const productFinded = products?.find(
        product => product.id === data.product_id,
      );

      if (!productFinded) {
        return;
      }

      try {
        const schema = Yup.object().shape({
          product_id: Yup.string().required('O campo produto é obrigatório.'),
          quantity: Yup.number().positive().required('O campo é obrigatório.'),
          unit_measure: Yup.string()
            .oneOf(
              ['un', 'kg'],
              'O campo deve ter selecionado Unidade ou Quilograma ',
            )
            .required('O campo é obrigatório'),
          unit_price: Yup.number()
            .positive()
            .required('O campo é obrigatório.'),
          discount: Yup.number().min(0),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        if (!result) return;

        const productItem: IShoppingItens = {
          ...result,
          id: uuid(),
          amount: result.unit_price * result.quantity - (result.discount || 0),
          product: productFinded,
        };

        onAddItem(productItem);
        onCancel();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        }
      }
    },
    [onAddItem, onCancel, products],
  );

  if (error) {
    return (
      <Error message="Um dado necessário não pode ser carregado. Tente novamente" />
    );
  }

  if (loading || !products) {
    return <Loading message="Carregando dados necessários" />;
  }

  return (
    <Container>
      <Form
        ref={formRef}
        onSubmit={handleOnSubmit}
        initialData={{
          unit_measure: 'un',
        }}
      >
        <ProductPicker name="product_id" label="Produto" products={products} />
        <Select
          name="unit_measure"
          label="Unidade de medida"
          options={[
            {
              value: 'un',
              label: 'Unidade',
            },
            { label: 'Quilograma', value: 'kg' },
          ]}
          required={false}
        />

        <InputNumber
          name="quantity"
          label="Quantidade"
          inputProps={{
            suffix: '',
            prefix: '',
          }}
        />

        <InputNumber name="unit_price" label="Valor Unitário" />
        <InputNumber name="discount" label="Desconto" required={false} />

        <div className="farm-add-buttons-actions">
          <Button type="submit">Adicionar</Button>
          <Button colorType="cancel" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewShoppingProduct;
