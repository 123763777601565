import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { uuid } from 'uuidv4';
import { Select, InputNumber } from '../../../../../../components/Form';
import Button from '../../../../../../components/Button';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { IProduct, IPond, IShoppingItens } from '../../../../../../types';
import api from '../../../../../../services/api';
import Loading from '../../../../../../components/Helpers/Information/Loading';
import { useToast } from '../../../../../../hooks/toast';
import Error from '../../../../../../components/Helpers/Information/Error';
import { Container } from './styles';
import { ProductPicker } from '../../../../../../components/Form/ProductPicker';

interface NewShoppingProductData {
  quantity: number;
  unit_price: number;
  discount?: number;
  product_id: string;
  pond_id: string;
  mean_unit_fish?: number;
  biomass?: number;
}

interface OwnProps {
  onAddItem(item: IShoppingItens): void;
  ponds: IPond[];
  itens: IShoppingItens[];
  onCancel(): void;
}

const NewShoppingProduct: React.FC<OwnProps> = ({
  onAddItem,
  ponds,
  itens,
  onCancel,
}) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [selectedProduct, setSelectedProduct] = useState<IProduct>();
  const [products, setProducts] = useState<IProduct[]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [calculating, setCalculating] = useState(false);

  /** Controls of calculator */
  const [quantity, setQuantity] = useState(1);
  const [meanUnitFish, setMeanUnitFish] = useState(0);
  const [biomass, setBiomass] = useState(0);

  useEffect(() => {
    async function loadProducts() {
      try {
        setLoading(true);
        const { data } = await api.get<IProduct[]>(`product`, {
          params: api.defaults.params,
        });
        setProducts(data);
      } catch (err) {
        setError(true);
        addToast({
          title: 'Inconsistência no carregamento',
          description:
            'Houve uma inconsistência ao carregar os produtos. Tente novamente',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
    setError(false);
    loadProducts();
  }, [addToast]);

  const handleOnSubmit = useCallback(
    async (data: NewShoppingProductData) => {
      formRef.current?.setErrors({});

      const productFinded = products?.find(
        product => product.id === data.product_id,
      );

      if (!productFinded) {
        return;
      }

      let stockOnPondForSpecie = 0;

      if (productFinded.category === 'fish') {
        const pond = ponds.find(item => item.id === data.pond_id);

        if (pond) {
          const stock = pond.stocks?.find(
            item => item.specie_id === productFinded.specie_id,
          );

          if (!stock) {
            stockOnPondForSpecie = -1;
          } else {
            stockOnPondForSpecie = stock.weight_approximate / 1000;
          }
        }
      }

      const itensFromSameSpecieAndPond = itens
        .filter(
          item =>
            item.pond_id === data.pond_id &&
            item.product?.specie_id === productFinded.specie_id,
        )
        .reduce((prev, curr) => {
          return prev + (curr.biomass || 0);
        }, 0);

      stockOnPondForSpecie -= itensFromSameSpecieAndPond;

      try {
        const schema = Yup.object().shape({
          product_id: Yup.string().required('O campo produto é obrigatório.'),
          pond_id:
            productFinded?.category === 'fish'
              ? Yup.string().required(
                  'O campo é obrigatório para produtos da categoria peixe',
                )
              : Yup.string(),
          quantity: Yup.number().positive().required('O campo é obrigatório.'),
          mean_unit_fish:
            selectedProduct?.category === 'fish'
              ? Yup.number()
                  .positive('O valor mínimo é 1.')
                  .required('O campo é obrigatório')
              : Yup.number(),
          biomass:
            selectedProduct?.category === 'fish'
              ? Yup.number()
                  .positive('O valor mínimo é 1.')
                  .required('O campo é obrigatório')
                  .max(
                    stockOnPondForSpecie,
                    stockOnPondForSpecie <= 0
                      ? 'Não há estoque para essa espécie nesse viveiro.'
                      : `O máximo contido no estoque para essa espécie nesse viveiro é de ${stockOnPondForSpecie.toLocaleString(
                          'pt-BR',
                          {
                            maximumFractionDigits: 3,
                            minimumFractionDigits: 3,
                          },
                        )} Kg`,
                  )
              : Yup.number(),
          unit_price: Yup.number()
            .positive()
            .required('O campo é obrigatório.'),
          discount: Yup.number().min(0),
        });

        const result = await schema.validate(
          {
            ...data,
            mean_unit_fish:
              selectedProduct?.category === 'fish' ? data.mean_unit_fish : 0,
            biomass: selectedProduct?.category === 'fish' ? data.biomass : 0,
          },
          {
            abortEarly: false,
          },
        );

        if (!result) return;

        let pondFinded: IPond | undefined;

        if (result?.pond_id) {
          pondFinded = ponds.find(pond => pond.id === result.pond_id);
        }

        let amount = 0;

        if (selectedProduct?.category === 'fish') {
          amount = result.biomass * result.unit_price - (result.discount || 0);
        } else {
          amount = result.unit_price * result.quantity - (result.discount || 0);
        }

        const productItem: IShoppingItens = {
          ...result,
          id: uuid(),
          amount,
          product: productFinded,
          pond: pondFinded,
        };

        onAddItem(productItem);
        onCancel();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        }
      }
    },
    [itens, onAddItem, onCancel, ponds, products, selectedProduct],
  );

  function handleOnSelectProduct(product: IProduct) {
    setSelectedProduct(product);
  }

  function handleChangeQuantity(value: number) {
    if (calculating || value === 0) return;

    try {
      setCalculating(true);

      if (selectedProduct?.category === 'fish') {
        if (meanUnitFish > 0) {
          setBiomass((meanUnitFish * value) / 1000);
        } else if (biomass > 0) {
          setMeanUnitFish(Number(((biomass * 1000) / meanUnitFish).toFixed(0)));
        }
      }
      setQuantity(value);
    } finally {
      setCalculating(false);
    }
  }

  function handleChangeMeanWeightFish(value: number) {
    if (calculating || value === 0) return;

    try {
      setCalculating(true);
      if (selectedProduct?.category === 'fish') {
        if (biomass > 0) {
          const integerWeight = Number(((biomass * 1000) / value).toFixed(0));

          setQuantity(integerWeight);
        } else if (quantity > 0) {
          setBiomass((quantity * value) / 1000);
        }

        setMeanUnitFish(value);
      }
    } finally {
      setCalculating(false);
    }
  }

  function handleChangeBiomass(value: number) {
    if (calculating || value === 0) return;

    try {
      setCalculating(true);
      if (selectedProduct?.category === 'fish') {
        if (meanUnitFish > 0) {
          const integerQuantity = Number(
            ((value * 1000) / meanUnitFish).toFixed(0),
          );
          setQuantity(integerQuantity);
        } else if (quantity > 0) {
          setMeanUnitFish(Number(((value * 1000) / quantity).toFixed(0)));
        }
      }
      setBiomass(value);
    } finally {
      setCalculating(false);
    }
  }

  if (error) {
    return (
      <Error message="Um dado necessário não pode ser carregado. Tente novamente" />
    );
  }

  if (loading || !products) {
    return <Loading message="Carregando dados necessários" />;
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <ProductPicker
          name="product_id"
          label="Produto"
          products={products}
          onSelectProduct={handleOnSelectProduct}
        />
        <Select
          name="pond_id"
          label="Viveiro"
          required={false}
          options={ponds.map(pond => ({
            label: pond.name,
            value: pond.id,
          }))}
        />
        <InputNumber
          value={quantity}
          name="quantity"
          onChange={e => handleChangeQuantity(Number(e.target.value))}
          inputProps={{
            suffix: '',
            prefix: '',
          }}
          label={
            selectedProduct?.category === 'fish'
              ? 'Unidades'
              : 'Quantidade (Kg/Un)'
          }
        />

        {selectedProduct?.category === 'fish' && (
          <>
            <InputNumber
              value={meanUnitFish}
              name="mean_unit_fish"
              label="Média em gramas por unidade"
              onChange={e => handleChangeMeanWeightFish(Number(e.target.value))}
              inputProps={{
                suffix: '',
                prefix: '',
              }}
            />
            <InputNumber
              value={biomass}
              name="biomass"
              label="Biomassa (Kg)"
              onChange={e => handleChangeBiomass(Number(e.target.value))}
              inputProps={{
                suffix: '',
                prefix: '',
                decimalScale: 3,
              }}
            />
          </>
        )}

        <InputNumber name="unit_price" label="Valor Unitário" />
        <InputNumber name="discount" label="Desconto" required={false} />

        <div className="farm-add-buttons-actions">
          <Button type="submit">Adicionar</Button>
          <Button colorType="cancel" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewShoppingProduct;
