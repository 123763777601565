import React from 'react';
// import { useTransition } from 'react-spring';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from '@material-ui/core/Snackbar';
import { ToastMessage, useToast } from '../../hooks/toast';
// import Toast from './Toast';
import { Container } from './styles';

interface ToastMessageProps {
  messages: ToastMessage[];
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ToastContainer: React.FC<ToastMessageProps> = ({ messages }) => {
  const { removeToast } = useToast();

  const handleClose = (
    id: string,
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    removeToast(id);
  };

  return (
    <Container>
      {messages.map(message => {
        return (
          <Snackbar
            open
            key={message.id}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={(event?: React.SyntheticEvent, reason?: string) => {
              handleClose(message.id, event, reason);
            }}
          >
            <Alert severity={message.type} variant="standard">
              <AlertTitle>{message.title}</AlertTitle>
              {message.description}
            </Alert>
          </Snackbar>
        );
      })}

      {/* {messagesWithTransitions.map(({ item, key, props }) => {
        return <>{<Toast key={key} message={item} style={props} /> }</>;
      })} */}
    </Container>
  );
};

export default ToastContainer;
