import React, { useState, useEffect, useCallback } from 'react';
import { AxiosError } from 'axios';
import BaseTableDataManager from '../../../components/BaseTableDataManager';
import { ICompany } from '../../../types';
import Error from '../../../components/Helpers/Information/Error';
import Loading from '../../../components/Helpers/Information/Loading';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import { useAuth } from '../../../hooks/auth';
import { DialogScroll } from '../../../components/DialogScroll';
import NewCompany from './NewCompany';
import { formatIdentificador } from '../../../utils/format';
import { errorHandler } from '../../../utils/errorHandler';
import { Company } from '../../../hooks/userCompanies';

const Companies: React.FC = () => {
  const { addToast } = useToast();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    async function loadCompanies() {
      try {
        setError(false);
        const { data } = await api.get<ICompany[]>(`companies`);

        setCompanies(
          data.map(company => ({
            ...company,
            identificatorFormatted: formatIdentificador(company.identificator),
          })),
        );
      } catch (err) {
        addToast({
          title: 'Inconsistência no loading',
          description:
            'Não foi possível carregar as espécies, tente mais tarde.',
          type: 'error',
        });
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadCompanies();
  }, [addToast]);

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  const handleOnAddCompany = useCallback((company: ICompany) => {
    setCompanies(current => [...current, company]);
  }, []);

  async function handleOnDeleteCompany(id: string) {
    try {
      await api.delete(`companies/${id}`);

      addToast({
        title: 'Empresa removida com sucesso!',
      });
    } catch (err) {
      if ((err as AxiosError).isAxiosError) {
        errorHandler(err);
      }
    }
  }

  // async function handleOnClearData(id: string) {
  //   try {
  //     await api.put(`companies/${id}/reset`);

  //     addToast({
  //       title: 'Dados removidos com sucesso!',
  //     });
  //   } catch (err) {
  //     if ((err as AxiosError).isAxiosError) {
  //       errorHandler(err);
  //     }
  //   }
  // }

  if (error) {
    return <Error />;
  }

  if (loading || !companies) {
    return <Loading />;
  }

  return (
    <Container>
      <BaseTableDataManager<ICompany>
        isLoading={loading}
        options={{
          grouping: true,
          pageSizeOptions: [5, 20, 50, companies.length],
          selection: false,
        }}
        title="Empresas"
        onAdd={user.is_admin ? handleOnToogleDialog : undefined}
        columns={[
          {
            title: 'Nome',
            field: 'name',
          },
          {
            title: 'CNPJ',
            field: 'identificatorFormatted',
          },
          {
            title: 'Criada em',
            field: 'created_at',
            type: 'date',
            render: column => {
              return new Date(column.created_at).toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              });
            },
          },
          { title: 'Cód.', field: 'id', grouping: false },
        ]}
        actions={[
          // {
          //   icon: 'clear',
          //   onClick: (_, data) => handleOnClearData((data as Company).id),
          //   tooltip: 'Resetar dados',
          // },
          {
            icon: 'delete',
            onClick: (_, data) => handleOnDeleteCompany((data as Company).id),
            tooltip: 'Remover empresa',
          },
        ]}
        data={companies}
      />
      {open && (
        <DialogScroll
          open={open}
          dialogTitle="Nova Empresa"
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <NewCompany
            onAddCompany={handleOnAddCompany}
            onCancel={handleOnToogleDialog}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default Companies;
