import React, { useState, useEffect, useCallback } from 'react';
import { BsGraphUp } from 'react-icons/bs';
import { useHistory, Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FaPlus } from 'react-icons/fa';
import { useUserCompanies } from '../../hooks/userCompanies';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import DialogScroll from '../../components/DialogScroll';
import { IFarm } from '../../types';
import NewFarm from './New';
import { useCicle } from '../../hooks/useCicle';
import { Container, List, ListItem, ListText } from './styles';

const Farm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { selectedCompany } = useUserCompanies();
  const { selectedCicle } = useCicle();
  const [farms, setFarms] = useState<IFarm[]>([]);

  useEffect(() => {
    async function loadFarms() {
      try {
        const { data } = await api.get<IFarm[]>('/company-farms', {
          params: api.defaults.params,
          headers: api.defaults.headers,
        });

        const totals = data.map(farm => {
          const { ponds } = farm;

          const total_fish = ponds
            .filter(pond => pond.cicle_id === selectedCicle?.id)
            .map(pond => {
              const amount_quantity =
                pond.stocks
                  ?.filter(stock => stock.cicle_id === selectedCicle?.id)
                  .map(stock => stock.fish_count)
                  .reduce((prev, curr) => {
                    return prev + curr;
                  }, 0) || 0;

              return amount_quantity;
            })
            .reduce((prev, curr) => prev + curr, 0);

          return {
            ...farm,
            total_fish,
          };
        });

        setFarms(totals);
      } catch (error) {
        addToast({
          title: 'Inconsistência no loading',
          description:
            'Não foi possível carregar as fazendas, tente mais tarde.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    if (selectedCompany && selectedCicle) {
      loadFarms();
    }
  }, [addToast, selectedCicle, selectedCompany]);

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  const handleOnAddNewFarm = useCallback((farm: IFarm) => {
    setFarms(current => [
      ...current,
      {
        ...farm,
        pct_populate: 0,
        total_fish: 0,
      },
    ]);
  }, []);

  if (!selectedCompany) {
    return <Redirect to="/company-selection" />;
  }

  if (loading || !selectedCompany) {
    return (
      <Container>
        <header>
          <h1>Carregando fazendas...</h1>
        </header>

        <div>
          <CircularProgress />
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <header>
        <h1>Fazendas</h1>
      </header>
      <List>
        {farms.map(farm => {
          return (
            <ListItem
              key={farm.id}
              onClick={() => history.push(`/farms/${farm.id}/show`)}
            >
              <ListText>
                <p>{farm.name}</p>
                <div>
                  <strong>{farm.total_fish}</strong>
                  <p>Peixes</p>
                </div>
                {/* <div>
                  <span>{`${farm.pct_populate}%`}</span>
                  <p>Da área povoada</p>
                </div> */}
              </ListText>
              <BsGraphUp size={50} color="#2DCE98" />
            </ListItem>
          );
        })}
        <ListItem onClick={handleOnToogleDialog}>
          <div className="farm-add-button">
            <FaPlus size="40" />
            <strong>Nova Fazenda</strong>
          </div>
        </ListItem>
      </List>

      {open && (
        <DialogScroll
          open={open}
          dialogTitle="Nova fazenda"
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <NewFarm
            company_id={selectedCompany.id}
            onAddFarm={handleOnAddNewFarm}
            onCancel={handleOnToogleDialog}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default Farm;
