import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Input, InputNumber } from '../../../../components/Form';
import Button from '../../../../components/Button';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { IPond } from '../../../../types';
import { Container } from './styles';
import api from '../../../../services/api';

interface NewPondData {
  name: string;
  capacity: string;
}

interface OwnProps {
  onAddPond(pond: IPond): void;
  farm_id: string;
  onCancel(): void;
}

const NewPond: React.FC<OwnProps> = ({ onAddPond, farm_id, onCancel }) => {
  const formRef = useRef<FormHandles>(null);

  const handleOnSubmit = useCallback(
    async (data: NewPondData) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome do viveiro é obrigatório'),
          capacity: Yup.number()
            .positive('A área do viveiro deve ser positiva.')
            .required('O campo área é obrigatório'),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        const formData = Object.assign(result, { farm_id });

        const response = await api.post<IPond>('pond', formData, {
          params: api.defaults.params,
        });

        onAddPond(response.data);
        onCancel();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        }
      }
    },
    [farm_id, onAddPond, onCancel],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <Input
          name="name"
          placeholder="Insira o nome da viveiro"
          label="Nome da viveiro"
          autoFocus
        />
        <InputNumber
          name="capacity"
          label="Área (hectares)"
          placeholder="Insira a área em hectares do viveiro"
          inputProps={{
            suffix: '',
            prefix: '',
          }}
        />

        <div className="farm-add-buttons-actions">
          <Button type="submit">Adicionar</Button>
          <Button colorType="cancel" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewPond;
