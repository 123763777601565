import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { AxiosError } from 'axios';
import api from '../services/api';
import { useToast } from './toast';
import { useAuth } from './auth';
import { formatIdentificador } from '../utils/format';

interface AxiosErrorResponse {
  status: 'error';
  message: string;
}

export interface Company {
  id: string;
  identificator: string;
  name: string;
  logo_url?: string;
  formattedIdentificator?: string;
}

interface UserCompanyContextData {
  companies: Company[];
  selectedCompany: Company | undefined;
  changeSelectedCompany(company: Company | undefined): Promise<void>;
  loadCompanies(user_id: string): Promise<void>;
  clearStorage(): void;
}

const UserCompanyContext = createContext<UserCompanyContextData>(
  {} as UserCompanyContextData,
);

const UserCompanyProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(
    () => {
      if (!user) return undefined;

      const selectedCompanyStorage = localStorage.getItem(
        `@FarmFish:company-selected:${user.id}`,
      );

      if (selectedCompanyStorage) {
        const companyParsed = JSON.parse(selectedCompanyStorage) as Company;
        api.defaults.params = {
          company_id: companyParsed.id,
        };

        return companyParsed;
      }
      api.defaults.params = null;
      return undefined;
    },
  );

  const [data, setData] = useState<Company[]>(() => {
    if (!user) return [];

    const companiesStorage = localStorage.getItem(
      `@FarmFish:company-list:${user.id}`,
    );

    if (companiesStorage) {
      return JSON.parse(companiesStorage) as Company[];
    }

    return [];
  });

  const loadCompanies = useCallback(async () => {
    if (!user) return;

    const response = await api
      .get<Company[]>('/users/companies')
      .then(res => res)
      .catch((err: AxiosError) => {
        if (!!err.isAxiosError && !err.message) {
          addToast({
            type: 'error',
            title: 'Inconsistência na comunicação',
            description:
              'Não está sendo possível conectar aos servidores, cheque sua conexão ou tente mais tarde.',
          });
        }
        if ([400, 401].includes(err.response?.status || 0)) {
          addToast({
            type: 'error',
            title: 'Inconsistência no loading',
            description: err.response?.data
              ? (err.response?.data as AxiosErrorResponse).message
              : 'Houve um erro no carregamento das empresas, tente novamente.',
          });
        }
      });

    if (!response) return;

    const companyFormatted = response.data.map(item => ({
      ...item,
      formattedIdentificator: formatIdentificador(item.identificator),
    }));

    localStorage.setItem(
      `@FarmFish:company-list:${user.id}`,
      JSON.stringify(companyFormatted),
    );
    try {
      setData(companyFormatted);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Inconsistência no sistema',
        description:
          'Houve uma inconsistência inesperada no sistema. O serviço já foi notificado, desculpe pelo desconforto.',
      });
    }
  }, [user, addToast]);

  useEffect(() => {
    loadCompanies();
  }, [loadCompanies]);

  const changeSelectedCompany = useCallback(
    async (company: Company | undefined) => {
      if (user) {
        setSelectedCompany(company);
        if (!company) {
          localStorage.removeItem(`@FarmFish:company-selected:${user.id}`);
          api.defaults.params = null;
          return;
        }

        localStorage.setItem(
          `@FarmFish:company-selected:${user.id}`,
          JSON.stringify(company),
        );

        api.defaults.params = { company_id: company.id };
      }
    },
    [user],
  );

  const clearStorage = useCallback(() => {
    if (user) {
      localStorage.removeItem(`@FarmFish:company-list:${user.id}`);
      localStorage.removeItem(`@FarmFish:company-selected:${user.id}`);

      api.defaults.params = null;
    }
    setData([]);
  }, [user]);

  return (
    <UserCompanyContext.Provider
      value={{
        selectedCompany,
        companies: data,
        changeSelectedCompany,
        clearStorage,
        loadCompanies,
      }}
    >
      {children}
    </UserCompanyContext.Provider>
  );
};

function useUserCompanies(): UserCompanyContextData {
  const context = useContext(UserCompanyContext);

  if (!context) {
    throw new Error(
      'useUserCompany must be used within an UserCompanyProvider',
    );
  }

  return context;
}

export { UserCompanyProvider, useUserCompanies };
