import React, { useRef, useState, useEffect, useMemo } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { Select, ShoppingPicker } from '../../../../components/Form';
import Button from '../../../../components/Button';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { IPopulates, IShopping } from '../../../../types';
import api from '../../../../services/api';
import Loading from '../../../../components/Helpers/Information/Loading';
import { useToast } from '../../../../hooks/toast';
import Error from '../../../../components/Helpers/Information/Error';
import { useUserCompanies } from '../../../../hooks/userCompanies';
import {
  getAndFormatIdentificator,
  getNameFromParticipant,
  formatDate,
} from '../../../../utils/format';
import { Container, ActionContainer } from './styles';
import { errorHandler } from '../../../../utils/errorHandler';

interface EditShoppingPopulateData {
  shopping_id: string;
  shopping_item_id: string;
  date: Date;
  quantity: number;
  weight: number;
}

interface OwnProps {
  populate: IPopulates;
  onUpdate(id: string): void;
  farm_id: string;
  onCancel(): void;
}

const EditShoppingPopulate: React.FC<OwnProps> = ({
  onUpdate,
  populate,
  farm_id,
  onCancel,
}) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { selectedCompany } = useUserCompanies();
  const [loading, setLoading] = useState(false);
  const [selectedShopping, setSelectedShopping] = useState<
    IShopping | undefined
  >();
  const [itemSelect, setItemSelect] = useState<string | undefined>();
  const [shoppings, setShoppings] = useState<IShopping[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function loadShopping(company_id: string) {
      try {
        setLoading(true);
        const { data } = await api.get<IShopping[]>('shopping', {
          params: {
            farm_id,
            company_id,
            type: 'payment',
            onlyItensWithBalanceAvailable: true,
          },
        });

        const dataFormatted = data
          .map(shopping => {
            const cpfcnpj = getAndFormatIdentificator(shopping.participant);
            const participantName = getNameFromParticipant(
              shopping.participant,
            );
            const dateFormatted = formatDate(new Date(shopping.date));

            return {
              ...shopping,
              cpfcnpj,
              participantName,
              dateFormatted,
              itens:
                shopping.itens
                  ?.map(shoppingItem => ({
                    ...shoppingItem,
                    balance:
                      shoppingItem.quantity - (shoppingItem.balance || 0),
                  }))
                  .filter(
                    item =>
                      item.product?.category === 'fish' && item.balance > 0,
                  ) || [],
            };
          })
          .filter(item => item.itens?.length > 0);

        setShoppings(dataFormatted);
      } catch (err) {
        setError(true);
        addToast({
          title: 'Inconsistência no carregamento',
          description:
            'Houve uma inconsistência ao carregar as compras. Tente novamente',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    setError(false);
    if (selectedCompany?.id && !!farm_id) {
      loadShopping(selectedCompany.id);
    }
  }, [addToast, farm_id, selectedCompany]);

  useEffect(() => {
    setSelectedShopping(
      shoppings.find(shopping => shopping.id === populate.shopping_id),
    );
  }, [populate, shoppings]);

  function handleOnSelectShopping(item: IShopping) {
    setSelectedShopping(item);
  }

  async function handleOnSubmitPopulate(data: EditShoppingPopulateData) {
    formRef.current?.setErrors({});
    try {
      setLoading(true);
      const schema = Yup.object().shape({
        shopping_id: Yup.string()
          .uuid('Valor do campo inválido')
          .required('É obrigatório selecionar uma compra'),
        shopping_item_id: Yup.string()
          .uuid('Valor do campo inválido')
          .required('É obrigatório selecionar um item da compra'),
      });

      const result = await schema.validate(
        {
          ...data,
          shopping_id: selectedShopping?.id,
          shopping_item_id: itemSelect,
        },
        {
          abortEarly: false,
        },
      );

      await api.put<IPopulates>(
        `/populate/fit/bycompany/${populate.id}`,
        result,
      );

      onUpdate(populate.id);
      onCancel();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        formRef.current?.setErrors(getValidationErrors(err));
      } else if ((err as AxiosError).isAxiosError) {
        const { description } = errorHandler(err);
        addToast({
          title: 'Não foi possível criar o povoamento',
          description,
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  }

  const shoppingItens = useMemo(() => {
    if (selectedShopping) {
      return (
        selectedShopping.itens?.map(item => {
          const productName = item.product?.name;
          return {
            value: item.id,
            label: `${productName || `Produto sem nome`} - Saldo(${
              item.balance
            })`,
          };
        }) || []
      );
    }
    return [];
  }, [selectedShopping]);

  if (error) {
    return (
      <Error message="Um dado necessário não pode ser carregado. Tente novamente" />
    );
  }

  if (loading || !shoppings) {
    return <Loading message="Carregando dados necessários" />;
  }

  return (
    <Container>
      <Form
        ref={formRef}
        onSubmit={handleOnSubmitPopulate}
        initialData={{
          shopping_id: selectedShopping
            ? {
                label: `${selectedShopping?.participantName} - ${selectedShopping?.amount}`,
                value: selectedShopping.id,
              }
            : {},
        }}
      >
        <ShoppingPicker
          name="shopping_id"
          label="Compra"
          shoppings={shoppings}
          onSelectShopping={handleOnSelectShopping}
        />
        <Select
          disabled={!selectedShopping}
          name="shopping_item_id"
          label="Item da venda"
          onValueChange={value => setItemSelect(String(value))}
          options={shoppingItens}
          required
        />
        <ActionContainer>
          <Button type="submit" isLoading={loading} disabled={loading}>
            Próximo
          </Button>
          <Button colorType="cancel" onClick={onCancel} disabled={loading}>
            Cancelar
          </Button>
        </ActionContainer>
      </Form>
    </Container>
  );
};

export default EditShoppingPopulate;
