import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { AxiosError } from 'axios';
import BaseTableDataManager from '../../../../components/BaseTableDataManager';
import { IPond, IPopulates } from '../../../../types';
import Error from '../../../../components/Helpers/Information/Error';
import Loading from '../../../../components/Helpers/Information/Loading';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import DialogScroll from '../../../../components/DialogScroll';
import NewPopulate from './NewPopulate';
import { Container } from './styles';
import { errorHandler } from '../../../../utils/errorHandler';
import { useCicle } from '../../../../hooks/useCicle';

interface RouteParams {
  id: string;
}

type callbackType = () => void | Promise<void>;

const Populates: React.FC = () => {
  const { addToast } = useToast();
  const { id } = useParams<RouteParams>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { selectedCicle } = useCicle();
  const [pond, setPond] = useState<IPond | undefined>();
  const [open, setOpen] = useState(false);

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  useEffect(() => {
    async function loadPondPopulates(pond_id: string, cicle_id: string) {
      try {
        setError(false);
        const { data } = await api.get<IPond>(`pond/${pond_id}/populates`, {
          params: { ...api.defaults.params, cicle_id },
        });

        setPond({
          ...data,
          populates: data.populates?.filter(
            populate => populate.cicle_id === cicle_id,
          ),
        });
      } catch (err) {
        addToast({
          title: 'Inconsistência no loading',
          description:
            'Não foi possível carregar as populações do viveiro, tente mais tarde.',
          type: 'error',
        });
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    if (id && selectedCicle?.id) {
      loadPondPopulates(id, selectedCicle.id);
    }
  }, [id, addToast, selectedCicle]);

  const handleOnAddNewPopulate = useCallback((populate: IPopulates) => {
    setPond(current => {
      if (!current) return undefined;

      const { populates = [] } = current;

      return {
        ...current,
        populates: [...populates, populate],
      };
    });
  }, []);

  function checkIsSameCicle(callback: callbackType) {
    if (pond && pond.cicle_id === selectedCicle?.id) {
      callback();
    } else {
      addToast({
        title: 'Viveiro atualmente está em outro ciclo',
        description:
          'As operações de ajuste neste ciclo foram bloqueadas devido o início de outro.',
        type: 'warning',
      });
    }
  }

  const handleOnDeleteRow = useCallback(
    async (populate: IPopulates) => {
      setLoading(true);

      try {
        await api.delete(`populate/${populate.id}`);

        setPond(current => {
          if (!current) return undefined;

          return {
            ...current,
            populates: current.populates?.filter(
              item => item.id !== populate.id,
            ),
          };
        });

        addToast({
          title: 'Remoção concluída com sucesso',
          type: 'success',
        });
      } catch (err) {
        if ((err as AxiosError).isAxiosError) {
          const { description } = errorHandler(err);
          addToast({
            title: 'Remoção não foi possível',
            description: `A remoção do povoamento ${populate.id} não foi possível. Motivo: ${description}`,
            type: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  if (error) {
    return <Error />;
  }

  if (loading || !pond || !selectedCicle) {
    return <Loading />;
  }

  return (
    <Container>
      <header>
        <Link to={`/farms/${pond.farm_id}/fishtanks`}>
          <h1>{pond.name}</h1>
        </Link>
        <h1>{`>`}</h1>
        <h1>Povoamentos</h1>
      </header>
      <BaseTableDataManager<IPopulates>
        isLoading={loading}
        options={{
          grouping: true,
          selection: false,
          pageSizeOptions:
            pond.populates && pond.populates.length > 50
              ? [5, 20, 50, pond.populates.length]
              : [5, 20, 50],
        }}
        title="Povoamentos"
        columns={[
          {
            title: 'Data',
            field: 'date',
            type: 'date',
            render: (column: IPopulates) => {
              return format(parseISO(column.date), 'dd/MM/yyyy');
            },
          },
          {
            title: 'Quantidade',
            field: 'quantity',
            type: 'numeric',
            render: (column: IPopulates) => {
              return column.quantity.toLocaleString('pt-BR');
            },
          },
          {
            title: 'Peso',
            field: 'weight',
            type: 'numeric',
            render: (column: IPopulates) => {
              return column.weight.toLocaleString('pt-BR');
            },
          },
          {
            title: 'Espécie',
            field: 'specie.name',
          },
          { title: 'Compra', field: 'shopping_id' },
          { title: 'Cód.', field: 'id', grouping: false },
        ]}
        data={pond.populates || []}
        onAdd={() => checkIsSameCicle(() => setOpen(true))}
        onDeleteMany={data =>
          checkIsSameCicle(() => handleOnDeleteRow(data as IPopulates))
        }
      />

      {open && (
        <DialogScroll
          open={open}
          fullWidth
          maxWidth="md"
          dialogTitle="Novo povoamento"
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <NewPopulate
            pond_id={pond.id}
            cicle_id={selectedCicle.id}
            farm_id={pond.farm_id}
            onAddPopulate={handleOnAddNewPopulate}
            onCancel={handleOnToogleDialog}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default Populates;
