import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const params = config.params || {};
  const headers = config.headers || {};
  return { ...config, params, headers };
});

axios.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  },
);

export default api;
