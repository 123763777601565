import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Input, Select } from '../../../../components/Form';
import Button from '../../../../components/Button';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { ISpecie, Options, IProduct } from '../../../../types';
import api from '../../../../services/api';
import Loading from '../../../../components/Helpers/Information/Loading';
import { useToast } from '../../../../hooks/toast';
import Error from '../../../../components/Helpers/Information/Error';
import { Container } from './styles';

interface NewProductData {
  name: string;
  description: string;
  internal_code: string;
  specie_id: string;
  category: string;
}

interface OwnProps {
  onAddProduct(product: IProduct): void;
  company_id: string;
  onCancel(): void;
}

const NewProduct: React.FC<OwnProps> = ({
  onAddProduct,
  company_id,
  onCancel,
}) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [species, setSpecies] = useState<Options[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function loadSpecies() {
      try {
        setLoading(true);
        const { data } = await api.get<ISpecie[]>('species');

        setSpecies(
          data.map(specie => ({
            value: specie.id,
            label: specie.name,
          })),
        );
      } catch (err) {
        setError(true);
        addToast({
          title: 'Inconsistência no carregamento',
          description:
            'Houve uma inconsistência ao carregar as espécies. Tente novamente',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    setError(false);
    loadSpecies();
  }, [addToast]);

  const handleOnSubmit = useCallback(
    async (data: NewProductData) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('O campo nome é obrigatório'),
          description: Yup.string(),
          internal_code: Yup.string(),
          specie_id: Yup.string().when('category', {
            is: 'fish',
            then: Yup.string()
              .uuid('Valor do campo inválido')
              .required('É obrigatório selecionar a espécie'),
            otherwise: Yup.string(),
          }),
          category: Yup.string()
            .oneOf([
              'fish',
              'machines',
              'implement',
              'ration',
              'general',
              'inputs',
            ])
            .required('É obrigatório selecionar uma compra'),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        const formData = Object.assign(result, { company_id });

        const response = await api.post<IProduct>('product', formData);

        onAddProduct(response.data);
        onCancel();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        }
      }
    },
    [company_id, onCancel, onAddProduct],
  );

  if (error) {
    return (
      <Error message="Um dado necessário não pode ser carregado. Tente novamente" />
    );
  }

  if (loading || !species) {
    return <Loading message="Carregando dados necessários" />;
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <Input name="name" label="Nome" placeholder="O nome do produto" />
        <Input
          name="description"
          label="Descrição"
          placeholder="Insira a descrição do produto"
          multiline
          rows={3}
          required={false}
        />
        <Input name="internal_code" label="Cód. Interno" required={false} />
        <Select
          name="specie_id"
          label="Espécie"
          options={species}
          required={false}
        />
        <Select
          name="category"
          label="Categoria"
          options={[
            { value: 'general', label: 'Geral' },
            { value: 'fish', label: 'Peixe' },
            { value: 'machines', label: 'Máquina' },
            { value: 'implement', label: 'Implemento' },
            { value: 'ration', label: 'Ração' },
            { value: 'inputs', label: 'Insumos' },
          ]}
          required
        />

        <div className="farm-add-buttons-actions">
          <Button type="submit">Adicionar</Button>
          <Button colorType="cancel" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewProduct;
