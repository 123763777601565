import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Input, InputNumber } from '../../../components/Form';
import Button from '../../../components/Button';
import getValidationErrors from '../../../utils/getValidationErrors';
import { IFarm } from '../../../types';
import { Container } from './styles';
import api from '../../../services/api';

interface NewFarmData {
  name: string;
  hectares: string;
}

interface OwnProps {
  onAddFarm(farm: IFarm): void;
  company_id: string;
  onCancel(): void;
}

const NewFarm: React.FC<OwnProps> = ({ onAddFarm, company_id, onCancel }) => {
  const formRef = useRef<FormHandles>(null);

  const handleOnSubmit = useCallback(
    async (data: NewFarmData) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('O nome da fazenda é obrigatório'),
          hectares: Yup.number()
            .positive('Os hectares da fazenda devem ser positivos')
            .required('O campo hectares é obrigatório'),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        const formData = Object.assign(result, { company_id });

        const response = await api.post<IFarm>('farms', formData);

        onAddFarm(response.data);
        onCancel();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        }
      }
    },
    [company_id, onCancel, onAddFarm],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <Input
          name="name"
          placeholder="Insira o nome da fazenda"
          label="Nome da fazenda"
          autoFocus
        />
        <InputNumber
          name="hectares"
          label="Hectares"
          placeholder="Insira os hectares da fazenda"
          inputProps={{
            suffix: '',
            prefix: '',
          }}
        />

        <div className="farm-add-buttons-actions">
          <Button type="submit">Adicionar</Button>
          <Button colorType="cancel" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewFarm;
