import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    font-size: 1rem;
  }
`;
