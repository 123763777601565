import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { Input, Select } from '../../../../components/Form';
import Button from '../../../../components/Button';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { IUser, IParticipant } from '../../../../types';
import api from '../../../../services/api';
import Loading from '../../../../components/Helpers/Information/Loading';
import { useToast } from '../../../../hooks/toast';
import Error from '../../../../components/Helpers/Information/Error';
import { Container } from './styles';
import {
  getAndFormatIdentificator,
  getNameFromParticipant,
} from '../../../../utils/format';
import { ParticipantPicker } from '../../../../components/Form/ParticipantPicker';
import { errorHandler } from '../../../../utils/errorHandler';

interface NewUserData {
  name: string;
  email: string;
  participant_id?: string;
  role_id: string;
}

interface OwnProps {
  onAddUser(user: IUser): void;
  company_id: string;
  onCancel(): void;
}

const NewUser: React.FC<OwnProps> = ({ onAddUser, company_id, onCancel }) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [participants, setParticipants] = useState<IParticipant[]>([]);

  useEffect(() => {
    async function loadParticipants(id: string) {
      try {
        setLoading(true);
        const { data } = await api.get<IParticipant[]>(
          `companies/${id}/members`,
        );

        setParticipants(
          data.map(participant => {
            const identificator = getAndFormatIdentificator(participant);
            const nameFormatted = getNameFromParticipant(participant);

            return {
              ...participant,
              nameFormatted,
              identificator,
            };
          }),
        );
      } catch (err) {
        setError(true);
        addToast({
          title: 'Inconsistência no carregamento',
          description:
            'Houve uma inconsistência ao carregar os participantes. Tente novamente',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    setError(false);
    loadParticipants(company_id);
  }, [addToast, company_id]);

  const handleOnSubmit = useCallback(
    async (data: NewUserData) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('O campo nome é obrigatório'),
          password: Yup.string()
            .min(6, 'Mínimo de 6 caracteres')
            .required('O campo nome é obrigatório'),
          email: Yup.string()
            .email('E-mail inválido')
            .required('O campo é obrigatório'),
          participant_id: Yup.string(),
          roles: Yup.string()
            .oneOf(
              ['client', 'consultant'],
              'É apenas permitido o cadastro de regras de cliente e consultor',
            )
            .required('É obrigatório selecionar uma regra/papel'),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post<IUser>('users', {
          ...result,
          roles: [result?.roles],
          company_id,
        });

        onAddUser(response.data);
        onCancel();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        } else if ((err as AxiosError).isAxiosError) {
          const { description } = errorHandler(err);

          addToast({
            title: 'Não foi possível adicionar usuário',
            description,
            type: 'error',
          });
        }
      }
    },
    [company_id, onAddUser, onCancel, addToast],
  );

  if (error) {
    return (
      <Error message="Um dado necessário não pode ser carregado. Tente novamente" />
    );
  }

  if (loading || !participants) {
    return <Loading message="Carregando dados necessários" />;
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <Input name="name" label="Nome" placeholder="O nome do usuário" />
        <Input
          name="password"
          label="Senha"
          placeholder="A senha do usuário. Esta será obrigatoriamente trocada no primeiro login"
        />

        <Input
          name="email"
          label="E-mail"
          placeholder="Insira o email do usuário"
          type="email"
        />

        <ParticipantPicker
          name="participant_id"
          label="Participante"
          participants={participants}
          required={false}
        />

        <Select
          name="roles"
          label="Regra/Papel"
          options={[
            { value: 'client', label: 'Cliente' },
            { value: 'consultant', label: 'Consultor' },
          ]}
          required
        />

        <div className="farm-add-buttons-actions">
          <Button type="submit">Adicionar</Button>
          <Button colorType="cancel" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewUser;
