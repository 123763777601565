import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;

  section {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

export const Header = styled.header`
  display: flex;
  min-width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin: 0px 20px;
    }

    img {
      margin-right: 20px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    a {
      color: #d9ebdd;
    }
  }
`;

export const Content = styled.section`
  padding: 20px;
  background: #d9ebdd;
`;
