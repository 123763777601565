import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  main: '#102D45',
  secondary: '#5BB77F',
  buttons: {
    primary: '#102D45',
    secondary: '#5BB77F',
  },
  text: {
    dark: '#102D45',
    ligth: '#5BB77F',
    white: '#F7FAFC',
    softLight: '#D9EBDD',
  },
  rightMenu: {
    background: '#D9EBDD',
    textColor: '#5BB77F',
    selectedBackground: '#102D45',
  },
  cards: {
    strongText: '#4D4F5C',
    ligthText: '#8898AA',
  },
  input: {
    placeholder: '#172B4D',
    background: '#F7FAFC',
    iconDefaultColor: '#F7FAFC',
    iconFilledColor: '#172B4D',
    iconErrorColor: '#F53C56',
    iconSuccessColor: '#2DCE98',
  },
};

export default theme;
