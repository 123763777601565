import React from 'react';

import { BiErrorCircle } from 'react-icons/bi';
import { Container } from './styles';

interface ErrorProps {
  message?: string;
}

const Error: React.FC<ErrorProps> = ({ message, children, ...rest }) => {
  return (
    <Container {...rest}>
      <BiErrorCircle size={40} />
      <h1>{message || 'Ops, algo deu errado. Tente novamente.'}</h1>
      {children}
    </Container>
  );
};

export default Error;
