import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  color: ${props => props.theme.main};
`;

export const List = styled.ul`
  display: grid;
  flex: 1;
  grid-template-columns: repeat(auto-fill, max(240px));
  gap: 10px;
  margin: 10px 0;
`;

export const ListItem = styled.a`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 18px 20px;
  cursor: pointer;
  background: ${props => props.theme.main};

  svg {
    margin-left: 11px;
  }

  .farm-add-button {
    display: flex;
    flex: 1;
    margin: 0 auto;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    color: ${props => props.theme.text.softLight};
    font-size: 1rem;
    opacity: 0.6;
    transition: opacity 0.2s;

    svg {
      color: ${props => props.theme.text.softLight};
    }

    &:hover {
      opacity: 1;
    }
  }
`;

export const ListText = styled.div`
  p {
    font-size: 14px;
    color: ${props => props.theme.text.softLight};
    font-weight: 500;
  }

  div {
    display: flex;
    align-items: center;

    strong {
      font-size: 20px;
      color: ${props => props.theme.text.white};
      margin-right: 5px;
    }

    span {
      color: #2dce98;
      margin-right: 10px;
    }

    & + div {
      font-size: 10px;

      p {
        font-size: 10px;
      }
    }
  }
`;
