import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: ${props => props.theme.main};
`;

export const Content = styled.div`
  flex-grow: 1;
  display: flex;
`;

export const TabsContainer = styled.div``;

export const ControlMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ReportArea = styled(Paper).attrs({
  elevation: 3,
  variant: 'outlined',
})`
  flex-grow: 1;
  background: inherit;
  overflow-x: auto;
  overflow-y: auto;
`;
