import React, { useRef, useEffect, useState, useCallback } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useField } from '@unform/core';
import Input from '@material-ui/core/Input';
import Select, { SelectProps } from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { Options } from '../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
    },
  }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface SelectComponentProps extends SelectProps {
  name: string;
  label?: string;
  options: Options[];
  required?: boolean;
  onValueChange?(value: string | string[] | number | number[]): void;
  containerStyle?: React.CSSProperties;
}

export const SelectComponent: React.FC<SelectComponentProps> = ({
  name,
  label,
  options,
  multiple = false,
  required = true,
  onValueChange,
  containerStyle = {},
  ...rest
}) => {
  const classes = useStyles();
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [selectedValue, setSelectedValue] = useState<
    string | string[] | number | number[]
  >(() => {
    if (defaultValue) {
      return multiple ? (defaultValue as string[]) : String(defaultValue);
    }
    if (multiple) {
      return [];
    }

    return '';
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: () => selectedValue,
    });
  }, [fieldName, registerField, selectedValue]);

  useEffect(() => {
    if (onValueChange) {
      onValueChange(selectedValue);
    }
  }, [selectedValue, onValueChange]);

  const handleOnChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: React.ChangeEvent<{ value: any }>) => {
      if (multiple) {
        setSelectedValue(event.target.value as string[]);
      } else {
        setSelectedValue(event.target.value as string);
      }
    },
    [multiple],
  );

  return (
    <FormControl className={classes.formControl} style={containerStyle}>
      <InputLabel htmlFor={fieldName}>{label}</InputLabel>
      <Select
        required={required}
        inputRef={selectRef}
        input={<Input />}
        inputProps={{
          id: fieldName,
        }}
        variant="outlined"
        value={selectedValue}
        onChange={handleOnChange}
        MenuProps={MenuProps}
        multiple={multiple}
        {...rest}
      >
        <MenuItem value="">
          <em>Nenhum</em>
        </MenuItem>
        {options.map(({ value, label: optionLabel }) => (
          <MenuItem key={value} value={value}>
            {multiple && (
              <Checkbox checked={(selectedValue as string[]).includes(value)} />
            )}
            <ListItemText primary={optionLabel || (defaultValue as string)} />
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectComponent;
