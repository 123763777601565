import React, { useRef, useState, useEffect, useMemo } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import {
  InputNumber,
  DatePicker,
  Select,
  ShoppingPicker,
} from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { IPopulates, IShopping } from '../../../../../types';
import api from '../../../../../services/api';
import Loading from '../../../../../components/Helpers/Information/Loading';
import { useToast } from '../../../../../hooks/toast';
import Error from '../../../../../components/Helpers/Information/Error';
import { useUserCompanies } from '../../../../../hooks/userCompanies';
import {
  getAndFormatIdentificator,
  getNameFromParticipant,
  formatDate,
} from '../../../../../utils/format';
import { Container, ActionContainer } from './styles';
import { errorHandler } from '../../../../../utils/errorHandler';

interface NewPopulateData {
  shopping_id: string;
  shopping_item_id: string;
  date: Date;
  quantity: number;
  weight: number;
}

interface OwnProps {
  onAddPopulate(populate: IPopulates): void;
  pond_id: string;
  farm_id: string;
  cicle_id: string;
  onCancel(): void;
}

const NewPopulate: React.FC<OwnProps> = ({
  onAddPopulate,
  pond_id,
  farm_id,
  cicle_id,
  onCancel,
}) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { selectedCompany } = useUserCompanies();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedShopping, setSelectedShopping] = useState<
    IShopping | undefined
  >();
  const [itemSelect, setItemSelect] = useState<string | undefined>();
  const [shoppings, setShoppings] = useState<IShopping[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function loadShopping(company_id: string) {
      try {
        setLoading(true);
        const { data } = await api.get<IShopping[]>('shopping', {
          params: {
            cicle_id,
            farm_id,
            company_id,
            type: 'payment',
            onlyItensWithBalanceAvailable: true,
          },
        });

        const dataFormatted = data
          .map(shopping => {
            const cpfcnpj = getAndFormatIdentificator(shopping.participant);
            const participantName = getNameFromParticipant(
              shopping.participant,
            );
            const dateFormatted = formatDate(new Date(shopping.date));

            return {
              ...shopping,
              cpfcnpj,
              participantName,
              dateFormatted,
              itens:
                shopping.itens
                  ?.map(shoppingItem => ({
                    ...shoppingItem,
                    balance:
                      shoppingItem.quantity - (shoppingItem.balance || 0),
                  }))
                  .filter(
                    item =>
                      item.product?.category === 'fish' && item.balance > 0,
                  ) || [],
            };
          })
          .filter(item => item.itens?.length > 0);

        setShoppings(dataFormatted);
      } catch (err) {
        setError(true);
        addToast({
          title: 'Inconsistência no carregamento',
          description:
            'Houve uma inconsistência ao carregar as compras. Tente novamente',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    setError(false);
    if (selectedCompany?.id && !!farm_id && !!cicle_id) {
      loadShopping(selectedCompany.id);
    }
  }, [addToast, cicle_id, farm_id, selectedCompany]);

  function handleOnSelectShopping(item: IShopping) {
    setSelectedShopping(item);
  }

  async function handleOnSubmitShoppingSelectStep() {
    formRef.current?.setErrors({});

    try {
      const schema = Yup.object().shape({
        shopping_id: Yup.string()
          .uuid('Valor do campo inválido')
          .required('É obrigatório selecionar uma compra'),
        shopping_item_id: Yup.string()
          .uuid('Valor do campo inválido')
          .required('É obrigatório selecionar um item da compra'),
      });

      await schema.validate(
        {
          shopping_id: selectedShopping?.id,
          shopping_item_id: itemSelect,
        },
        {
          abortEarly: false,
        },
      );

      setActiveStep(current => current + 1);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        formRef.current?.setErrors(getValidationErrors(err));
      }
    }
  }

  async function handleOnSubmitPopulate(data: NewPopulateData) {
    formRef.current?.setErrors({});
    setActiveStep(current => current + 1);
    try {
      const selectedItem = selectedShopping?.itens?.find(
        item => item.id === itemSelect,
      );

      const schema = Yup.object().shape({
        shopping_id: Yup.string()
          .uuid('Valor do campo inválido')
          .required('É obrigatório selecionar uma compra'),
        shopping_item_id: Yup.string()
          .uuid('Valor do campo inválido')
          .required('É obrigatório selecionar um item da compra'),
        quantity: Yup.number()
          .positive('A quantidade deve ser positiva.')
          .max(
            selectedItem?.balance || 0,
            `Quantidade ultrapassa o máximo disponível de ${selectedItem?.balance}`,
          )
          .required('O campo quantidade é obrigatório'),
        weight: Yup.number()
          .positive('O peso deve ser positivo.')
          .required('O campo peso médio em gramas é obrigatório'),
        date: Yup.date().required('O campo data do povoamento é obrigatório.'),
        specie_id: Yup.string()
          .uuid('Valor do campo inválido')
          .required('É obrigatório selecionar a espécie'),
      });

      const selectedItemSpecie = selectedItem?.product?.specie_id;

      const result = await schema.validate(
        {
          ...data,
          shopping_id: selectedShopping?.id,
          shopping_item_id: itemSelect,
          specie_id: selectedItemSpecie,
        },
        {
          abortEarly: false,
        },
      );

      const formData = Object.assign(result, { pond_id });

      const response = await api.post<IPopulates>('populate', formData, {
        params: api.defaults.params,
      });

      onAddPopulate(response.data);
      onCancel();
    } catch (err) {
      setActiveStep(current => current - 1);
      if (err instanceof Yup.ValidationError) {
        formRef.current?.setErrors(getValidationErrors(err));
      } else if ((err as AxiosError).isAxiosError) {
        const { description } = errorHandler(err);
        addToast({
          title: 'Não foi possível criar o povoamento',
          description,
          type: 'error',
        });
      }
    }
  }

  const shoppingItens = useMemo(() => {
    if (selectedShopping) {
      return (
        selectedShopping.itens?.map(item => {
          const productName = item.product?.name;
          return {
            value: item.id,
            label: `${productName || `Produto sem nome`} - Saldo(${
              item.balance
            })`,
          };
        }) || []
      );
    }
    return [];
  }, [selectedShopping]);

  if (error) {
    return (
      <Error message="Um dado necessário não pode ser carregado. Tente novamente" />
    );
  }

  if (loading || !shoppings) {
    return <Loading message="Carregando dados necessários" />;
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmitPopulate}>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step>
            <StepLabel>Seleção de compra</StepLabel>
            <StepContent>
              <ShoppingPicker
                name="shopping_id"
                label="Compra"
                shoppings={shoppings}
                onSelectShopping={handleOnSelectShopping}
              />
              <Select
                disabled={!selectedShopping}
                name="shopping_item_id"
                label="Item da venda"
                onValueChange={value => setItemSelect(String(value))}
                options={shoppingItens}
                required
              />
              <ActionContainer>
                <Button onClick={handleOnSubmitShoppingSelectStep}>
                  Próximo
                </Button>
                <Button colorType="cancel" onClick={onCancel}>
                  Cancelar
                </Button>
              </ActionContainer>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Informações do povoamento</StepLabel>
            <StepContent>
              <DatePicker name="date" label="Data do povoamento" />
              <InputNumber
                name="quantity"
                label="Quantidade"
                autoFocus
                type="number"
                placeholder="Insira a quantidade povoada"
                inputProps={{
                  suffix: '',
                  prefix: '',
                }}
                style={{ marginBottom: 15 }}
              />

              <InputNumber
                name="weight"
                label="Peso médio em gramas"
                placeholder="Insira o peso médio dos peixes povoados"
                inputProps={{
                  suffix: '',
                  prefix: '',
                }}
              />
              <ActionContainer>
                <Button type="submit">Cadastrar</Button>
                <Button colorType="cancel" onClick={onCancel}>
                  Cancelar
                </Button>
              </ActionContainer>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Confirmação do povoamento</StepLabel>
            <StepContent>
              <Loading message="Validando povoamento" />
            </StepContent>
          </Step>
        </Stepper>
      </Form>
    </Container>
  );
};

export default NewPopulate;
