import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Input, InputMask } from '../../../../components/Form';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { ICompany } from '../../../../types';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import { Container, FormStyled as Form } from './styles';
import Button from '../../../../components/Button';
import { cnpjMaskDefinition } from '../../../../utils/format';

interface NewCompanyData {
  identificator: string;
  name: string;
}

interface OwnProps {
  onAddCompany(company: ICompany): void;
  onCancel(): void;
}

const NewCompany: React.FC<OwnProps> = ({ onAddCompany, onCancel }) => {
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);

  const handleOnSubmit = useCallback(
    async (data: NewCompanyData) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('O campo nome da espécie é obrigatório'),
          identificator: Yup.string()
            .min(11, 'É necessário que o campo contenha no mínimo 11 caracteres')
            .required('O campo é obrigatório'),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post<ICompany>('companies', result);

        addToast({
          title: 'Sucesso!',
          description: `Nova empresa ${response.data.name} adicionada.`,
          type: 'success',
        });
        onAddCompany(response.data);
        onCancel();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        }
      }
    },
    [onAddCompany, onCancel, addToast],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <Input
          name="name"
          label="Razão Social"
          placeholder="Insira a razão social da empresa"
        />

        <InputMask
          name="identificator"
          label="CNPJ"
          mask={cnpjMaskDefinition}
        />

        <div className="farm-add-buttons-actions">
          <Button type="submit">Adicionar</Button>
          <Button colorType="cancel" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewCompany;
