import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Input } from '../../../../components/Form';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { ISpecie } from '../../../../types';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import { Container, FormStyled as Form } from './styles';
import Button from '../../../../components/Button';

interface NewParticipantData {
  name: string;
}

interface OwnProps {
  onAddSpecie(specie: ISpecie): void;
  onCancel(): void;
}

const NewSpecie: React.FC<OwnProps> = ({ onAddSpecie, onCancel }) => {
  const { addToast } = useToast();

  const formRef = useRef<FormHandles>(null);

  const handleOnSubmit = useCallback(
    async (data: NewParticipantData) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('O campo nome da espécie é obrigatório'),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post<ISpecie>('species', result);

        addToast({
          title: 'Sucesso!',
          description: `Nova espcéci ${response.data.name} adicionada.`,
          type: 'success',
        });
        onAddSpecie(response.data);
        onCancel();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        }
      }
    },
    [onAddSpecie, onCancel, addToast],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <Input
          name="name"
          label="Espécie"
          placeholder="Insira o nome da espécie"
        />

        <div className="farm-add-buttons-actions">
          <Button type="submit">Adicionar</Button>
          <Button colorType="cancel" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewSpecie;
