/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Chart from 'react-apexcharts';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useUserCompanies } from '../../../../hooks/userCompanies';
import { IFarm } from '../../../../types';
import { errorHandler } from '../../../../utils/errorHandler';
import api from '../../../../services/api';
import { Select } from '../../../../components/Form';
import { useCicle } from '../../../../hooks/useCicle';
import { Container } from './styles';

interface DataItem {
  x: string;
  y: number;
}

interface LineData {
  id: string;
  data: DataItem[];
}

interface OwnProps {
  height: number;
  width: number;
}

interface ChartSeries {
  name: string;
  data: number[];
}

const StockByPond: React.FC<OwnProps> = ({ height, width }) => {
  const formRef = useRef<FormHandles>(null);
  const { selectedCompany } = useUserCompanies();
  const { selectedCicle, cicles } = useCicle();
  const [actualCicle, setActualCicle] = useState(selectedCicle?.id);
  const [loading, setLoading] = useState(true);
  const [farms, setFarms] = useState<IFarm[]>([]);
  const [selectedFarm, setSelectedFarm] = useState<string | undefined>();
  // const [data, setData] = useState<LineData[]>([]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get<IFarm[]>('reports/stockbypond', {
          params: api.defaults.params,
        });

        setFarms(response.data);
        if (response.data.length > 0) {
          setSelectedFarm(response.data[0].id);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }

    if (selectedCompany) {
      loadData();
    }
  }, [selectedCompany]);

  useEffect(() => {
    const farm = farms.find(item => item.id === selectedFarm);

    if (farm && actualCicle) {
      const arrayOfData: LineData[] = [];

      farm.ponds.forEach(pond => {
        pond.stocks
          ?.filter(stock => stock.cicle_id === actualCicle)
          .forEach(stock => {
            if (stock.specie?.name) {
              const newItem = {
                x: pond.name,
                y: Number((stock.weight_approximate / 1000).toFixed(3)),
              };

              const specieIndex = arrayOfData.findIndex(
                item => item.id === stock.specie?.name,
              );

              if (specieIndex >= 0) {
                arrayOfData[specieIndex].data = [
                  ...arrayOfData[specieIndex].data,
                  newItem,
                ];
              } else {
                arrayOfData.push({
                  id: stock.specie.name,
                  data: [newItem],
                });
              }
            }
          });
      });

      // setData(arrayOfData);
    } else {
      // setData([]);
    }
  }, [actualCicle, farms, selectedFarm]);

  const handleOnChangeFarm = useCallback((value: string) => {
    setSelectedFarm(value);
  }, []);

  const farmsOptions = useMemo(() => {
    return farms.map(farm => ({
      label: farm.name,
      value: farm.id,
    }));
  }, [farms]);

  const cicleOptions = useMemo(() => {
    return cicles
      .sort((a, b) => a.sequence - b.sequence)
      .map(cicle => ({
        label: cicle.description,
        value: cicle.id,
      }));
  }, [cicles]);

  const ChartData = useMemo(() => {
    const pondNames: string[] = [];
    let seriesSpecies: ChartSeries[] = [];

    const farm = farms.find(item => item.id === selectedFarm);

    if (!farm) {
      return {
        categories: pondNames,
        series: seriesSpecies,
      };
    }
    const speciesFinded: string[] = [];
    farm.ponds.forEach(pond => {
      pondNames.push(pond.name);
      /**
       * primeiro pegar todas as especies dos stocks
       */

      pond.stocks
        ?.filter(stock => stock.cicle_id === actualCicle)
        .forEach(stock => {
          const name = stock.specie?.name || 'Não recuperado';
          if (!speciesFinded.includes(name)) {
            speciesFinded.push(name);
          }
        });
    });
    const speciesFindedSorted = speciesFinded.sort();

    seriesSpecies = speciesFindedSorted.map(name => ({
      data: [],
      name,
    }));

    farm.ponds.forEach((pond, index) => {
      const stocks =
        pond.stocks?.filter(stock => stock.cicle_id === actualCicle) || [];

      speciesFindedSorted.forEach(specie => {
        const stockFromPond = stocks.find(
          stock => (stock.specie?.name || 'Não recuperado') === specie,
        );

        seriesSpecies[index] &&
          seriesSpecies[index].data.push(
            stockFromPond
              ? Number((stockFromPond.weight_approximate / 1000).toFixed(3))
              : 0,
          );
      });
    });

    return {
      categories: pondNames,
      series: seriesSpecies,
    };
  }, [actualCicle, farms, selectedFarm]);

  if (!selectedCompany) {
    return <h1>Selecione uma empresa primeiramente</h1>;
  }

  if (loading) {
    return <h1>Carregando informações...</h1>;
  }

  return (
    <Container>
      <Form
        ref={formRef}
        onSubmit={() => console.log()}
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Select
          required
          name="farm_id"
          label="Fazenda"
          options={farmsOptions || []}
          value={selectedFarm}
          onChange={event => handleOnChangeFarm(event.target.value as string)}
        />
        <Select
          required
          name="cicle_id"
          label="Ciclo"
          options={cicleOptions || []}
          value={actualCicle}
          onChange={event => setActualCicle(event.target.value as string)}
        />
      </Form>
      <Chart
        width={width}
        height={height}
        type="line"
        options={{
          chart: {
            id: 'basic-bar',
          },
          xaxis: {
            categories: ChartData.categories,
          },
        }}
        series={ChartData.series}
      />
    </Container>
  );
};

export default StockByPond;
