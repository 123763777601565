import React from 'react';

import { Link } from 'react-router-dom';
import Menu from '../../components/Menu';
import logoAzul from '../../assets/logo_azul.png';
import { Container, Header, Content } from './styles';
import { useAuth } from '../../hooks/auth';
import { useUserCompanies } from '../../hooks/userCompanies';
import { useCicle } from '../../hooks/useCicle';

/** Remover */

// interface FormData {
//   search: string;
// }

const Dashboard: React.FC = ({ children }) => {
  const { user } = useAuth();
  const { selectedCompany } = useUserCompanies();
  const { selectedCicle } = useCicle();

  return (
    <Container>
      <Menu />
      <section>
        <Header>
          <div />
          <div>
            <Link to="/company-selection">{selectedCompany?.name}</Link>
          </div>
          <div>
            <Link to="/cicle-selection">{selectedCicle?.description}</Link>
          </div>
          <div>
            <img src={user.avatar_url || logoAzul} alt="Imagem de perfil" />
            <p>{user.nameFormatted}</p>
          </div>
        </Header>
        <Content>{children}</Content>
      </section>
    </Container>
  );
};

export default Dashboard;
