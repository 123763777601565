import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  min-width: 350px;

  form {
    flex: 1;
  }

  .farm-add-buttons-actions {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 150px);
    gap: 10px;
  }
`;
