import React, { createContext, useCallback, useState, useContext } from 'react';
import { AxiosError } from 'axios';
import { Redirect, useHistory } from 'react-router-dom';
import api from '../services/api';
import { useToast } from './toast';

export interface AxiosErrorResponse {
  status: 'error';
  message: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  avatar_url?: string;
  nameFormatted?: string;
  is_admin: boolean;
  is_consultant: boolean;
  is_client: boolean;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const history = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@FarmFish:token');
    const user = localStorage.getItem('@FarmFish:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      const userParsed = JSON.parse(user);

      const name = userParsed.name.toLowerCase().split(' ');

      userParsed.nameFormatted = `${name[0][0].toUpperCase()}${name[0].slice(
        1,
        name[0].length,
      )}`;

      return { token, user: userParsed };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({ email, password }) => {
      try {
        const response = await api
          .post<AuthState>('sessions', {
            email,
            password,
          })
          .then(res => res)
          .catch((err: AxiosError) => {
            if (!!err.isAxiosError && !err.message) {
              addToast({
                type: 'error',
                title: 'Erro na comunicação',
                description:
                  'Não está sendo possível conectar aos servidores, cheque sua conexão ou tente mais tarde.',
              });
            }
            if ([400, 401].includes(err.response?.status || 0)) {
              addToast({
                type: 'error',
                title: 'Erro na autenticação',
                description: err.response?.data
                  ? (err.response?.data as AxiosErrorResponse).message
                  : 'Houve um erro na autenticação, tente novamente.',
              });
            }
          });

        if (!response) return;

        const { token, user } = response.data;

        api.defaults.headers.authorization = `Bearer ${token}`;

        localStorage.setItem('@FarmFish:token', token);
        localStorage.setItem('@FarmFish:user', JSON.stringify(user));

        const name = user.name.toLowerCase().split(' ');

        user.nameFormatted = `${name[0][0].toUpperCase()}${name[0].slice(
          1,
          name[0].length,
        )}`;

        setData({ token, user });

        history.push('/');
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Inconsistência no sistema',
          description:
            'Houve uma inconsistência inesperada no sistema. O serviço já foi notificado, desculpe pelo desconforto.',
        });
      }
    },
    [addToast, history],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@FarmFish:token');
    localStorage.removeItem('@FarmFish:user');

    localStorage.clear();

    api.defaults.headers.authorization = null;

    setData({} as AuthState);

    return <Redirect to="/" />;
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
