import React, { useState, useEffect } from 'react';
import { BsGraphDown } from 'react-icons/bs';
import { MdAttachMoney } from 'react-icons/md';
import { GiFishingNet } from 'react-icons/gi';
import { GoGraph } from 'react-icons/go';
import { useParams } from 'react-router-dom';
import { BiTransfer } from 'react-icons/bi';
import { Container, List, ListItem, ListText } from './styles';
import Loading from '../../../components/Helpers/Information/Loading';
import Error from '../../../components/Helpers/Information/Error';
import { IFarm } from '../../../types';
import api from '../../../services/api';
import { formatCurrency } from '../../../utils/format';
import { useCicle } from '../../../hooks/useCicle';

interface RouteParams {
  id: string;
}

interface IFarmShow extends IFarm {
  pondsCount: number;
  balance: number;
  balanceFormatted: string;
  pctBalance: number;
}

const FarmShow: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const { selectedCicle } = useCicle();
  const [farm, setFarm] = useState<IFarmShow | undefined>();

  useEffect(() => {
    async function loadFarm(farm_id: string) {
      try {
        setError(false);

        const { data } = await api.get<IFarm>(`/farms/${farm_id}`, {
          headers: { ...api.defaults.headers },
        });

        const { ponds, shoppings } = data;

        const totalFromPonds = ponds
          // .filter(pond => pond.cicle_id === selectedCicle?.id)
          .map(pond => {
            const amount_quantity =
              pond.populates
                ?.filter(populate => populate.cicle_id === selectedCicle?.id)
                .map(populate => populate.quantity)
                .reduce((prev, curr) => {
                  return prev + curr;
                }, 0) || 0;

            const amount_mortality =
              pond.mortalities
                ?.filter(mortality => mortality.cicle_id === selectedCicle?.id)
                .map(mortality => mortality.quantity)
                .reduce((prev, curr) => {
                  return prev + curr;
                }, 0) || 0;

            return {
              capacity: pond.cicle_id === selectedCicle?.id ? pond.capacity : 0,
              amount_quantity,
              amount_mortality,
            };
          })
          .reduce(
            (prev, curr) => {
              return {
                total_capacity: prev.total_capacity + curr.capacity,
                total_amount_quantity:
                  prev.total_amount_quantity + curr.amount_quantity,
                total_amount_mortality:
                  prev.total_amount_mortality + curr.amount_mortality,
              };
            },
            {
              total_capacity: 0,
              total_amount_quantity: 0,
              total_amount_mortality: 0,
            },
          );

        const pct_mortality =
          (totalFromPonds.total_amount_mortality * 100) /
          totalFromPonds.total_amount_quantity;

        const totalsShopping = shoppings
          .filter(shopping => shopping.cicle_id === selectedCicle?.id)
          .reduce(
            (prev, curr) => {
              if (curr.type === 'receive') {
                return {
                  total_receive: prev.total_receive + curr.amount,
                  total_payment: prev.total_payment,
                };
              }

              return {
                total_payment: prev.total_payment + curr.amount,
                total_receive: prev.total_receive,
              };
            },
            {
              total_receive: 0,
              total_payment: 0,
            },
          );

        const balance =
          totalsShopping.total_receive - totalsShopping.total_payment;

        const calcPctBalance = (bigger: number, smaller: number) => {
          return Number((100 - (smaller * 100) / bigger).toFixed(2));
        };

        let pctBalance = 0;

        if (totalsShopping.total_payment > totalsShopping.total_receive) {
          pctBalance =
            calcPctBalance(
              totalsShopping.total_payment,
              totalsShopping.total_receive,
            ) * -1;
        } else {
          pctBalance = calcPctBalance(
            totalsShopping.total_receive,
            totalsShopping.total_payment,
          );
        }

        // const pondsFiltered = data.ponds.filter(
        //   pond => pond.cicle_id === selectedCicle?.id,
        // );

        const costsOfTheCicle = data.costs.filter(
          stock => stock.cicle_id === selectedCicle?.id,
        );

        const mean_cost = Math.floor(
          costsOfTheCicle.reduce((prev, curr) => {
            return prev + curr.cost;
          }, 0) / costsOfTheCicle.length,
        );
        const farmFormatted: IFarmShow = {
          ...data,
          ponds,
          mean_cost,
          meanCostFormatted: formatCurrency(mean_cost),
          balance,
          balanceFormatted: formatCurrency(balance),
          pctBalance: Number(pctBalance.toFixed(2)) || 0,
          pondsCount: ponds.length,
          pct_mortality: Number(pct_mortality.toFixed(2)) || 0,
          total_mortality: totalFromPonds.total_amount_mortality,
          total_fish: totalFromPonds.total_amount_quantity,
        };

        setFarm(farmFormatted);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      loadFarm(id);
    }
  }, [id, selectedCicle]);

  if (error) {
    return <Error />;
  }

  if (loading || !farm) {
    return <Loading />;
  }

  return (
    <Container>
      <header>
        <h1>{farm.name}</h1>
      </header>
      <List>
        <ListItem to={`/farms/${id}/fishtanks`}>
          <ListText>
            <p>Total de viveiros</p>
            <div>
              <strong>{farm.pondsCount}</strong>
            </div>
            {/* <div>
              <span>{`${farm.pct_populate} %`}</span>
              <p>provoado</p>
            </div> */}
          </ListText>
          <GiFishingNet size={50} color="#2DCE98" />
        </ListItem>
        <ListItem to="">
          <ListText isNegative>
            <p>Mortalidade geral</p>
            <div>
              <strong>{farm.total_mortality || 0}</strong>
            </div>
            <div>
              <span>{`${farm.pct_mortality || 0}%`}</span>
              {/* <p>desde o último mês</p> */}
            </div>
          </ListText>
          <BsGraphDown size={50} color="#F53C56" />
        </ListItem>
        <ListItem to="">
          <ListText isNegative={farm.pctBalance <= 0}>
            <p>Receita</p>
            <div>
              <strong>{`${farm.balanceFormatted}`}</strong>
            </div>
            <div>
              <span>
                {`${farm.pctBalance > 0 ? '+' : ''}${farm.pctBalance}%`}
              </span>
            </div>
          </ListText>
          <GoGraph size={50} color="#11CDEF" />
        </ListItem>
        <ListItem to={`/farms/${farm.id}/costs`}>
          <ListText>
            <p>Custo médio Kg</p>
            <div>
              <strong>{farm.meanCostFormatted}</strong>
            </div>
          </ListText>
          <MdAttachMoney size={50} color="#7764E4" />
        </ListItem>
        <ListItem to={`/farms/${farm.id}/transferences`}>
          <ListText>
            <p>Transferências</p>
          </ListText>
          <BiTransfer size={50} color="#7764E4" />
        </ListItem>
      </List>
    </Container>
  );
};

export default FarmShow;
