import styled from 'styled-components';

export const Container = styled.div`
  margin: auto;
  margin-top: 40px;
  padding: 0 20px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-self: center;
  max-width: 700px;
`;

export const ListCompanies = styled.ul`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, 320px);
  gap: 20px;
  text-decoration: none;
  margin-top: 20px;
`;

export const CompanieCard = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-align: left;
  background: #fff;
  color: ${props => props.theme.main};

  img {
    width: 100px;
    margin-right: 30px;
  }

  div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    strong {
      line-height: 40px;
      width: 100%;
    }
  }

  &:hover {
    box-shadow: 0 2px 6px rgba(255, 255, 255, 0.12),
      0 2px 4px rgba(255, 255, 255, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
`;
