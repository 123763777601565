import styled from 'styled-components';

export const Container = styled.div`
  button {
    border: 0;
    margin: 0;
    background: none;
  }
`;

export const List = styled.ul`
  font-size: 14px;
  display: flex;
  flex-direction: column;

  a {
    padding: 10px 11px;
    color: #172b4d;
  }

  & + a {
    margin-top: 8px;
  }

  a:hover {
    background: ${props => props.theme.secondary};
  }
`;
