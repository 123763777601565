import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  colorType: 'normal' | 'cancel';
}

export const Container = styled.button<ContainerProps>`
  background: ${props => props.theme.buttons.secondary};
  border-radius: 10px;
  border: 0;
  height: 56px;
  padding: 0px 16px;
  width: 100%;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;

  ${props =>
    props.colorType === 'normal' &&
    css`
      color: ${props.theme.buttons.primary};

      &:hover {
        background: ${shade(0.2, '#5BB77F')};
      }
    `}

  ${props =>
    props.colorType === 'cancel' &&
    css`
      background: ${props.theme.input.iconErrorColor};

      &:hover {
        background: ${shade(0.2, props.theme.input.iconErrorColor)};
      }
    `}

  ${props =>
    props.disabled &&
    css`
      background: #333;
      color: #999;
      cursor: not-allowed;

      &:hover {
        background: #333;
      }
    `}
`;
