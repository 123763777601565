import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

interface OwnProps {
  colorType?: 'normal' | 'cancel';
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps & OwnProps> = ({
  children,
  isLoading = false,
  colorType = 'normal',
  ...props
}) => {
  return (
    <Container type="button" colorType={colorType} {...props}>
      {isLoading ? 'Processando..' : children}
    </Container>
  );
};

export default Button;
