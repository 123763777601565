import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TInput = styled(TextField).attrs({})`
  background: #fff;
  border-radius: 4px 4px 0px 0px;
`;
