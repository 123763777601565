import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import _ from 'lodash';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { DatePicker, InputNumber, Select } from '../../../../components/Form';
import Button from '../../../../components/Button';
import getValidationErrors from '../../../../utils/getValidationErrors';
import {
  ISpecie,
  Options,
  ITransferences,
  IPond,
  IFarm,
} from '../../../../types';
import api from '../../../../services/api';
import Loading from '../../../../components/Helpers/Information/Loading';
import { useToast } from '../../../../hooks/toast';
import Error from '../../../../components/Helpers/Information/Error';
import { Container } from './styles';
import { errorHandler } from '../../../../utils/errorHandler';

interface NewTransferenceData {
  specie_id: string;
  pond_id_origin: string;
  pond_id_destiny: string;
  quantity: number;
  mean_weight: number;
  biomass: number;
  biometry_id: number;
}

interface OwnProps {
  onAddTransference(transference: ITransferences): void;
  farm_id: string;
  onCancel(): void;
}

const NewTrasnference: React.FC<OwnProps> = ({
  onAddTransference,
  farm_id,
  onCancel,
}) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [species, setSpecies] = useState<Options[]>([]);
  const [ponds, setPonds] = useState<IPond[]>([]);
  const [selectedPondId, setSelectedPondId] = useState<string | undefined>();

  /** Controls of calculator */
  const [calculating, setCalculating] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [meanUnitFish, setMeanUnitFish] = useState(0);
  const [biomass, setBiomass] = useState(0);
  const [biometryId, setBiometryId] = useState<string | undefined>();

  useEffect(() => {
    async function loadSpecies() {
      try {
        setLoading(true);
        const { data } = await api.get<ISpecie[]>('species');

        setSpecies(
          data.map(specie => ({
            value: specie.id,
            label: specie.name,
          })),
        );
      } catch (err) {
        setError(true);
        addToast({
          title: 'Inconsistência no carregamento',
          description:
            'Houve uma inconsistência ao carregar as espécies. Tente novamente',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    async function loadFarm(id: string) {
      try {
        setLoading(true);
        const { data } = await api.get<IFarm>(`farms/${id}/ponds`);

        setPonds(data.ponds || []);
        if (data.ponds.length > 0) {
          setSelectedPondId(data.ponds[0].id);
        }
      } catch (err) {
        setError(true);
        addToast({
          title: 'Inconsistência no carregamento',
          description:
            'Houve uma inconsistência ao carregar os viveiros. Tente novamente',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    setError(false);
    loadSpecies();
    loadFarm(farm_id);
  }, [addToast, farm_id]);

  useEffect(() => {
    if (selectedPondId) {
      const orderedBiometries = _.orderBy(
        ponds.find(pond => pond.id === selectedPondId)?.biometries || [],
        ['date'],
        ['asc'],
      );

      const lastBiometry = orderedBiometries.pop();

      if (lastBiometry) {
        setMeanUnitFish(lastBiometry.weight);
      }

      setBiometryId(lastBiometry?.id);
    }
  }, [ponds, selectedPondId]);

  const handleOnSubmit = useCallback(
    async (data: NewTransferenceData) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          specie_id: Yup.string().required('O campo nome é obrigatório'),
          date: Yup.date().required('O campo é obrigatório'),
          pond_id_origin: Yup.string(),
          pond_id_destiny: Yup.string(),
          quantity: Yup.number()
            .positive('A quantidade tem que ser maior que zero.')
            .required('Campo obrigatório'),
          mean_weight: Yup.number()
            .positive(
              'A quantidade tem que ser maior que zero. Possivelmente não há biometrias cadastradas para a espécie no viveiro de origem.',
            )
            .required('Campo obrigatório'),
          biomass: Yup.number()
            .positive('A quantidade tem que ser maior que zero.')
            .equals(
              [Number((quantity * meanUnitFish).toFixed(0)) / 1000],
              'O valor da biomassa não confere.',
            )
            .required('Campo obrigatório'),
        });

        await schema.validate(
          { ...data, biometry_id: biometryId },
          {
            abortEarly: false,
          },
        );

        const { mean_weight, ...finalData } = data;

        const response = await api.post<ITransferences>(
          '/transferences',
          { ...finalData, biometry_id: biometryId },
          {
            params: api.defaults.params,
          },
        );

        onAddTransference(response.data);
        onCancel();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        } else if ((err as AxiosError).isAxiosError) {
          const { description } = errorHandler(err);
          addToast({
            title: 'Não foi possível realizar a transferência.',
            description,
            type: 'error',
          });
        }
      }
    },
    [addToast, biometryId, meanUnitFish, onAddTransference, onCancel, quantity],
  );

  function handleChangeQuantity(value: number) {
    if (calculating || value === 0) return;

    try {
      setCalculating(true);
      setBiomass((meanUnitFish * value) / 1000);
      setQuantity(value);
    } finally {
      setCalculating(false);
    }
  }

  function handleChangeBiomass(value: number) {
    if (calculating || value === 0) return;

    try {
      setCalculating(true);

      setQuantity(Math.round((value * 1000) / meanUnitFish));

      setBiomass(value);
    } finally {
      setCalculating(false);
    }
  }

  function handleChangeMeanWeightFish() {
    setBiomass((meanUnitFish * quantity) / 1000);
  }

  const destinyPonds = useMemo(() => {
    return ponds
      .filter(pond => pond.id !== selectedPondId)
      .map(pond => {
        return {
          value: pond.id,
          label: pond.name,
        };
      });
  }, [ponds, selectedPondId]);

  if (error) {
    return (
      <Error message="Um dado necessário não pode ser carregado. Tente novamente" />
    );
  }

  if (loading || !species || !ponds) {
    return <Loading message="Carregando dados necessários" />;
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <Select name="specie_id" label="Espécie" options={species} required />
        <DatePicker name="date" required />
        <Select
          name="pond_id_origin"
          label="Viveiro de origem"
          options={ponds.map(pond => ({
            value: pond.id,
            label: pond.name,
          }))}
          onValueChange={event => setSelectedPondId(event as string)}
          required
        />

        <Select
          name="pond_id_destiny"
          label="Viveiro de destino"
          options={destinyPonds}
          required
        />

        <InputNumber
          value={quantity}
          name="quantity"
          onChange={e => handleChangeQuantity(Number(e.target.value))}
          inputProps={{
            suffix: '',
            prefix: '',
          }}
          label="Unidades"
        />
        <InputNumber
          value={meanUnitFish}
          disabled
          name="mean_weight"
          label="Média em gramas por unidade"
          onChange={() => handleChangeMeanWeightFish()}
          inputProps={{
            suffix: '',
            prefix: '',
          }}
        />
        <InputNumber
          value={biomass}
          name="biomass"
          label="Biomassa (Kg)"
          onChange={e => handleChangeBiomass(Number(e.target.value))}
          inputProps={{
            suffix: '',
            prefix: '',
          }}
        />

        <div className="farm-add-buttons-actions">
          <Button type="submit">Adicionar</Button>
          <Button colorType="cancel" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewTrasnference;
