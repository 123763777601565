import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AiOutlineStock } from 'react-icons/ai';
import { Button, Typography } from '@material-ui/core';
import { AxiosError } from 'axios';
import BaseTableDataManager from '../../../components/BaseTableDataManager';
import { IFarm, ICost } from '../../../types';
import Error from '../../../components/Helpers/Information/Error';
import Loading from '../../../components/Helpers/Information/Loading';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import DialogScroll from '../../../components/DialogScroll';
import { convertGramsTokilogram, formatCurrency } from '../../../utils/format';
import { Container, Recalcular } from './styles';
import { errorHandler } from '../../../utils/errorHandler';
import { useCicle } from '../../../hooks/useCicle';

interface RouteParams {
  id: string;
}

const FarmCost: React.FC = () => {
  const { addToast } = useToast();
  const { id } = useParams<RouteParams>();
  const { selectedCicle } = useCicle();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRecalcing, setIsRecalcing] = useState(false);
  const [farm, setFarm] = useState<IFarm | undefined>();

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  useEffect(() => {
    async function loadFarmCost(farm_id: string, cicle_id: string) {
      try {
        setError(false);
        const { data } = await api.get<IFarm>(`farms/${farm_id}/costs`, {
          params: api.defaults.params,
        });

        const { costs } = data;

        const costsFormatted = costs
          .filter(cost => cost.cicle_id === cicle_id)
          .map(cost => {
            return {
              ...cost,
              amountPayedFormatted: formatCurrency(cost.amount_payed),
              costFormatted: formatCurrency(cost.cost),
              fishWeightFormatted: convertGramsTokilogram(cost.fish_weight),
              weightDeadFormatted: convertGramsTokilogram(cost.weight_dead),
              weightProducedFormatted: convertGramsTokilogram(
                cost.weight_produced,
              ),
              weightSoldFormatted: convertGramsTokilogram(cost.weight_sold),
            };
          });

        setFarm({
          ...data,
          costs: costsFormatted,
        });
      } catch (err) {
        addToast({
          title: 'Inconsistência no loading',
          description: 'Não foi possível carregar os custos, tente mais tarde.',
          type: 'error',
        });
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    if (id && selectedCicle?.id) {
      loadFarmCost(id, selectedCicle.id);
    }
  }, [id, addToast, selectedCicle]);

  async function handleOnRecalcStock() {
    try {
      setIsRecalcing(true);
      setOpen(false);
      const { data } = await api.post<ICost[]>(`/farms/${farm?.id}/recalccost`);

      const costsFormatted = data
        .filter(cost => cost.cicle_id === selectedCicle?.id)
        .map(cost => {
          return {
            ...cost,
            amountPayedFormatted: formatCurrency(cost.amount_payed),
            costFormatted: formatCurrency(cost.cost),
            fishWeightFormatted: convertGramsTokilogram(cost.fish_weight),
            weightDeadFormatted: convertGramsTokilogram(cost.weight_dead),
            weightProducedFormatted: convertGramsTokilogram(
              cost.weight_produced,
            ),
            weightSoldFormatted: convertGramsTokilogram(cost.weight_sold),
          };
        });

      setFarm(current => {
        if (!current) return undefined;

        return {
          ...current,
          costs: costsFormatted,
        };
      });
    } catch (err) {
      if ((err as AxiosError).isAxiosError) {
        const { description } = errorHandler(err);

        addToast({
          title: 'Não foi possível recalcular o custo',
          description,
          type: 'error',
        });
      }
    } finally {
      setIsRecalcing(false);
    }
  }

  function handleOnCancelRecalcCost() {
    setOpen(false);
  }

  if (error) {
    return <Error />;
  }

  if (loading || !farm) {
    return <Loading />;
  }

  if (isRecalcing) {
    return <Loading message="Recalculando custos" />;
  }

  return (
    <Container>
      <header>
        <Link to={`/farms/${farm.id}/show`}>
          <h1>{farm.name}</h1>
        </Link>
        <h1>{`>`}</h1>
        <h1>Custos por espécie</h1>
      </header>
      <BaseTableDataManager<ICost>
        isLoading={loading}
        options={{
          grouping: true,
          pageSizeOptions: [5, 20, 50, farm.costs.length],
        }}
        actions={[
          {
            icon: AiOutlineStock,
            onClick: () => setOpen(true),
            isFreeAction: true,
            tooltip: 'Recalcular custos',
          },
        ]}
        title="Custos"
        columns={[
          {
            title: 'Espécie',
            field: 'specie.name',
          },
          {
            title: 'Ciclo',
            field: 'cicle.description',
          },
          {
            title: 'Estoque atual (KG)',
            field: 'fish_weight',
            type: 'numeric',
            render: column => {
              return column.fishWeightFormatted;
            },
          },
          {
            title: 'Total gasto',
            field: 'amount_payed',
            type: 'numeric',
            render: column => {
              return column.amountPayedFormatted;
            },
          },
          {
            title: 'Custo atual',
            field: 'cost',
            type: 'numeric',
            grouping: false,
            render: column => {
              return column.costFormatted;
            },
          },
          {
            title: 'Total morto/perdido (Kg)',
            field: 'weight_dead',
            type: 'numeric',
            grouping: false,
            render: column => {
              return column.weightDeadFormatted;
            },
          },
          {
            title: 'Total produzido (Kg)',
            field: 'weight_produced',
            type: 'numeric',
            grouping: false,
            render: column => {
              return column.weightProducedFormatted;
            },
          },
          {
            title: 'Total vendido (Kg)',
            field: 'weight_sold',
            type: 'numeric',
            grouping: false,
            render: column => {
              return column.weightSoldFormatted;
            },
          },

          { title: 'Cód.', field: 'id', filtering: false, grouping: false },
        ]}
        data={farm.costs}
      />

      {open && (
        <DialogScroll
          open={open}
          dialogTitle=""
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <Recalcular>
            <Typography variant="h6">
              Deseja mesmo recalcular os custos da fazenda?
            </Typography>

            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={handleOnRecalcStock}
              >
                Sim
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleOnCancelRecalcCost}
              >
                Não
              </Button>
            </div>
          </Recalcular>
        </DialogScroll>
      )}
    </Container>
  );
};

export default FarmCost;
