import React, { useEffect, useRef, useCallback, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextFieldProps, IconButton } from '@material-ui/core';
import { useField } from '@unform/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BiSearch } from 'react-icons/bi';
import { Container, TInput as TextInput } from './styles';
import DialogScroll from '../../DialogScroll';
import BaseTableDataManager from '../../BaseTableDataManager';
import { IShopping } from '../../../types';
import { formatCurrency } from '../../../utils/format';

interface DefaultValue {
  label: string;
  value: string;
}

interface Props {
  name: string;
  label?: string;
  shoppings: IShopping[];
  shoppingType?: 'receive' | 'payment';
  onSelectShopping?(shopping: IShopping): void;
}

type InputProps = TextFieldProps & JSX.IntrinsicElements['input'] & Props;

export const ShoppingPicker: React.FC<InputProps> = ({
  name,
  label,
  shoppings,
  required = true,
  variant = 'outlined',
  shoppingType = 'receive',
  onSelectShopping,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [showedValue, setShowedValue] = useState<string | undefined>(
    defaultValue ? (defaultValue as DefaultValue).label : undefined,
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  const handleOnSelectItem = useCallback(
    (item: IShopping) => {
      setShowedValue(`${item.participantName} - ${item.amount}`);
      if (inputRef.current) {
        inputRef.current.value = item.id;
      }
      if (onSelectShopping) {
        onSelectShopping(item);
      }
      handleOnToogleDialog();
    },
    [handleOnToogleDialog, onSelectShopping],
  );

  return (
    <Container>
      <TextInput
        inputRef={inputRef}
        defaultValue={defaultValue && (defaultValue as DefaultValue).value}
        style={{ display: 'none' }}
        {...rest}
      />

      <TextInput
        required={required}
        label={label}
        error={!!error}
        helperText={error}
        value={showedValue || ''}
        fullWidth
        variant={variant}
        style={{ marginBottom: 10 }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <>
              <InputAdornment position="end">
                <IconButton onClick={handleOnToogleDialog}>
                  <BiSearch />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />

      <DialogScroll
        open={open}
        fullWidth
        maxWidth="lg"
        dialogTitle="Seleção de Compra"
        onClose={handleOnToogleDialog}
        onClickActionCancelButton={handleOnToogleDialog}
        dialogActions={<div />}
      >
        <BaseTableDataManager<IShopping>
          options={{
            pageSizeOptions: [5, 20, 50, shoppings.length],
            selection: false,
            filtering: true,
            exportButton: false,
            grouping: false,
          }}
          title=""
          columns={[
            {
              title: `${shoppingType === 'receive' ? 'Cliente' : 'Fornecedor'}`,
              field: 'participantName',
            },
            {
              title: 'CPF/CNPJ',
              field: 'cpfcnpj',
            },
            {
              title: 'Valor',
              field: 'amount',
              type: 'numeric',
              render: (column: IShopping) => {
                return formatCurrency(column.amount);
              },
            },
            {
              title: 'Data',
              field: 'date',
              type: 'date',
              render: (column: IShopping) => {
                return column.dateFormatted;
              },
            },
            { title: 'Compra', field: 'id' },
          ]}
          actions={[
            {
              icon: 'check',
              tooltip: 'Selecionar',
              isFreeAction: false,
              onClick: (_, data) => handleOnSelectItem(data as IShopping),
            },
          ]}
          data={shoppings}
          detailPanel={(rowData: IShopping) => {
            return (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Produto</TableCell>
                      <TableCell>Espécie</TableCell>
                      <TableCell align="right">Qtd.</TableCell>
                      <TableCell align="right">Peso médio</TableCell>
                      <TableCell align="right">Biomassa</TableCell>
                      <TableCell align="right">Saldo</TableCell>
                      <TableCell align="right">Valor Unitário</TableCell>
                      <TableCell align="right">Desconto</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowData.itens?.map(item => (
                      <TableRow key={item.id}>
                        <TableCell component="th" scope="row">
                          {item.product?.name}
                        </TableCell>
                        <TableCell align="right">
                          {item.product?.specie?.name}
                        </TableCell>
                        <TableCell align="right">{item.quantity}</TableCell>
                        <TableCell align="right">
                          {item.mean_unit_fish}
                        </TableCell>
                        <TableCell align="right">{item.biomass}</TableCell>
                        <TableCell align="right">{item.balance}</TableCell>
                        <TableCell align="right">{item.unit_price}</TableCell>
                        <TableCell align="right">{item.discount}</TableCell>
                        <TableCell align="right">{item.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            );
          }}
        />
      </DialogScroll>
    </Container>
  );
};
