import React, { useCallback } from 'react';
import { MdMoreVert } from 'react-icons/md';
import Popover from '@material-ui/core/Popover';
import { useHistory } from 'react-router-dom';
import { ListItem } from '@material-ui/core';
import { Container, List } from './styles';

interface PopoverFishTankMenuProps {
  idTank: string;
}

const PopoverFishTankMenu: React.FC<PopoverFishTankMenuProps> = ({
  idTank,
}) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRedirect = useCallback(
    (path: string) => {
      history.replace(path);
    },
    [history],
  );

  const open = Boolean(anchorEl);

  return (
    <Container>
      <button type="button" onClick={handleClick}>
        <MdMoreVert size={22} color="#000000" />
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <List>
          <ListItem
            button
            onClick={() => handleRedirect(`/fishtanks/${idTank}/populate`)}
          >
            Povoamentos
          </ListItem>
          <ListItem
            button
            onClick={() => handleRedirect(`/fishtanks/${idTank}/biometry`)}
          >
            Biometrias
          </ListItem>
          <ListItem
            button
            onClick={() => handleRedirect(`/fishtanks/${idTank}/mortality`)}
          >
            Mortalidades
          </ListItem>
        </List>
      </Popover>
    </Container>
  );
};

export default PopoverFishTankMenu;
