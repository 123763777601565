import React, { useState, useEffect, useCallback } from 'react';
import BaseTableDataManager from '../../../components/BaseTableDataManager';
import { ISpecie } from '../../../types';
import Error from '../../../components/Helpers/Information/Error';
import Loading from '../../../components/Helpers/Information/Loading';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { Container } from './styles';
import { useAuth } from '../../../hooks/auth';
import { DialogScroll } from '../../../components/DialogScroll';
import NewSpecie from './NewSpecie';

const Species: React.FC = () => {
  const { addToast } = useToast();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [species, setSpecies] = useState<ISpecie[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    async function loadSpecies() {
      try {
        setError(false);
        const { data } = await api.get<ISpecie[]>(`species`);

        setSpecies(data);
      } catch (err) {
        addToast({
          title: 'Inconsistência no loading',
          description:
            'Não foi possível carregar as espécies, tente mais tarde.',
          type: 'error',
        });
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadSpecies();
  }, [addToast]);

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  const handleOnAddSpecie = useCallback((specie: ISpecie) => {
    setSpecies(current => [...current, specie]);
  }, []);

  if (error) {
    return <Error />;
  }

  if (loading || !species) {
    return <Loading />;
  }

  return (
    <Container>
      <BaseTableDataManager<ISpecie>
        isLoading={loading}
        options={{
          grouping: true,
          pageSizeOptions: [5, 20, 50, species.length],
          selection: false,
        }}
        title="Espécies"
        onAdd={user.is_admin ? handleOnToogleDialog : undefined}
        columns={[
          {
            title: 'Nome',
            field: 'name',
          },

          { title: 'Cód.', field: 'id', grouping: false },
        ]}
        data={species}
      />
      {open && (
        <DialogScroll
          open={open}
          dialogTitle="Nova espécie"
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <NewSpecie
            onAddSpecie={handleOnAddSpecie}
            onCancel={handleOnToogleDialog}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default Species;
