import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import logo from '../../assets/logo_original.png';
import { Input } from '../../components/Form';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import { useAuth } from '../../hooks/auth';
import { Container, Content, AnimationContainer, Background } from './styles';

interface SignInData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();

  const handleOnSubmit = useCallback(
    async (data: SignInData) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('O email é inválido')
            .required('O campo email é obrigatório'),
          password: Yup.string()
            .min(6, 'Sua senha deve ter no mínimo 6 caracteres')
            .required('O campo senha é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        }
      }
    },
    [signIn],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logo} alt="FarmFish" width="200" />
          <Form ref={formRef} onSubmit={handleOnSubmit}>
            <h1>Acesse o sistema</h1>

            <Input name="email" placeholder="E-mail" label="E-mail" autoFocus />
            <Input
              name="password"
              type="password"
              label="Senha"
              placeholder="Senha"
            />
            <Button type="submit">Entrar</Button>
            <a href="forgot">Esqueci minha senha</a>
          </Form>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
