import React from 'react';
import { StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from 'styled-components';
import ptLocale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import theme from '../styles/theme';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { UserCompanyProvider } from './userCompanies';
import { UserCicleProvider } from './useCicle';

const AppProvider: React.FC = ({ children }) => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
          <ToastProvider>
            <AuthProvider>
              <UserCompanyProvider>
                <UserCicleProvider>{children}</UserCicleProvider>
              </UserCompanyProvider>
            </AuthProvider>
          </ToastProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default AppProvider;
