import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

export const Container = styled.div`
  width: 245px;
  height: 100vh;
  background: ${props => props.theme.main};

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px 10px 20px;

    img {
      height: 80px;
    }

    svg {
      color: ${props => props.theme.main};
      transform: rotate(-90deg);
    }
  }
`;

export const List = styled.ul`
  margin: 10px 0px;
`;

interface ListItemProps {
  isPathSelected: boolean;
}

export const ListItem = styled(Link)<ListItemProps>`
  text-decoration: none;
  padding: 5px 22px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 11px;
  }

  p {
    font-size: 16px;
    color: ${props => props.theme.rightMenu.textColor};
  }

  &:hover {
    background: #d9ebdd;
  }

  & + li {
    margin-bottom: 10px;
  }

  ${props =>
    props.isPathSelected &&
    css`
      background: ${lighten(0.7, '#102D45')};

      p {
        color: #102d45;
      }
    `}
`;
