/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FaBoxes, FaFish } from 'react-icons/fa';
import { GiWeight } from 'react-icons/gi';
import { AxiosError } from 'axios';
import Button from '../../../../components/Button';
import getValidationErrors from '../../../../utils/getValidationErrors';
import api from '../../../../services/api';
import { Container, StockContainer } from './styles';
import { convertGramsTokilogram } from '../../../../utils/format';
import { IPond } from '../../../../types';
import { errorHandler } from '../../../../utils/errorHandler';
import { useToast } from '../../../../hooks/toast';
import { DatePicker, Input } from '../../../../components/Form';

interface NewDespescaData {
  date: Date;
  observation: string;
}

interface OwnProps {
  onSubmitDespesca(pond_id: string): void;
  pond: IPond;
  onCancel(): void;
}

const NewDespesca: React.FC<OwnProps> = ({
  onSubmitDespesca,
  pond,
  onCancel,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [stocksWitchIsLoss, setStocksWitchIsLoss] = useState<string[]>([]);

  const handleOnToggleStock = useCallback(async (id: string) => {
    setStocksWitchIsLoss(current => {
      const stockIndex = current.findIndex(item => item === id);

      if (stockIndex >= 0) {
        return current.filter(item => item !== id);
      }

      return [...current, id];
    });
  }, []);

  const handleOnSendDespesca = useCallback(
    async (data: NewDespescaData) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);

        const schema = Yup.object().shape({
          date: Yup.date().required('A data da despesca é obrigatória.'),
          observation: Yup.string(),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        const formData = Object.assign(result, { stocksWitchIsLoss });

        await api.post(`pond/${pond.id}/clean`, formData, {
          params: api.defaults.params,
        });
        onSubmitDespesca(pond.id);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        } else if ((err as AxiosError).isAxiosError) {
          const { description } = errorHandler(err);

          addToast({
            title: 'Não foi possível realizar a despesca',
            description,
            type: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, onSubmitDespesca, pond.id, stocksWitchIsLoss],
  );

  return (
    <Container>
      {pond.stocks?.map(stock => {
        return (
          <StockContainer>
            <div>
              <Tooltip title="Espécie">
                <IconButton aria-label="Peixes">
                  <FaFish size={20} color="#7764E4" />
                </IconButton>
              </Tooltip>
              <Typography>{stock.specie?.name}</Typography>
            </div>
            <div>
              <Tooltip title="Quantidade de peixes">
                <IconButton aria-label="Quantidade de peixes">
                  <FaBoxes size={20} color="#7764E4" />
                </IconButton>
              </Tooltip>
              <Typography>{`${stock.fish_count} un`}</Typography>
            </div>
            <div>
              <Tooltip title="Biomassa (Kg)">
                <IconButton aria-label="Biomassa (Kg)">
                  <GiWeight size={20} color="#7764E4" />
                </IconButton>
              </Tooltip>
              <Typography>
                {`${convertGramsTokilogram(stock.weight_approximate)} Kg`}
              </Typography>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stocksWitchIsLoss.includes(stock.id)}
                  onChange={() => handleOnToggleStock(stock.id)}
                  color="primary"
                />
              }
              label="Considerar como prejuízo?"
            />
          </StockContainer>
        );
      })}
      <Form ref={formRef} onSubmit={handleOnSendDespesca}>
        <DatePicker name="date" label="Data da despesca" />
        <Input
          name="observation"
          placeholder=""
          label="Observação"
          autoFocus
          multiline
          rows={8}
        />
        <Button type="submit" isLoading={loading}>
          Finalizar
        </Button>
        <Button
          type="button"
          colorType="cancel"
          onClick={onCancel}
          isLoading={loading}
        >
          Cancelar
        </Button>
      </Form>
    </Container>
  );
};

export default NewDespesca;
