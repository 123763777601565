import React, { useState, useEffect, useCallback } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { AxiosError } from 'axios';
import BaseTableDataManager from '../../../../components/BaseTableDataManager';
import { IShopping } from '../../../../types';
import Error from '../../../../components/Helpers/Information/Error';
import Loading from '../../../../components/Helpers/Information/Loading';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import DialogScroll from '../../../../components/DialogScroll';
import { useUserCompanies } from '../../../../hooks/userCompanies';
import {
  formatCurrency,
  getAndFormatIdentificator,
  getNameFromParticipant,
  formatDate,
  formatPaymentType,
} from '../../../../utils/format';
import { useCicle } from '../../../../hooks/useCicle';
import NewShoppingSell from './NewShoppingSell';
import { errorHandler } from '../../../../utils/errorHandler';
import { Container } from './styles';

const ShoppingBuy: React.FC = () => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { selectedCompany } = useUserCompanies();
  const { selectedCicle } = useCicle();
  const [shoppings, setShopping] = useState<IShopping[]>([]);
  const [open, setOpen] = useState(false);

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  useEffect(() => {
    async function loadShoppingsBuy(company_id: string, cicle_id: string) {
      try {
        setError(false);
        const { data } = await api.get<IShopping[]>(`shopping`, {
          params: {
            cicle_id,
            company_id,
            type: 'receive',
          },
        });

        const dataFormatted = data.map(shopping => {
          const cpfcnpj = getAndFormatIdentificator(shopping.participant);
          const participantName = getNameFromParticipant(shopping.participant);
          const dateFormatted = formatDate(new Date(shopping.date));
          const paymentTypeFormatted = formatPaymentType(shopping.payment_type);

          return {
            ...shopping,
            cpfcnpj,
            participantName,
            dateFormatted,
            paymentTypeFormatted,
          };
        });

        setShopping(dataFormatted);
      } catch (err) {
        addToast({
          title: 'Inconsistência no loading',
          description: 'Não foi possível carregar as vendas, tente mais tarde.',
          type: 'error',
        });
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    if (selectedCompany?.id && selectedCicle?.id) {
      loadShoppingsBuy(selectedCompany.id, selectedCicle.id);
    }
  }, [addToast, selectedCicle, selectedCompany]);

  const handleOnAddNewShopping = useCallback((shopping: IShopping) => {
    const cpfcnpj = getAndFormatIdentificator(shopping.participant);
    const participantName = getNameFromParticipant(shopping.participant);
    const dateFormatted = formatDate(new Date(shopping.date));
    const paymentTypeFormatted = formatPaymentType(shopping.payment_type);

    setShopping(current => [
      ...current,
      {
        ...shopping,
        cpfcnpj,
        participantName,
        dateFormatted,
        paymentTypeFormatted,
      },
    ]);
  }, []);

  const handleOnDeleteRow = useCallback(
    async (shopping: IShopping) => {
      setLoading(true);

      try {
        await api.delete(`shopping/${shopping.id}`, {
          params: api.defaults.params,
        });

        setShopping(current => current.filter(item => item.id !== shopping.id));

        addToast({
          title: 'Remoção concluída com sucesso',
          type: 'success',
        });
      } catch (err) {
        if ((err as AxiosError).isAxiosError) {
          const { description } = errorHandler(err);
          addToast({
            title: 'Remoção não foi possível',
            description,
            type: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  if (error) {
    return <Error />;
  }

  if (loading || !shoppings) {
    return <Loading />;
  }

  if (!selectedCompany) {
    return (
      <Error message="É necessário selecionar a empresa no escopo geral." />
    );
  }

  return (
    <Container>
      <BaseTableDataManager<IShopping>
        options={{
          selection: false,
          pageSizeOptions: [5, 20, 50, shoppings.length],
          filtering: true,
          grouping: true,
        }}
        title="Vendas"
        columns={[
          {
            title: 'Cliente',
            field: 'participantName',
          },
          {
            title: 'CPF/CNPJ',
            field: 'cpfcnpj',
          },
          {
            title: 'Valor',
            field: 'amount',
            type: 'numeric',
            render: (column: IShopping) => {
              return formatCurrency(column.amount);
            },
          },
          {
            title: 'Tipo de pagamento',
            field: 'paymentTypeFormatted',
          },
          {
            title: 'Data',
            field: 'date',
            type: 'date',
            render: (column: IShopping) => {
              return column.dateFormatted;
            },
          },
          { title: 'Compra', field: 'id' },
        ]}
        data={shoppings}
        onAdd={() => setOpen(true)}
        onDeleteMany={data => handleOnDeleteRow(data as IShopping)}
        detailPanel={(rowData: IShopping) => {
          return (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Produto</TableCell>
                    <TableCell align="right">Qtd.</TableCell>
                    <TableCell align="right">Valor Unitário</TableCell>
                    <TableCell align="right">Média g/Un</TableCell>
                    <TableCell align="right">Biomassa</TableCell>
                    <TableCell align="right">Desconto</TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell align="right">Espécie</TableCell>
                    <TableCell align="right">Viveiro</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowData.itens?.map(item => (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="row">
                        {item.product?.name}
                      </TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                      <TableCell align="right">{item.unit_price}</TableCell>
                      <TableCell align="right">{item.mean_unit_fish}</TableCell>
                      <TableCell align="right">{item.biomass}</TableCell>
                      <TableCell align="right">{item.discount}</TableCell>
                      <TableCell align="right">{item.amount}</TableCell>
                      <TableCell align="right">
                        {item.product?.specie?.name}
                      </TableCell>
                      <TableCell align="right">{item.pond?.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      />

      {open && (
        <DialogScroll
          open={open}
          fullWidth
          maxWidth="lg"
          dialogTitle="Nova venda"
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <NewShoppingSell
            company_id={selectedCompany.id}
            onAddShopping={handleOnAddNewShopping}
            onCancel={handleOnToogleDialog}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default ShoppingBuy;
