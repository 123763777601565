import React, { useEffect, useMemo, useRef, useState } from 'react';

// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import { BarCanvas } from '@nivo/bar';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useUserCompanies } from '../../../../hooks/userCompanies';
import { IFarm } from '../../../../types';
import { errorHandler } from '../../../../utils/errorHandler';
import api from '../../../../services/api';
import { useCicle } from '../../../../hooks/useCicle';
import { Select } from '../../../../components/Form';
import { Container } from './styles';

interface IBarData {
  farm: string | number;
  [key: string]: string | number;
}

interface OwnProps {
  height: number;
  width: number;
}

const MortalitiesBySpecieAndFarm: React.FC<OwnProps> = ({ height, width }) => {
  const formRef = useRef<FormHandles>(null);
  const { selectedCompany } = useUserCompanies();
  const { selectedCicle, cicles } = useCicle();
  const [actualCicle, setActualCicle] = useState(selectedCicle?.id);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IFarm[]>([]);
  const [chartKeys, setChartKeys] = useState<string[]>([]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const response = await api.get<IFarm[]>('reports/mortalitiesbyfarm', {
          params: api.defaults.params,
        });

        setData(response.data);

        // const arrayOfData: BarData[] = [];
        // const keysChartSpecie: string[] = [];

        // response.data.forEach(farm => {
        //   const species: { [key: string]: number } = {};

        //   farm.ponds.forEach(pond => {
        //     pond.mortalities.forEach(mortality => {
        //       if (
        //         !!mortality.specie?.name &&
        //         !keysChartSpecie.includes(mortality.specie.name)
        //       ) {
        //         keysChartSpecie.push(mortality.specie?.name);
        //       }

        //       species[mortality.specie?.name || 'Não informada'] =
        //         species[mortality.specie?.name || 'Não informada'] ||
        //         0 + mortality.quantity;
        //     });
        //   });

        //   arrayOfData.push({ farm: farm.name, ...species });
        // });
        // setChartKeys(keysChartSpecie);
        // setData(arrayOfData);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }

    if (selectedCompany) {
      loadData();
    }
  }, [selectedCompany]);

  const cicleOptions = useMemo(() => {
    return cicles
      .sort((a, b) => a.sequence - b.sequence)
      .map(cicle => ({
        label: cicle.description,
        value: cicle.id,
      }));
  }, [cicles]);

  const BarData = useMemo(() => {
    const arrayOfData: IBarData[] = [];
    const keysChartSpecie: string[] = [];

    data.forEach(farm => {
      const species: { [key: string]: number } = {};

      farm.ponds.forEach(pond => {
        pond.mortalities
          ?.filter(mortality => mortality.cicle_id === actualCicle)
          .forEach(mortality => {
            if (
              !!mortality.specie?.name &&
              !keysChartSpecie.includes(mortality.specie.name)
            ) {
              keysChartSpecie.push(mortality.specie?.name);
            }

            species[mortality.specie?.name || 'Não informada'] =
              species[mortality.specie?.name || 'Não informada'] ||
              0 + mortality.quantity;
          });
      });

      arrayOfData.push({ farm: farm.name, ...species });
    });
    setChartKeys(keysChartSpecie);
    return arrayOfData;
  }, [actualCicle, data]);

  if (!selectedCompany) {
    return <h1>Selecione uma empresa primeiramente</h1>;
  }

  if (loading) {
    return <h1>Carregando informações...</h1>;
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={() => {}}>
        <Select
          required
          name="cicle_id"
          label="Ciclo"
          options={cicleOptions || []}
          value={actualCicle}
          onChange={event => setActualCicle(event.target.value as string)}
        />
      </Form>
      <BarCanvas
        width={width}
        height={height}
        data={BarData}
        layout="vertical"
        keys={chartKeys}
        indexBy="farm"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        enableGridX
        groupMode="grouped"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Fazenda',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Unidades',
          legendPosition: 'middle',
          legendOffset: -50,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'top-right',
            direction: 'row',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </Container>
  );
};

export default MortalitiesBySpecieAndFarm;
