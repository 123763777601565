/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react';

import FilterList from '@material-ui/icons/FilterList';
import MaterialTable, {
  Options,
  MaterialTableProps,
  Action,
} from 'material-table';

interface OwnProps<RowData extends object> {
  onRefresh?(): void;
  onShowCustomFilter?: () => void;
  onAdd?: (event: any, data: RowData | RowData[]) => void;
  onDeleteMany?: (data: RowData | RowData[]) => void;
  fileNameExport?: string;
  options?: Options<RowData>;
}

export default function BaseTableDataManager<RowData extends object>({
  onRefresh,
  onShowCustomFilter,
  onAdd,
  onDeleteMany,
  fileNameExport,
  actions,
  ...rest
}: OwnProps<RowData> & MaterialTableProps<RowData>) {
  const actionsTable = useMemo(() => {
    const defaultActions: Action<RowData>[] = [
      {
        icon: 'add',
        tooltip: 'Adicionar',
        isFreeAction: true,
        hidden: !onAdd,
        onClick: (event: any, data: RowData | RowData[]) => {
          if (onAdd) {
            onAdd(event, data);
          }
        },
      },
      {
        icon: () => <FilterList />,
        tooltip: 'Pesquisa Avançada',
        isFreeAction: true,
        hidden: !onShowCustomFilter,
        onClick: (event: any, data: RowData | RowData[]) => {
          if (onShowCustomFilter) {
            onShowCustomFilter();
          }
        },
      },
      {
        icon: 'refresh',
        tooltip: 'Recarregar',
        isFreeAction: true,
        hidden: !onRefresh,
        onClick: (event: any, data: RowData | RowData[]) => {
          if (onRefresh) {
            onRefresh();
          }
        },
      },
      {
        icon: 'delete',
        tooltip: 'Remover',
        hidden: !onDeleteMany,
        onClick: (event: any, data: RowData | RowData[]) => {
          if (onDeleteMany) {
            onDeleteMany(data);
          }
        },
      },
    ];

    if (actions) {
      return [...defaultActions, ...actions] as Action<RowData>[];
    }

    return defaultActions as Action<RowData>[];
  }, [onAdd, onDeleteMany, onRefresh, onShowCustomFilter, actions]);

  return (
    <MaterialTable
      actions={actionsTable}
      style={{
        fontSize: 15,
      }}
      options={{
        filtering: true,
        selection: true,
        grouping: true,
        exportFileName: fileNameExport,
        exportDelimiter: ';',
        doubleHorizontalScroll: true,
        exportButton: true,
        ...(rest.options || {}),
      }}
      localization={{
        pagination: {
          labelRowsSelect: 'registros',
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsPerPage: 'Registros por página:',
          firstAriaLabel: 'Primeira página',
          firstTooltip: 'Primeira página',
          previousAriaLabel: 'Página anterior',
          previousTooltip: 'Página anterior',
          nextAriaLabel: 'Próxima página',
          nextTooltip: 'Próxima página',
          lastAriaLabel: 'Última página',
          lastTooltip: 'Última página',
        },
        toolbar: {
          addRemoveColumns: 'Adicionar ou remover colunas',
          showColumnsTitle: 'Mostrar colunas',
          showColumnsAriaLabel: 'Mostrar colunas',
          exportTitle: 'Exportar',
          exportAriaLabel: 'Exportar',
          nRowsSelected: '{0} registro(s) selecionado(s)',
          searchTooltip: 'Procurar',
          searchPlaceholder: 'Procurar',
        },
        header: {
          actions: 'Ações',
        },
        body: {
          addTooltip: 'Adicionar',
          deleteTooltip: 'Remover',
          editTooltip: 'Editar',
          emptyDataSourceMessage: 'Não há registros.',
          filterRow: {
            filterTooltip: 'Filtrar',
          },
          editRow: {
            cancelTooltip: 'Cancelar',
            saveTooltip: 'Salvar',
            deleteText: 'Tem certeza que quer remover esse registro ?',
          },
        },
        grouping: {
          placeholder: 'Arraste os títulos para agrupar.',
        },
      }}
      {...rest}
    />
  );
}

// options={{
//   filtering: true,
//   selection: true,
//   grouping: true,
//   exportButton: true,
//   exportFileName: fileNameExport,
//   exportDelimiter: ';',
//   doubleHorizontalScroll: true,
//   ...options,
// }}
// actions={[
//   {
//     icon: 'add',
//     tooltip: 'Adicionar',
//     isFreeAction: true,
//     hidden: !onAdd,
//     onClick: (event:  any, data: RowData | RowData[]) => {
//       if (onAdd) {
//         onAdd(event, data);
//       }
//     },
//     // onClick: onAdd || function () {},
//   },
//   {
//     icon: () => <FilterList />,
//     tooltip: 'Pesquisa Avançada',
//     isFreeAction: true,
//     hidden: !onShowCustomFilter,
//     onClick: (event:  any, data: RowData | RowData[]) => {
//       if (onShowCustomFilter) {
//         onShowCustomFilter();
//       }
//     },
//   },
//   {
//     icon: 'refresh',
//     tooltip: 'Recarregar',
//     isFreeAction: true,
//     hidden: !onRefresh,
//     onClick: (event:  any, data: RowData | RowData[]) => {
//       if (onRefresh) {
//         onRefresh();
//       }
//     },
//   },
//   {
//     icon: 'delete',
//     tooltip: 'Remover',
//     hidden: !onDeleteMany,
//     onClick: (event:  any, data: RowData | RowData[]) => {
//       if (onDeleteMany) {
//         onDeleteMany(data);
//       }
//     },
//   },
//   ...actions,
// ]}
// {...rest}
