import React, { useState, useEffect, useCallback } from 'react';
import BaseTableDataManager from '../../../components/BaseTableDataManager';
import { IParticipant } from '../../../types';
import Error from '../../../components/Helpers/Information/Error';
import Loading from '../../../components/Helpers/Information/Loading';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import DialogScroll from '../../../components/DialogScroll';
import { useUserCompanies } from '../../../hooks/userCompanies';
import NewParticipant from './NewParticipant';
import { Container } from './styles';
import {
  getNameFromParticipant,
  getAndFormatIdentificator,
} from '../../../utils/format';

const Participants: React.FC = () => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { selectedCompany } = useUserCompanies();
  const [participants, setParticipants] = useState<IParticipant[]>([]);
  const [open, setOpen] = useState(false);

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  useEffect(() => {
    async function loadParticipants(company_id: string) {
      try {
        setError(false);
        const { data } = await api.get<IParticipant[]>(
          `companies/${company_id}/members`,
        );

        const dataFormatted = data.map(participant => {
          const nameFormatted = getNameFromParticipant(participant);
          const identificator = getAndFormatIdentificator(participant);

          return {
            ...participant,
            nameFormatted,
            identificator,
          };
        });

        setParticipants(dataFormatted);
      } catch (err) {
        addToast({
          title: 'Inconsistência no loading',
          description:
            'Não foi possível carregar os participantes da empresa, tente mais tarde.',
          type: 'error',
        });
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    if (selectedCompany?.id) {
      loadParticipants(selectedCompany.id);
    }
  }, [addToast, selectedCompany]);

  const handleOnAddParticipant = useCallback((participant: IParticipant) => {
    const nameFormatted = getNameFromParticipant(participant);
    const identificator = getAndFormatIdentificator(participant);

    setParticipants(current => [
      ...current,
      { ...participant, nameFormatted, identificator },
    ]);
  }, []);

  // const handleOnDeleteProduct = useCallback(
  //   async (items: IParticipant[]) => {
  //     setLoading(true);
  //     await Promise.all(
  //       items.map(async item => {
  //         try {
  //           await api.delete(`product/${item.id}`);

  //           setParticipants(current =>
  //             current.filter(product => product.id !== item.id),
  //           );
  //         } catch (err) {
  //           addToast({
  //             title: 'Remoção não foi possível',
  //             description: `A remoção do produto ${item.name} não foi possível.`,
  //             type: 'error',
  //           });
  //         }
  //       }),
  //     );

  //     setLoading(false);
  //   },
  //   [addToast],
  // );

  if (error) {
    return <Error />;
  }

  if (loading || !participants) {
    return <Loading />;
  }

  if (!selectedCompany) {
    return (
      <Error message="É necessário selecionar a empresa no escopo geral." />
    );
  }

  return (
    <Container>
      <BaseTableDataManager<IParticipant>
        isLoading={loading}
        options={{
          grouping: true,
          pageSizeOptions: [5, 20, 50, participants.length],
          selection: false,
        }}
        title="Participantes"
        columns={[
          {
            title: 'Nome',
            field: 'nameFormatted',
          },
          {
            title: 'CPF/CNPJ',
            field: 'identificator',
          },
          {
            title: 'Nome fantasia',
            field: 'juridical.fantasy_name',
          },
          { title: 'Cód.', field: 'id', grouping: false },
        ]}
        data={participants}
        onAdd={() => setOpen(true)}
        // onDeleteMany={data => handleOnDeleteProduct(data as IParticipant[])}
      />

      {open && (
        <DialogScroll
          open={open}
          dialogTitle="Novo participante"
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <NewParticipant
            company_id={selectedCompany.id}
            onAddParticipant={handleOnAddParticipant}
            onCancel={handleOnToogleDialog}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default Participants;
