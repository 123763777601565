import React from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';
import AuthLayout from '../pages/_layouts';
import { useAuth } from '../hooks/auth';
import { useUserCompanies } from '../hooks/userCompanies';
import CompanySelection from '../pages/modules/controle/CompanySelection';
import { useCicle } from '../hooks/useCicle';
import CicleSelection from '../pages/modules/controle/CicleSelection';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();
  const { selectedCompany } = useUserCompanies();
  const { selectedCicle } = useCicle();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (isPrivate && !!user && !selectedCompany) {
          return <CompanySelection />;
        }

        if (isPrivate && !!user && selectedCompany && !selectedCicle) {
          return <CicleSelection />;
        }

        if (isPrivate && !!user && !!selectedCompany && !!selectedCicle) {
          return (
            <AuthLayout>
              <Component />
            </AuthLayout>
          );
        }

        if (!isPrivate && !user) {
          return <Component />;
        }

        return (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/dashboard',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
