import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: auto auto;
  justify-content: center;
  align-content: center;
  align-items: center;

  color: ${props => props.theme.main};

  svg {
    color: ${props => props.theme.main};
  }
`;
