import { AxiosError } from 'axios';
import { AxiosErrorResponse } from '../hooks/auth';

interface IErrorHandlerReturn {
  type: 'success' | 'info' | 'warning' | 'error';
  title: string;
  description?: string;
  status?: number;
  needsLogout?: boolean;
}

export function errorHandler(error: AxiosError): IErrorHandlerReturn {
  if (!error.message) {
    return {
      status: error.response?.status,
      type: 'error',
      title: 'Erro na comunicação',
      description:
        'Não está sendo possível conectar aos servidores, cheque sua conexão ou tente mais tarde.',
    };
  }
  if ([400, 401].includes(error.response?.status || 0)) {
    if (
      error.response?.status === 401 &&
      String(error.response.data.message).toLocaleLowerCase() ===
        'Invalid JWT token'.toLocaleLowerCase()
    ) {
      return {
        status: error.response?.status,
        type: 'error',
        title: 'Credenciais expiraram',
        description: 'Suas credenciais expirararam',
        needsLogout: true,
      };
    }
    return {
      status: error.response?.status,
      type: 'error',
      title: 'Erro na autenticação',
      description: error.response?.data
        ? (error.response?.data as AxiosErrorResponse).message
        : 'Houve um erro na autenticação, tente novamente.',
    };
  }

  if ([403].includes(error.response?.status || 0)) {
    return {
      status: error.response?.status,
      type: 'error',
      title: 'Permissão insuficiente',
      description: error.response?.data
        ? (error.response?.data as AxiosErrorResponse).message
        : 'Você não tem permissão para executar essa operação',
    };
  }

  if ([404].includes(error.response?.status || 0)) {
    return {
      status: error.response?.status,
      type: 'error',
      title: 'Ops! Algo não existe!',
      description: error.response?.data
        ? (error.response?.data as AxiosErrorResponse).message
        : 'Serviço ou funcionalidade não está mais disponível.',
    };
  }

  return {
    status: error.response?.status,
    type: 'error',
    title: 'Ops! Algo não errado!',
    description:
      'Alguma inconsistência aconteceu. Avise o suporte sobre o problema.',
  };
}
