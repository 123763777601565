import { format } from 'date-fns';
import { IParticipant, TPaymentType } from '../types';

export const cpfMaskDefinition = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const cnpjMaskDefinition = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export function cpfMask(value: string | undefined): string {
  if (!value) return '';

  const cleanValue = value.replace(/\D/g, '');

  if (cleanValue.length !== 11) {
    return 'CPF inválido';
  }

  return cleanValue
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function cnpjMask(value: string | undefined): string {
  if (!value) return '';
  const cleanValue = value.replace(/\D/g, '');

  if (cleanValue.length !== 14) {
    return 'CNPJ inválido';
  }
  return cleanValue.replace(
    /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
    '$1.$2.$3/$4-$5',
  );
}

export function formatIdentificador(value: string | undefined): string {
  if (!value) return '';

  const cleanValue = value.replace(/\D/g, '');

  if (cleanValue.length === 14) {
    return cnpjMask(value);
  }

  if (cleanValue.length === 11) {
    return cpfMask(value);
  }

  return 'Identificador Inválido';
}

export function getAndFormatIdentificator(
  value: IParticipant | undefined,
): string {
  if (value?.type === 'fisical') {
    return formatIdentificador(value.fisical?.cpf);
  }

  return formatIdentificador(value?.juridical?.cnpj);
}

export function getNameFromParticipant(
  value: IParticipant | undefined,
): string {
  if (value?.type === 'fisical') {
    return value.fisical?.name || '';
  }

  return value?.juridical?.fantasy_name || value?.juridical?.social_name || '';
}

export function formatCurrency(value: number | undefined): string {
  if (!value) return 'R$ 0,00';

  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  });
}

export function convertGramsTokilogram(value: number | undefined): string {
  if (!value) return '0.00';

  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    maximumFractionDigits: 3,
  }).format(value / 1000);
}

export function convertGramsToTon(value: number | undefined): string {
  if (!value) return '0.00';

  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumSignificantDigits: 2,
  }).format(value / 10000);
}

export function formatNumber(value: number | undefined): string {
  if (!value) return '0.00';

  return new Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumSignificantDigits: 2,
  }).format(value);
}

export function formatDate(value: Date | undefined): string {
  if (!value) return '';

  return format(value, 'dd/MM/yyyy');
}

export const paymentTypes = {
  cash: 'Dinheiro',
  debit: 'Cartão de débito',
  credit: 'Cartão de crédito',
  pix: 'PIX',
  transfer: 'Transferência',
};

export function formatPaymentType(payment_type: TPaymentType): string {
  return paymentTypes[payment_type];
}
