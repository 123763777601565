import React, { useEffect, useCallback } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useUserCompanies } from '../../../../hooks/userCompanies';
import logoazul from '../../../../assets/logo_azul.png';
import { useCicle } from '../../../../hooks/useCicle';
import { ICicle } from '../../../../types';
import { useAuth } from '../../../../hooks/auth';
import { Container, ListCompanies, CompanieCard } from './styles';

const CicleSelection: React.FC = () => {
  const history = useHistory();
  const { signOut } = useAuth();
  const { selectedCompany } = useUserCompanies();
  const { cicles, selectedCicle, loadCicles, onChangeCicle } = useCicle();

  useEffect(() => {
    if (selectedCompany) {
      loadCicles(selectedCompany.id);
    }
  }, [selectedCompany, loadCicles]);

  const handleOnChangeCicle = useCallback(
    (cicle: ICicle) => {
      onChangeCicle(cicle);
      history.push('/dashboard');
    },
    [history, onChangeCicle],
  );

  if (!cicles) {
    return (
      <Container>
        <h1>Carregando lista de ciclos</h1>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      {!selectedCicle && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton onClick={signOut}>
            <ExitToAppIcon fontSize="large" style={{ color: '#5BB77F' }} />
          </IconButton>
        </div>
      )}
      {!selectedCicle && (
        <>
          <h3>Selecione o ciclo para iniciar</h3>
        </>
      )}

      <ListCompanies>
        {cicles.map(cicle => {
          return (
            <CompanieCard
              key={cicle.id}
              onClick={() => handleOnChangeCicle(cicle)}
            >
              <img src={logoazul} alt="Farmfish logo" />

              <div>
                <strong>{cicle.description}</strong>
              </div>
            </CompanieCard>
          );
        })}
      </ListCompanies>
    </Container>
  );
};

export default CicleSelection;
