import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import BaseTableDataManager from '../../../../components/BaseTableDataManager';
import { IPond, IBiometry } from '../../../../types';
import Error from '../../../../components/Helpers/Information/Error';
import Loading from '../../../../components/Helpers/Information/Loading';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import DialogScroll from '../../../../components/DialogScroll';
import NewBiometry from './NewBiometry';
import { formatDate } from '../../../../utils/format';
import { Container } from './styles';
import { useCicle } from '../../../../hooks/useCicle';

interface RouteParams {
  id: string;
}

const Biometries: React.FC = () => {
  const { addToast } = useToast();
  const { id } = useParams<RouteParams>();
  const { selectedCicle } = useCicle();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pond, setPond] = useState<IPond | undefined>();
  const [open, setOpen] = useState(false);

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  useEffect(() => {
    async function loadPondBiometries(pond_id: string, cicle_id: string) {
      try {
        setError(false);
        const { data } = await api.get<IPond>(`pond/${pond_id}/biometries`, {
          params: { ...api.defaults.params, cicle_id },
        });

        setPond({
          ...data,
          biometries: data.biometries?.filter(
            biometry => cicle_id === biometry.cicle_id,
          ),
        });
      } catch (err) {
        addToast({
          title: 'Inconsistência no loading',
          description:
            'Não foi possível carregar as populações do viveiro, tente mais tarde.',
          type: 'error',
        });
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    if (id && selectedCicle?.id) {
      loadPondBiometries(id, selectedCicle.id);
    }
  }, [id, addToast, selectedCicle]);

  const handleOnAddNewBiometry = useCallback((biometry: IBiometry) => {
    setPond(current => {
      if (!current) return undefined;

      const { biometries = [] } = current;

      return {
        ...current,
        biometries: [...biometries, biometry],
      };
    });
  }, []);

  const handleOnRemoveBiometry = useCallback(
    async (item: IBiometry) => {
      setLoading(true);

      try {
        await api.delete(`biometry/${item.id}`);

        setPond(current => {
          if (!current) return undefined;

          return {
            ...current,
            biometries:
              current.biometries?.filter(biometry => biometry.id !== item.id) ||
              [],
          };
        });
      } catch (err) {
        addToast({
          title: 'Remoção não foi possível',
          description: `A remoção da biometria ${item.id} não foi possível.`,
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  function handleOnAdd() {
    if (pond && pond.cicle_id === selectedCicle?.id) {
      setOpen(true);
    } else {
      addToast({
        title: 'Viveiro atualmente está em outro ciclo',
        description:
          'As operações de ajuste neste ciclo foram bloqueadas devido o início de outro.',
        type: 'warning',
      });
    }
  }

  if (error) {
    return <Error />;
  }

  if (loading || !pond) {
    return <Loading />;
  }

  return (
    <Container>
      <header>
        <Link to={`/farms/${pond.farm_id}/fishtanks`}>
          <h1>{pond.name}</h1>
        </Link>
        <h1>{`>`}</h1>
        <h1>Biometrias</h1>
      </header>
      <BaseTableDataManager<IBiometry>
        isLoading={loading}
        options={{
          grouping: true,
          pageSizeOptions:
            pond.biometries && pond.biometries.length > 5
              ? [5, 20, 50, pond.biometries.length]
              : [5, 20, 50],
        }}
        title="Biometrias"
        columns={[
          {
            title: 'Data',
            field: 'date',
            type: 'date',
            render: (column: IBiometry) => {
              return formatDate(new Date(column.date));
            },
          },
          {
            title: 'Peso (gramas)',
            field: 'weight',
            type: 'numeric',
            render: (column: IBiometry) => {
              return column.weight.toLocaleString('pt-BR');
            },
          },
          {
            title: 'Observação',
            field: 'observation',
          },
          { title: 'Cód.', field: 'id', grouping: false },
        ]}
        data={pond.biometries || []}
        onAdd={handleOnAdd}
        onDeleteMany={data => handleOnRemoveBiometry(data as IBiometry)}
      />

      {open && (
        <DialogScroll
          open={open}
          dialogTitle="Nova biometria"
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <NewBiometry
            pond_id={pond.id}
            onAddNewBiometry={handleOnAddNewBiometry}
            onCancel={handleOnToogleDialog}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default Biometries;
