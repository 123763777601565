import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  flex: 1;
  color: ${props => props.theme.main};

  header {
    h1 {
    }
  }
`;

export const List = styled.ul`
  display: grid;
  flex: 1;
  grid-template-columns: repeat(auto-fill, max(240px));
  gap: 10px;
  margin: 10px 0;
`;

export const ListItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 10px;
  padding: 18px 20px;
  background: ${props => props.theme.main};

  svg {
    margin-left: 11px;
  }
`;

interface ListTextProps {
  isNegative?: boolean;
}

export const ListText = styled.div<ListTextProps>`
  p {
    font-size: 14px;
    color: ${props => props.theme.text.softLight};
    font-weight: 500;
  }

  div {
    display: flex;
    align-items: center;

    strong {
      font-size: 20px;
      color: ${props => props.theme.text.white};
      margin-right: 5px;
    }

    span {
      color: #2dce98;
      margin-right: 10px;
    }

    ${props =>
      props.isNegative &&
      css`
        span {
          color: #f53c56;
        }
      `}

    & + div {
      font-size: 10px;

      p {
        font-size: 10px;
      }
    }
  }
`;
