import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  min-width: 350px;
`;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;

  & > * {
    margin-top: 10px;
  }

  & > section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
`;
