/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

import React, { useState, useCallback, useMemo } from 'react';
import ReportIcon from '@material-ui/icons/Assessment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { AutoSizer } from 'react-virtualized';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { IconButton } from '@material-ui/core';
import CostBySpecie from './CostBySpecie';
import StockByFarm from './StockByFarm';
import MortalitiesBySpecieAndFarm from './MortalitiesBySpecieAndFarm';
import SellsByFarmAndSpecie from './SellsByFarmAndSpecie';
import StockByPond from './StockByPond';
// import MortalitiesByPond from './MortalitiesByPond';
import {
  Container,
  Content,
  TabsContainer,
  ControlMenu,
  ReportArea,
} from './styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box minWidth={400} minHeight={400}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tab: {
    width: 40,
  },
}));

const Reports: React.FC = () => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(true);
  const [value, setValue] = React.useState(0);

  const handleOnOpenChange = useCallback(() => {
    setMenuOpen(current => !current);
  }, []);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const listOptions = useMemo(() => {
    return [
      'Custo por espécie/fazenda',
      'Estoque por viveiro',
      'Estoque por fazenda',
      // 'Mortalidades por viveiro',
      'Mortalidades por fazenda',
      'Vendas por espécie',
    ];
  }, []);

  const ListComponents = [
    CostBySpecie,
    StockByPond,
    StockByFarm,
    // MortalitiesByPond,
    MortalitiesBySpecieAndFarm,
    SellsByFarmAndSpecie,
  ];

  return (
    <Container>
      <header>
        <h1>Relatórios</h1>
      </header>
      <Content>
        <TabsContainer>
          <ControlMenu>
            {menuOpen ? (
              <IconButton onClick={handleOnOpenChange}>
                <ArrowBackIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleOnOpenChange}>
                <ArrowForwardIcon />
              </IconButton>
            )}
          </ControlMenu>

          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            {listOptions.map((itemList, index) => {
              return (
                <Tab
                  icon={<ReportIcon />}
                  wrapped
                  label={menuOpen && itemList}
                  className={classes.tab}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </TabsContainer>

        <ReportArea>
          <AutoSizer>
            {({ height, width }) => {
              return ListComponents.map((report, index) => {
                const ReportComponent = report;
                return (
                  <TabPanel key={String(index)} value={value} index={index}>
                    <ReportComponent height={height - 30} width={width - 20} />
                  </TabPanel>
                );
              });
            }}
          </AutoSizer>
        </ReportArea>
      </Content>
    </Container>
  );
};

export default Reports;
