import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import FarmShow from '../pages/Farm/Show';
import FishTank from '../pages/Farm/FishTank';
import Populate from '../pages/Farm/FishTank/Populate';
import CompanySelection from '../pages/modules/controle/CompanySelection';
import Biometries from '../pages/Farm/FishTank/Biometry';
import Mortalities from '../pages/Farm/FishTank/Mortality';
import Products from '../pages/modules/Products';
import Species from '../pages/modules/Species';
import Participants from '../pages/modules/Participant';
import ShoppingBuy from '../pages/modules/Shopping/Buy';
import ShoppingSell from '../pages/modules/Shopping/Sell';
import Users from '../pages/modules/User';
import Reports from '../pages/modules/reports';
import Logout from '../pages/Logout';
import Transference from '../pages/modules/Transference';
import FixPopulateWithoutShoppingItem from '../pages/Fix/FixPopulateWithoutShoppingItem';
import FarmCost from '../pages/Farm/Cost';
import Companies from '../pages/modules/Companies';
import CicleSelection from '../pages/modules/controle/CicleSelection';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route
        exact
        path="/company-selection"
        component={CompanySelection}
        isPrivate
      />
      <Route
        exact
        path="/cicle-selection"
        component={CicleSelection}
        isPrivate
      />
      <Route exact path="/dashboard" component={Dashboard} isPrivate />
      <Route
        exact
        path="/farms/:id/transferences"
        component={Transference}
        isPrivate
      />

      <Route exact path="/farms/:id/show" component={FarmShow} isPrivate />
      <Route exact path="/farms/:id/costs" component={FarmCost} isPrivate />
      <Route exact path="/farms/:id/fishtanks" component={FishTank} isPrivate />
      <Route
        exact
        path="/fishtanks/:id/populate"
        component={Populate}
        isPrivate
      />
      <Route
        exact
        path="/fishtanks/:id/biometry"
        component={Biometries}
        isPrivate
      />

      <Route
        exact
        path="/populate/fit/bycompany"
        component={FixPopulateWithoutShoppingItem}
        isPrivate
      />

      <Route
        exact
        path="/fishtanks/:id/mortality"
        component={Mortalities}
        isPrivate
      />
      <Route exact path="/products" component={Products} isPrivate />
      <Route exact path="/sells" component={ShoppingSell} isPrivate />
      <Route exact path="/users" component={Users} isPrivate />
      <Route exact path="/shopping" component={ShoppingBuy} isPrivate />
      <Route exact path="/species" component={Species} isPrivate />
      <Route exact path="/companies" component={Companies} isPrivate />
      <Route exact path="/participants" component={Participants} isPrivate />
      <Route exact path="/dashboard" component={Dashboard} isPrivate />
      <Route exact path="/reports" component={Reports} isPrivate />
      <Route exact path="/logout" component={Logout} isPrivate />
    </Switch>
  );
};

export default Routes;
