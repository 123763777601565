import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import {
  InputNumber,
  DatePicker,
  Input,
  Select,
} from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { IBiometry, Options, ISpecie } from '../../../../../types';
import api from '../../../../../services/api';
import { useToast } from '../../../../../hooks/toast';
import Error from '../../../../../components/Helpers/Information/Error';
import Loading from '../../../../../components/Helpers/Information/Loading';
import { Container } from './styles';
import { errorHandler } from '../../../../../utils/errorHandler';

interface NewBiometryData {
  date: Date;
  weight: number;
  observation: string;
}

interface OwnProps {
  onAddNewBiometry(biometry: IBiometry): void;
  pond_id: string;
  onCancel(): void;
}

const NewBiometry: React.FC<OwnProps> = ({
  onAddNewBiometry,
  pond_id,
  onCancel,
}) => {
  const { addToast } = useToast();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [species, setSpecies] = useState<Options[]>([]);

  useEffect(() => {
    async function loadSpecies() {
      try {
        setLoading(true);
        const { data } = await api.get<ISpecie[]>('species');

        setSpecies(
          data.map(specie => ({
            value: specie.id,
            label: specie.name,
          })),
        );
      } catch (err) {
        setError(true);
        addToast({
          title: 'Inconsistência no carregamento',
          description:
            'Houve uma inconsistência ao carregar as espécies. Tente novamente',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    setError(false);
    loadSpecies();
  }, [addToast]);

  const handleOnSubmit = useCallback(
    async (data: NewBiometryData) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          weight: Yup.number()
            .positive('O peso deve ser positivo.')
            .required('O campo peso médio em gramas é obrigatório'),
          date: Yup.date().required(
            'O campo data do povoamento é obrigatório.',
          ),
          specie_id: Yup.string().uuid().required('A espécie é obrigatória'),
          observation: Yup.string(),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        const formData = Object.assign(result, { pond_id });

        const response = await api.post<IBiometry>('biometry', formData, {
          params: api.defaults.params,
        });

        onAddNewBiometry(response.data);
        onCancel();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        } else if ((err as AxiosError).isAxiosError) {
          const { title, type, description, needsLogout } = errorHandler(err);

          addToast({
            title,
            description,
            type,
          });

          if (needsLogout) {
            history.push('/logout');
          }
        }
      }
    },
    [pond_id, onAddNewBiometry, onCancel, addToast, history],
  );

  if (error) {
    return (
      <Error message="Um dado necessário não pode ser carregado. Tente novamente" />
    );
  }

  if (loading || !species) {
    return <Loading message="Carregando dados necessários" />;
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <DatePicker name="date" label="Data da Biometria" />

        <InputNumber
          name="weight"
          label="Peso médio em gramas"
          placeholder="Insira o peso médio dos peixes apurados"
          inputProps={{
            suffix: '',
            prefix: '',
          }}
        />
        <Select
          name="specie_id"
          label="Espécie"
          options={species}
          required={false}
        />

        <Input name="observation" label="Observação" multiline rows={5} />

        <div className="farm-add-buttons-actions">
          <Button type="submit">Adicionar</Button>
          <Button colorType="cancel" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewBiometry;
