import React, { useState, useEffect, useCallback } from 'react';
import BaseTableDataManager from '../../../components/BaseTableDataManager';
import { IProduct } from '../../../types';
import Error from '../../../components/Helpers/Information/Error';
import Loading from '../../../components/Helpers/Information/Loading';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import DialogScroll from '../../../components/DialogScroll';
import { useUserCompanies } from '../../../hooks/userCompanies';
import NewProduct from './NewProduct';
import { Container } from './styles';

const Products: React.FC = () => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { selectedCompany } = useUserCompanies();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [open, setOpen] = useState(false);

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  useEffect(() => {
    async function loadProducts() {
      try {
        setError(false);
        const { data } = await api.get<IProduct[]>(`product`, {
          params: api.defaults.params,
        });

        setProducts(data);
      } catch (err) {
        addToast({
          title: 'Inconsistência no loading',
          description:
            'Não foi possível carregar as populações do viveiro, tente mais tarde.',
          type: 'error',
        });
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadProducts();
  }, [addToast]);

  const handleOnAddNewProduct = useCallback((product: IProduct) => {
    setProducts(current => [...current, product]);
  }, []);

  if (error) {
    return <Error />;
  }

  if (loading || !products) {
    return <Loading />;
  }

  if (!selectedCompany) {
    return (
      <Error message="É necessário selecionar a empresa no escopo geral." />
    );
  }

  return (
    <Container>
      <BaseTableDataManager<IProduct>
        isLoading={loading}
        options={{
          grouping: true,
          pageSizeOptions: [5, 20, 50, products.length],
          selection: false,
        }}
        title="Produtos"
        columns={[
          {
            title: 'Nome',
            field: 'name',
          },
          {
            title: 'Descrição',
            field: 'name',
          },
          {
            title: 'Categoria',
            field: 'category',
            render: (column: IProduct) => {
              const categories = {
                fish: 'Peixe',
                machines: 'Máquina',
                implement: 'Implemento',
                ration: 'Ração',
                general: 'Geral',
                inputs: 'Insumos',
              };

              return categories[column.category];
            },
          },
          {
            title: 'Espécie',
            field: 'specie.name',
          },
          {
            title: 'Ativo',
            field: 'active',
            type: 'boolean',
          },
          {
            title: 'Cód. Interno',
            field: 'internal_code',
          },
          { title: 'Cód.', field: 'id', grouping: false },
        ]}
        data={products}
        onAdd={() => setOpen(true)}
        // onDeleteMany={data => handleOnDeleteProduct(data as IProduct[])}
      />

      {open && (
        <DialogScroll
          open={open}
          dialogTitle="Novo produto"
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <NewProduct
            company_id={selectedCompany.id}
            onAddProduct={handleOnAddNewProduct}
            onCancel={handleOnToogleDialog}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default Products;
