import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import BaseTableDataManager from '../../../../components/BaseTableDataManager';
import { IPond, IMortality } from '../../../../types';
import Error from '../../../../components/Helpers/Information/Error';
import Loading from '../../../../components/Helpers/Information/Loading';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import DialogScroll from '../../../../components/DialogScroll';
import { formatDate } from '../../../../utils/format';
import { errorHandler } from '../../../../utils/errorHandler';
import { useCicle } from '../../../../hooks/useCicle';
import NewMortality from './NewMortality';
import { Container } from './styles';

interface RouteParams {
  id: string;
}

type callbackType = () => void | Promise<void>;

const Mortalities: React.FC = () => {
  const { addToast } = useToast();
  const { id } = useParams<RouteParams>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { selectedCicle } = useCicle();
  const [pond, setPond] = useState<IPond | undefined>();
  const [open, setOpen] = useState(false);

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  useEffect(() => {
    async function loadPondMortalities(pond_id: string) {
      try {
        setError(false);
        const { data } = await api.get<IPond>(`pond/${pond_id}/mortalities`, {
          params: api.defaults.params,
        });

        setPond({
          ...data,
          mortalities: data.mortalities?.filter(
            mortality => mortality.cicle_id === selectedCicle?.id,
          ),
        });
      } catch (err) {
        addToast({
          title: 'Inconsistência no loading',
          description:
            'Não foi possível carregar as mortalidades do viveiro, tente mais tarde.',
          type: 'error',
        });
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    if (id && !!selectedCicle?.id) {
      loadPondMortalities(id);
    }
  }, [id, addToast, selectedCicle]);

  const handleOnAddNewMortality = useCallback((mortality: IMortality) => {
    setPond(current => {
      if (!current) return undefined;

      const { mortalities = [] } = current;

      return {
        ...current,
        mortalities: [...mortalities, mortality],
      };
    });
  }, []);

  const handleOnDeleteMortalities = useCallback(
    async (item: IMortality) => {
      if (item.clean_pond_id) {
        addToast({
          title: 'Mortalidade gerada automaticamente não pode ser removida',
        });
      }
      try {
        setLoading(true);
        await api.delete(`mortality/${item.id}`);

        setPond(current => {
          if (!current) return undefined;

          return {
            ...current,
            mortalities: current.mortalities?.filter(
              mortality => mortality.id !== item.id,
            ),
          };
        });
      } catch (err) {
        if ((err as AxiosError).isAxiosError) {
          const { description } = errorHandler(err);
          addToast({
            title: 'Remoção não foi possível',
            description,
            type: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  function checkIsSameCicle(callback: callbackType) {
    if (pond && pond.cicle_id === selectedCicle?.id) {
      callback();
    } else {
      addToast({
        title: 'Viveiro atualmente está em outro ciclo',
        description:
          'As operações de ajuste neste ciclo foram bloqueadas devido o início de outro.',
        type: 'warning',
      });
    }
  }

  if (error) {
    return <Error />;
  }

  if (loading || !pond) {
    return <Loading />;
  }

  return (
    <Container>
      <header>
        <Link to={`/farms/${pond.farm_id}/fishtanks`}>
          <h1>{pond.name}</h1>
        </Link>
        <h1>{`>`}</h1>
        <h1>Mortalidades</h1>
      </header>
      <BaseTableDataManager<IMortality>
        isLoading={loading}
        options={{
          grouping: true,
          selection: false,
          pageSizeOptions:
            pond.mortalities && pond.mortalities.length > 50
              ? [5, 20, 50, pond.mortalities.length]
              : [5, 20, 50],
        }}
        title="Mortalidades"
        columns={[
          {
            title: 'Data',
            field: 'date',
            type: 'date',
            render: (column: IMortality) => {
              return formatDate(new Date(column.date));
            },
          },
          {
            title: 'Quantidade',
            field: 'quantity',
            type: 'numeric',
            render: (column: IMortality) => {
              return column.quantity.toLocaleString('pt-BR');
            },
          },
          {
            title: 'Espécie',
            field: 'specie.name',
          },
          { title: 'Motivo', field: 'reason' },
          { title: 'Cód.', field: 'id', grouping: false },
        ]}
        data={pond.mortalities || []}
        onAdd={() => checkIsSameCicle(() => setOpen(true))}
        onDeleteMany={data =>
          checkIsSameCicle(() => handleOnDeleteMortalities(data as IMortality))
        }
      />

      {open && (
        <DialogScroll
          open={open}
          dialogTitle="Nova mortalidade"
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <NewMortality
            pond_id={pond.id}
            onAddMortality={handleOnAddNewMortality}
            onCancel={handleOnToogleDialog}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default Mortalities;
