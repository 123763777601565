import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import api from '../../../services/api';
import { ITransferences } from '../../../types';
import { useToast } from '../../../hooks/toast';
import { DialogScroll } from '../../../components/DialogScroll';
import Error from '../../../components/Helpers/Information/Error';
import Loading from '../../../components/Helpers/Information/Loading';
import BaseTableDataManager from '../../../components/BaseTableDataManager';
import { useCicle } from '../../../hooks/useCicle';
import { formatDate } from '../../../utils/format';
import { errorHandler } from '../../../utils/errorHandler';
import NewTrasnference from './NewTransference';
import { Container } from './styles';

interface RouteParams {
  id: string;
}

function formatTransference(transference: ITransferences): ITransferences {
  return {
    ...transference,
    biomassConverted: Math.floor(transference.biomass / 1000),
    dateFormatted: formatDate(
      transference.date
        ? new Date(transference.date)
        : new Date(transference.created_at),
    ),
  };
}

const Transference: React.FC = () => {
  const { id } = useParams<RouteParams>();

  const { addToast } = useToast();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { selectedCicle } = useCicle();
  const [transferences, setTransferences] = useState<ITransferences[]>([]);

  useEffect(() => {
    async function loadTransferences(farm_id: string, cicle_id: string) {
      try {
        setError(false);
        const { data } = await api.get<ITransferences[]>(
          `/farms/${farm_id}/transferences`,
          {
            params: { ...api.defaults.params, cicle_id },
          },
        );

        setTransferences(
          data
            .filter(transference => transference.cicle_id === selectedCicle?.id)
            .map(transference => formatTransference(transference)),
        );
      } catch (err) {
        addToast({
          title: 'Inconsistência no loading',
          description:
            'Não foi possível carregar as transferências, tente mais tarde.',
          type: 'error',
        });
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    if (id && selectedCicle?.id) {
      loadTransferences(id, selectedCicle.id);
    }
  }, [addToast, id, selectedCicle]);

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  const handleOnAddTransference = useCallback(
    (transference: ITransferences) => {
      setTransferences(current => [
        ...current,
        formatTransference(transference),
      ]);
    },
    [],
  );

  const handleOnDeleteTransference = useCallback(
    async (transference: ITransferences) => {
      try {
        await api.delete(`transferences/${transference.id}`);

        setTransferences(current =>
          current.filter(item => item.id !== transference.id),
        );

        addToast({
          title: 'Transferência removida com sucesso!',
          type: 'success',
        });
      } catch (err) {
        if ((err as AxiosError).isAxiosError) {
          const { description } = errorHandler(err);

          addToast({
            title: 'Não foi possível remover a transferência',
            description,
            type: 'error',
          });
        }
      }
    },
    [addToast],
  );

  if (error) {
    return <Error />;
  }

  if (loading || !transferences) {
    return <Loading />;
  }

  return (
    <Container>
      <BaseTableDataManager<ITransferences>
        isLoading={loading}
        options={{
          grouping: true,
          pageSizeOptions: [5, 20, 50, transferences.length],
          selection: false,
        }}
        title="Transferências"
        onAdd={handleOnToogleDialog}
        onDeleteMany={data =>
          handleOnDeleteTransference(data as ITransferences)
        }
        columns={[
          {
            title: 'Espécie',
            field: 'specie.name',
          },
          {
            title: 'Data',
            field: 'date',
            type: 'date',
            render: column => column.dateFormatted,
          },
          {
            title: 'Origem',
            field: 'pondOrigin.name',
          },
          {
            title: 'Destino',
            field: 'pondDestiny.name',
          },
          {
            title: 'Quantidade',
            field: 'quantity',
            type: 'numeric',
          },
          {
            title: 'Peso Un/g',
            field: 'mean_weight',
            type: 'numeric',
          },
          {
            title: 'Biomassa (KG)',
            field: 'biomassConverted',
            type: 'numeric',
          },
          { title: 'Cód.', field: 'id', grouping: false },
        ]}
        data={transferences}
      />
      {open && (
        <DialogScroll
          open={open}
          dialogTitle="Nova transferência"
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <NewTrasnference
            farm_id={id}
            onAddTransference={handleOnAddTransference}
            onCancel={handleOnToogleDialog}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default Transference;
