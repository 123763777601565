import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import { IconButton } from '@material-ui/core';
import { GiSpiderWeb } from 'react-icons/gi';
import BaseTableDataManager from '../../../components/BaseTableDataManager';
import { IPond, IFarm } from '../../../types';
import Error from '../../../components/Helpers/Information/Error';
import Loading from '../../../components/Helpers/Information/Loading';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import DialogScroll from '../../../components/DialogScroll';
import { useCicle } from '../../../hooks/useCicle';
import NewPond from './NewPond';
import NewDespesca from './NewDespesca';
import PopoverFishTankMenu from './Popover';
import { Container } from './styles';
import { errorHandler } from '../../../utils/errorHandler';
import { formatDate } from '../../../utils/format';

interface RouteParams {
  id: string;
}

const FishTank: React.FC = () => {
  const { addToast } = useToast();
  const { id } = useParams<RouteParams>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { selectedCicle } = useCicle();
  const [farm, setFarm] = useState<IFarm | undefined>();
  const [open, setOpen] = useState(false);
  const [despesca, setDespesca] = useState<IPond | undefined>();

  const handleOnToogleDialog = useCallback(() => {
    setOpen(current => !current);
  }, []);

  useEffect(() => {
    async function loadFarmPonds(farm_id: string, cicle_id: string) {
      try {
        setError(false);
        const { data } = await api.get<IFarm>(`farms/${farm_id}/ponds`, {
          params: api.defaults.params,
        });

        const { ponds } = data;

        const pondsFormatted = ponds
          // .filter(pond => pond.cicle_id === cicle_id)
          .map(pond => {
            const resumeStocks = pond.stocks
              ?.filter(stock => stock.cicle_id === cicle_id)
              .reduce(
                (prev, curr) => {
                  return {
                    sum_fish_count: prev.sum_fish_count + curr.fish_count,
                    sum_weight: prev.sum_weight + curr.weight_approximate,
                  };
                },
                {
                  sum_fish_count: 0,
                  sum_weight: 0,
                },
              ) || {
              sum_fish_count: 0,
              sum_weight: 0,
            };

            const total_fish = Math.floor(resumeStocks.sum_fish_count);

            return {
              ...pond,
              is_populated: !!total_fish,
              stocks: pond.stocks?.filter(stock => stock.cicle_id === cicle_id),
              total_fish,
              total_weight: Number((resumeStocks.sum_weight / 1000).toFixed(3)),
            };
          });

        setFarm({
          ...data,
          ponds: pondsFormatted,
        });
      } catch (err) {
        addToast({
          title: 'Inconsistência no loading',
          description:
            'Não foi possível carregar os viveiros, tente mais tarde.',
          type: 'error',
        });
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    if (id && selectedCicle?.id) {
      loadFarmPonds(id, selectedCicle.id);
    }
  }, [id, addToast, selectedCicle]);

  const handleOnAddNewPond = useCallback((pond: IPond) => {
    setFarm(current => {
      if (!current) return undefined;

      const { ponds } = current;

      return {
        ...current,
        ponds: [...ponds, pond],
      };
    });
  }, []);

  const handleOnDeletePond = useCallback(
    async (item: IPond) => {
      setLoading(true);

      try {
        await api.delete(`pond/${item.id}`);

        setFarm(current => {
          if (!current) return undefined;

          return {
            ...current,
            ponds: current.ponds.filter(pond => pond.id !== item.id),
          };
        });
      } catch (err) {
        if ((err as AxiosError).isAxiosError) {
          const { description } = errorHandler(err);
          addToast({
            title: 'Remoção não foi possível',
            description: `A remoção do viveiro ${item.name} não foi possível. Motivo: ${description}`,
            type: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleOnSubmitDespesca = useCallback(
    (pond_id: string) => {
      setFarm(current => {
        if (!current) return undefined;

        return {
          ...current,
          ponds: current?.ponds.filter(pond => {
            return pond.cicle_id === selectedCicle?.id && pond.id !== pond_id;
          }),
        };
      });

      setDespesca(undefined);
    },
    [selectedCicle],
  );

  if (error) {
    return <Error />;
  }

  if (loading || !farm || !selectedCicle) {
    return <Loading />;
  }

  return (
    <Container>
      <header>
        <Link to={`/farms/${farm.id}/show`}>
          <h1>{farm.name}</h1>
        </Link>
        <h1>{`>`}</h1>
        <h1>Viveiros</h1>
      </header>
      <BaseTableDataManager<IPond>
        isLoading={loading}
        options={{
          grouping: true,
          pageSizeOptions: [5, 20, 50, farm.ponds.length],
        }}
        title="Viveiros"
        columns={[
          {
            title: 'Nome',
            field: 'name',
          },
          {
            title: 'Encerrar ciclo',
            field: 'id',
            render: column => {
              return (
                <IconButton onClick={() => setDespesca(column)}>
                  <GiSpiderWeb size={22} color="#000000" />
                </IconButton>
              );
            },
          },
          {
            title: 'Área (ha)',
            field: 'capacity',
            type: 'numeric',
            render: (column: IPond) => {
              return column.capacity.toLocaleString('pt-BR');
            },
          },
          {
            title: 'Povoado',
            field: 'first_populate',
            type: 'date',
            render: column => {
              if (column.populates && column.populates?.length > 0) {
                return formatDate(new Date(column.populates[0].date));
              }

              return null;
            },
          },
          {
            title: 'Peixes',
            field: 'total_fish',
            type: 'numeric',
            grouping: false,
          },
          {
            title: 'Biomassa (KG)',
            field: 'total_weight',
            type: 'numeric',
            grouping: false,
          },
          {
            title: 'Peso Un/g',
            field: 'mean_grams',
            type: 'numeric',
            grouping: false,
            render: column => {
              if (column.total_weight)
                return Number(
                  ((column.total_weight * 1000) / column.total_fish).toFixed(0),
                );

              return 0;
            },
          },
          {
            title: 'Opções',
            field: 'id',
            filtering: false,
            grouping: false,
            render: (column: IPond) => (
              <PopoverFishTankMenu idTank={column.id} />
            ),
          },
          { title: 'Cód.', field: 'id', filtering: false, grouping: false },
        ]}
        data={farm.ponds}
        onAdd={() => setOpen(true)}
        onDeleteMany={data => handleOnDeletePond(data as IPond)}
      />

      {open && (
        <DialogScroll
          open={open}
          dialogTitle="Novo viveiro"
          onClose={handleOnToogleDialog}
          onClickActionCancelButton={handleOnToogleDialog}
          dialogActions={<div />}
        >
          <NewPond
            farm_id={farm.id}
            onAddPond={handleOnAddNewPond}
            onCancel={handleOnToogleDialog}
          />
        </DialogScroll>
      )}

      {despesca && (
        <DialogScroll
          open={!!despesca}
          dialogTitle="Encerrar Ciclo"
          onClose={() => setDespesca(undefined)}
          onClickActionCancelButton={() => setDespesca(undefined)}
          dialogActions={<div />}
        >
          <NewDespesca
            pond={despesca}
            onCancel={() => setDespesca(undefined)}
            onSubmitDespesca={handleOnSubmitDespesca}
          />
        </DialogScroll>
      )}
    </Container>
  );
};

export default FishTank;
