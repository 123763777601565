import React, { useEffect, useCallback } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { IconButton } from '@material-ui/core';
import { useUserCompanies, Company } from '../../../../hooks/userCompanies';
import { useAuth } from '../../../../hooks/auth';
import logoazul from '../../../../assets/logo_azul.png';
import { Container, ListCompanies, CompanieCard } from './styles';

const CompanySelection: React.FC = () => {
  const history = useHistory();
  const { user, signOut } = useAuth();

  const {
    companies,
    selectedCompany,
    loadCompanies,
    changeSelectedCompany,
  } = useUserCompanies();

  useEffect(() => {
    if (!companies) {
      loadCompanies(user.id);
    }
  }, [companies, user, loadCompanies]);

  const handleOnSelectCompany = useCallback(
    (company: Company) => {
      changeSelectedCompany(company);
      history.push('/dashboard');
    },
    [changeSelectedCompany, history],
  );

  if (!companies) {
    return (
      <Container>
        <h1>Carregando lista de empresas</h1>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      {!selectedCompany && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton onClick={signOut}>
            <ExitToAppIcon fontSize="large" style={{ color: '#5BB77F' }} />
          </IconButton>
        </div>
      )}
      {!selectedCompany && (
        <>
          <h1>{`Bem vindo, ${user.nameFormatted}`}</h1>
          <h3>Selecione a empresa para iniciar</h3>
        </>
      )}

      <ListCompanies>
        {companies.map(company => {
          return (
            <CompanieCard
              key={company.id}
              onClick={() => handleOnSelectCompany(company)}
            >
              <img
                src={company.logo_url || logoazul}
                alt={`Logo da empresa ${company.name}`}
              />

              <div>
                <strong>{company.name}</strong>
                <strong>{company.formattedIdentificator}</strong>
              </div>
            </CompanieCard>
          );
        })}
      </ListCompanies>
    </Container>
  );
};

export default CompanySelection;
