import React, { useCallback, useEffect, useState } from 'react';

import {
  FiBarChart2,
  FiHome,
  FiBox,
  FiList,
  FiUser,
  FiSettings,
} from 'react-icons/fi';
import { FcSupport } from 'react-icons/fc';
import { IoMdExit } from 'react-icons/io';
import { FaFish, FaMoneyBillWaveAlt, FaBoxes } from 'react-icons/fa';
import { GiThreeFriends } from 'react-icons/gi';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { BsBuilding } from 'react-icons/bs';
import logo from '../../assets/logo_original.png';
import { Container, List, ListItem } from './styles';
import api from '../../services/api';
import { errorHandler } from '../../utils/errorHandler';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { useUserCompanies } from '../../hooks/userCompanies';

const Menu: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { selectedCompany } = useUserCompanies();
  const { pathname } = useLocation();
  const { addToast } = useToast();

  const [hasFixing, setHasFixing] = useState(false);

  useEffect(() => {
    async function checkHasPopulatesToSolve(company_id: string) {
      try {
        const response = await api.get(`populate/fit/bycompany`, {
          params: {
            ...api.defaults.params,
            company_id,
          },
        });

        if (response.data.length > 0) {
          setHasFixing(true);
        }
      } catch (err) {
        if ((err as AxiosError).isAxiosError) {
          const { title, type, description, needsLogout } = errorHandler(err);
          addToast({
            title,
            description,
            type,
          });

          if (needsLogout) {
            history.push('/logout');
          }
        }
      }
    }

    if (selectedCompany?.id) {
      checkHasPopulatesToSolve(selectedCompany.id);
    }
  }, [addToast, history, selectedCompany]);

  const checkIsPathSelected = useCallback(
    (path: string): boolean => {
      return pathname.search(path) > 0;
    },
    [pathname],
  );

  return (
    <Container>
      <header>
        <img src={logo} alt="Farm Fish" />
        <FiBarChart2 size={18} />
      </header>
      <List>
        <ListItem
          to="/dashboard"
          isPathSelected={checkIsPathSelected('dashboard')}
        >
          <FiHome size={18} color="#7764E4" />
          <p>Fazendas</p>
        </ListItem>

        <ListItem
          to="/participants"
          isPathSelected={checkIsPathSelected('participants')}
        >
          <GiThreeFriends size={18} color="#7764E4" />
          <p>Participantes</p>
        </ListItem>

        <ListItem
          to="/products"
          isPathSelected={checkIsPathSelected('products')}
        >
          <FaBoxes size={18} color="#7764E4" />
          <p>Produtos</p>
        </ListItem>

        <ListItem
          to="/shopping"
          isPathSelected={checkIsPathSelected('shopping')}
        >
          <FiBox size={18} color="#11CDEF" />
          <p>Compras</p>
        </ListItem>

        <ListItem to="/sells" isPathSelected={checkIsPathSelected('sells')}>
          <FaMoneyBillWaveAlt size={18} color="#7764E4" />
          <p>Vendas</p>
        </ListItem>

        <ListItem to="/reports" isPathSelected={checkIsPathSelected('reports')}>
          <FiList size={18} color="#FB6340" />
          <p>Relatórios</p>
        </ListItem>

        <ListItem to="/users" isPathSelected={checkIsPathSelected('user')}>
          <FiUser size={18} color="#11CDEF" />
          <p>Usuários</p>
        </ListItem>

        {hasFixing && (
          <ListItem
            to="/populate/fit/bycompany"
            isPathSelected={checkIsPathSelected('populate/fit/bycompany')}
          >
            <FcSupport size={18} color="#11CDEF" />
            <p>Ajustes necessários</p>
          </ListItem>
        )}

        {user.is_admin && (
          <>
            <ListItem
              to="/species"
              isPathSelected={checkIsPathSelected('species')}
            >
              <FaFish size={18} color="#7764E4" />
              <p>Espécies</p>
            </ListItem>
            <ListItem
              to="/companies"
              isPathSelected={checkIsPathSelected('companies')}
            >
              <BsBuilding size={18} color="#7764E4" />
              <p>Empresas</p>
            </ListItem>
            <ListItem
              to="/settings"
              isPathSelected={checkIsPathSelected('settings')}
            >
              <FiSettings size={18} color="#FB6340" />
              <p>Configurações</p>
            </ListItem>
          </>
        )}

        <ListItem to="/logout" isPathSelected={checkIsPathSelected('logout')}>
          <IoMdExit size={18} color="#11CDEF" />
          <p>Sair</p>
        </ListItem>
      </List>
    </Container>
  );
};

export default Menu;
