import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { AxiosError } from 'axios';
import api from '../services/api';
import { useToast } from './toast';

import { ICicle } from '../types';
import { useUserCompanies } from './userCompanies';
import { errorHandler } from '../utils/errorHandler';

interface UseCicleContextData {
  cicles: ICicle[];
  selectedCicle?: ICicle;
  loadCicles(company_id: string): Promise<void>;
  onChangeCicle(cicle: ICicle): void;
  clearCicles(company_id: string): void;
}

const UserCicleContext = createContext<UseCicleContextData>(
  {} as UseCicleContextData,
);

const UserCicleProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const { selectedCompany } = useUserCompanies();
  const [selectedCicle, setSelectedCicle] = useState<ICicle | undefined>(() => {
    if (!selectedCompany) return undefined;

    const selectedCicleStoraged = localStorage.getItem(
      `@FarmFish:company-cicles:${selectedCompany.id}:selectedCicle`,
    );

    if (selectedCicleStoraged) {
      const cicleParsed = JSON.parse(selectedCicleStoraged) as ICicle;
      api.defaults.params = {
        ...api.defaults.params,
        cicle_id: cicleParsed.id,
      };

      return cicleParsed;
    }
    api.defaults.params = null;
    return undefined;
  });
  const [data, setData] = useState<ICicle[]>(() => {
    if (!selectedCompany) return [];

    const ciclesStoraged = localStorage.getItem(
      `@FarmFish:company-cicles:${selectedCompany.id}`,
    );

    if (ciclesStoraged) {
      return JSON.parse(ciclesStoraged) as ICicle[];
    }

    return [];
  });

  const onChangeCicle = useCallback(
    async (cicle: ICicle | undefined) => {
      if (selectedCompany) {
        setSelectedCicle(cicle);
        if (!cicle) {
          localStorage.removeItem(
            `@FarmFish:company-cicles:${selectedCompany.id}:selectedCicle`,
          );
          api.defaults.params = {
            ...api.defaults.params,
            cicle_id: null,
          };
          return;
        }

        localStorage.setItem(
          `@FarmFish:company-cicles:${selectedCompany.id}:selectedCicle`,
          JSON.stringify(cicle),
        );

        api.defaults.params = {
          ...api.defaults.params,
          cicle_id: cicle.id,
        };
      }
    },
    [selectedCompany],
  );

  const loadCicles = useCallback(
    async (company_id: string) => {
      try {
        const response = await api.get<ICicle[]>('/cicles/bycompany', {
          params: {
            company_id,
          },
        });

        localStorage.setItem(
          `@FarmFish:company-cicles:${company_id}`,
          JSON.stringify(response.data),
        );

        setData(response.data);

        if (response.data.length > 0) {
          onChangeCicle(response.data[0]);
        }
      } catch (err) {
        if ((err as AxiosError).isAxiosError) {
          const { description } = errorHandler(err);

          if (!description) {
            addToast({
              type: 'error',
              title: 'Inconsistência na comunicação',
              description:
                'Não está sendo possível conectar aos servidores, cheque sua conexão ou tente mais tarde.',
            });
          } else {
            addToast({
              type: 'error',
              title: 'Inconsistência no loading',
              description: `Houve um erro no carregamento dos ciclos: ${description}`,
            });
          }
        } else {
          addToast({
            type: 'error',
            title: 'Inconsistência no sistema',
            description:
              'Houve uma inconsistência inesperada no sistema. O serviço já foi notificado, desculpe pelo desconforto.',
          });
        }
      }
    },
    [addToast, onChangeCicle],
  );

  useEffect(() => {
    if (selectedCompany?.id) {
      loadCicles(selectedCompany.id);
    } else {
      setData([]);
    }
  }, [loadCicles, selectedCompany]);

  const clearCicles = useCallback(() => {
    if (selectedCompany) {
      localStorage.removeItem(
        `@FarmFish:company-cicles:${selectedCompany.id}:selectedCicle`,
      );
      localStorage.removeItem(`@FarmFish:company-cicles:${selectedCompany.id}`);

      api.defaults.params = {
        ...api.defaults.params,
        cicle_id: null,
      };
    }
    setData([]);
  }, [selectedCompany]);

  return (
    <UserCicleContext.Provider
      value={{
        cicles: data,
        selectedCicle,
        clearCicles,
        loadCicles,
        onChangeCicle,
      }}
    >
      {children}
    </UserCicleContext.Provider>
  );
};

function useCicle(): UseCicleContextData {
  const context = useContext(UserCicleContext);

  if (!context) {
    throw new Error('useCicle must be used within an UserCicleProvider');
  }

  return context;
}

export { UserCicleProvider, useCicle };
