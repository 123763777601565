import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import {
  InputNumber,
  DatePicker,
  Select,
  Input,
} from '../../../../../components/Form';
import Button from '../../../../../components/Button';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { ISpecie, Options, IMortality } from '../../../../../types';
import api from '../../../../../services/api';
import { Container } from './styles';
import Loading from '../../../../../components/Helpers/Information/Loading';
import { useToast } from '../../../../../hooks/toast';
import Error from '../../../../../components/Helpers/Information/Error';
import { errorHandler } from '../../../../../utils/errorHandler';

interface NewMortalityData {
  date: Date;
  quantity: number;
  specie_id: string;
  reason: string;
}

interface OwnProps {
  onAddMortality(mortality: IMortality): void;
  pond_id: string;
  onCancel(): void;
}

const NewPopulate: React.FC<OwnProps> = ({
  onAddMortality,
  pond_id,
  onCancel,
}) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [species, setSpecies] = useState<Options[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function loadSpecies() {
      try {
        setLoading(true);
        const { data } = await api.get<ISpecie[]>('species');

        setSpecies(
          data.map(specie => ({
            value: specie.id,
            label: specie.name,
          })),
        );
      } catch (err) {
        setError(true);
        addToast({
          title: 'Inconsistência no carregamento',
          description:
            'Houve uma inconsistência ao carregar as espécies. Tente novamente',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }

    setError(false);
    loadSpecies();
  }, [addToast]);

  const handleOnSubmit = useCallback(
    async (data: NewMortalityData) => {
      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          date: Yup.date().required(
            'O campo data da mortalidade é obrigatório.',
          ),
          quantity: Yup.number()
            .positive('A quantidade deve ser positiva.')
            .required('O campo quantidade é obrigatório'),
          specie_id: Yup.string()
            .uuid('Valor do campo inválido')
            .required('É obrigatório selecionar a espécie'),
          reason: Yup.string().required(
            'É obrigatório informar o motivo da morte.',
          ),
        });

        const result = await schema.validate(data, {
          abortEarly: false,
        });

        const formData = Object.assign(result, { pond_id });

        const response = await api.post<IMortality>('mortality', formData, {
          params: api.defaults.params,
        });

        onAddMortality(response.data);
        onCancel();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        } else if ((err as AxiosError).isAxiosError) {
          const { title, type, description } = errorHandler(err);

          addToast({
            title,
            description,
            type,
          });
        }
      }
    },
    [pond_id, onCancel, onAddMortality, addToast],
  );

  if (error) {
    return (
      <Error message="Um dado necessário não pode ser carregado. Tente novamente" />
    );
  }

  if (loading || !species) {
    return <Loading message="Carregando dados necessários" />;
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleOnSubmit}>
        <DatePicker name="date" label="Data da morte" />
        <InputNumber
          name="quantity"
          label="Quantidade"
          autoFocus
          placeholder="Insira a quantidade encontrada"
          inputProps={{
            suffix: '',
            prefix: '',
          }}
        />

        <Select name="specie_id" label="Espécie" options={species} required />
        <Input name="reason" label="Motivo da morte" multiline rows={5} />

        <div className="farm-add-buttons-actions">
          <Button type="submit">Adicionar</Button>
          <Button colorType="cancel" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default NewPopulate;
