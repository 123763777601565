/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent, {
  DialogContentProps,
} from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface OwnProps extends DialogProps {
  dialogTitle?: string | (() => React.ReactElement<any>);
  dialogContentText?: string | (() => React.ReactElement<any>);
  dialogActions?: React.ReactElement<any>;
  dialogContentProps?: DialogContentProps;
  onClickActionCancelButton?: () => void;
  onClickActionConfirmButton?: () => void;
}

export const DialogScroll: React.FC<OwnProps> = ({
  dialogTitle,
  dialogContentText,
  dialogActions,
  dialogContentProps,
  onClickActionCancelButton,
  onClickActionConfirmButton,
  open,
  children,
  onClose,
  ...rest
}) => {
  const descriptionElementRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      {...rest}
      open={open}
      onClose={onClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      {dialogTitle && (
        <DialogTitle id="scroll-dialog-title">{dialogTitle}</DialogTitle>
      )}
      <DialogContent
        dividers={rest.scroll === 'paper'}
        {...dialogContentProps}
        className="dialogcontent-wrapper"
      >
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {dialogContentText}
        </DialogContentText>

        {children}
      </DialogContent>

      {dialogActions || (
        <DialogActions>
          <Button onClick={onClickActionCancelButton} color="secondary">
            Cancelar
          </Button>
          <Button onClick={onClickActionConfirmButton} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogScroll;
